import { combineReducers } from '@reduxjs/toolkit';
import login from './login';
import signup from './signup';
import user from './user';

const authReducers = combineReducers({
    user,
    login,
    signup,
});

export default authReducers;

import * as React from "react"

function FundoFPlain(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 290.87 290.87"
            {...props}
        >
            <defs>
                <clipPath id="prefix__clip-path">
                    <path
                        id="prefix__SVGID"
                        className="prefix__cls-1"
                        d="M145.44 285.47v-5.41A134.62 134.62 0 1010.82 145.44a134.8 134.8 0 00134.62 134.62v10.81a145.44 145.44 0 11145.43-145.43 145.43 145.43 0 01-145.43 145.43z"
                    />
                </clipPath>
                <clipPath id="prefix__clip-path-2">
                    <path
                        id="prefix__SVGID-2"
                        data-name="SVGID"
                        className="prefix__cls-1"
                        d="M146 104.32q-17.07 0-26.6 3.48t-13.83 9.61l-53.34 76.23h32.15l27-38.62h74.24l10.44-14.91h-74.23l13.59-19.39a3.33 3.33 0 012-1 16.19 16.19 0 013.48-.33H237l10.44-15.08z"
                    />
                </clipPath>
                <clipPath id="prefix__clip-path-3">
                    <path
                        id="prefix__SVGID-3"
                        data-name="SVGID"
                        className="prefix__cls-1"
                        transform="rotate(-30 149.843 148.97)"
                        d="M42.97 61.5h213.72v174.96H42.97z"
                    />
                </clipPath>
            </defs>
            <g id="prefix__Layer_2" data-name="Layer 2">
                <g id="prefix__Ebene_1" data-name="Ebene 1">
                    <path
                        id="prefix__SVGID-4"
                        data-name="SVGID"
                        className="prefix__cls-1"
                        d="M145.44 285.47v-5.41A134.62 134.62 0 1010.82 145.44a134.8 134.8 0 00134.62 134.62v10.81a145.44 145.44 0 11145.43-145.43 145.43 145.43 0 01-145.43 145.43z"
                    />
                    <g clipPath="url(#prefix__clip-path)">
                        <path
                            className="prefix__cls-1"
                            d="M282.43 290.87l8.44-4.87v4.87h-8.44zM273.28 290.87l17.59-10.15V286l-8.44 4.87h-9.15zM264.14 290.87l26.73-15.43v5.28l-17.59 10.15h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M254.99 290.87l35.88-20.71v5.28l-26.73 15.43h-9.15zM245.84 290.87l45.03-25.99v5.28l-35.88 20.71h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M236.69 290.87l54.18-31.28v5.29l-45.03 25.99h-9.15zM227.55 290.87l63.32-36.56v5.28l-54.18 31.28h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M218.4 290.87l72.47-41.84v5.28l-63.32 36.56h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M209.25 290.87l81.62-47.12v5.28l-72.47 41.84h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M200.11 290.87l90.76-52.4v5.28l-81.62 47.12h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M190.96 290.87l99.91-57.68v5.28l-90.76 52.4h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M181.81 290.87l109.06-62.96v5.28l-99.91 57.68h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M172.66 290.87l118.21-68.24v5.28l-109.06 62.96h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M163.52 290.87l127.35-73.53v5.29l-118.21 68.24h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M154.37 290.87l136.5-78.81v5.28l-127.35 73.53h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M145.22 290.87l145.65-84.09v5.28l-136.5 78.81h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M136.07 290.87l154.8-89.37v5.28l-145.65 84.09h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M126.93 290.87l163.94-94.65v5.28l-154.8 89.37h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M117.78 290.87l173.09-99.93v5.28l-163.94 94.65h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M108.63 290.87l182.24-105.21v5.28l-173.09 99.93h-9.15zM99.49 290.87l191.38-110.49v5.28L108.63 290.87h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M90.34 290.87l200.53-115.78v5.29L99.49 290.87h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M81.19 290.87l209.68-121.06v5.28L90.34 290.87h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M72.04 290.87l218.83-126.34v5.28L81.19 290.87h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M62.9 290.87l227.97-131.62v5.28L72.04 290.87H62.9z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M53.75 290.87l237.12-136.9v5.28L62.9 290.87h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M44.6 290.87l246.27-142.18v5.28L53.75 290.87H44.6z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M35.45 290.87l255.42-147.46v5.28L44.6 290.87h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M26.31 290.87l264.56-152.74v5.28L35.45 290.87h-9.14z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M17.16 290.87l273.71-158.02v5.28L26.31 290.87h-9.15z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M8.01 290.87l282.86-163.31v5.29L17.16 290.87H8.01z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 290.22l290.87-167.94v5.28L8.01 290.87H0v-.65z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 284.94L290.87 117v5.28L0 290.22v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 279.66l290.87-167.94V117L0 284.94v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 274.38l290.87-167.94v5.28L0 279.66v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 269.1l290.87-167.94v5.28L0 274.38v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 263.81L290.87 95.88v5.28L0 269.1v-5.29z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 258.53L290.87 90.6v5.28L0 263.81v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 253.25L290.87 85.32v5.28L0 258.53v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 247.97L290.87 80.03v5.29L0 253.25v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 242.69L290.87 74.75v5.28L0 247.97v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 237.41L290.87 69.47v5.28L0 242.69v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 232.13L290.87 64.19v5.28L0 237.41v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 226.85L290.87 58.91v5.28L0 232.13v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 221.56L290.87 53.63v5.28L0 226.85v-5.29z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 216.28L290.87 48.35v5.28L0 221.56v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 211L290.87 43.07v5.28L0 216.28V211z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 205.72L290.87 37.78v5.29L0 211v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 200.44L290.87 32.5v5.28L0 205.72v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 195.16L290.87 27.22v5.28L0 200.44v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 189.88L290.87 21.94v5.28L0 195.16v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 184.6L290.87 16.66v5.28L0 189.88v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 179.31L290.87 11.38v5.28L0 184.6v-5.29z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 174.03L290.87 6.1v5.28L0 179.31v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 168.75L290.87.82V6.1L0 174.03v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 163.47L283.14 0h7.73v.82L0 168.75v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 158.19L274 0h9.14L0 163.47v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 152.91L264.85 0H274L0 158.19v-5.28zM0 147.63L255.7 0h9.15L0 152.91v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 142.35L246.55 0h9.15L0 147.63v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 137.07L237.41 0h9.14L0 142.35v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 131.78L228.26 0h9.15L0 137.07v-5.29z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 126.5L219.11 0h9.15L0 131.78v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 121.22L209.96 0h9.15L0 126.5v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 115.94L200.82 0h9.14L0 121.22v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 110.66L191.67 0h9.15L0 115.94v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 105.38L182.52 0h9.15L0 110.66v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 100.1L173.38 0h9.14L0 105.38v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 94.82L164.23 0h9.15L0 100.1v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 89.54L155.08 0h9.15L0 94.82v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 84.25L145.93 0h9.15L0 89.54v-5.29z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 78.97L136.79 0h9.14L0 84.25v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 73.69L127.64 0h9.15L0 78.97v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 68.41L118.49 0h9.15L0 73.69v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 63.13L109.34 0h9.15L0 68.41v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 57.85L100.2 0h9.14L0 63.13v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 52.57L91.05 0h9.15L0 57.85v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 47.29L81.9 0h9.15L0 52.57v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 42.01L72.75 0h9.15L0 47.29v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 36.72L63.61 0h9.14L0 42.01v-5.29z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 31.44L54.46 0h9.15L0 36.72v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 26.16L45.31 0h9.15L0 31.44v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 20.88L36.17 0h9.14L0 26.16v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 15.6L27.02 0h9.15L0 20.88V15.6z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 10.32L17.87 0h9.15L0 15.6v-5.28z"
                        />
                        <path
                            className="prefix__cls-1"
                            d="M0 4.88L8.44 0h9.43L0 10.32V4.88z"
                        />
                        <path className="prefix__cls-1" d="M8.44 0L0 4.88V0h8.44z" />
                    </g>
                    <path
                        id="prefix__SVGID-5"
                        data-name="SVGID"
                        className="prefix__cls-1"
                        d="M146 104.32q-17.07 0-26.6 3.48t-13.83 9.61l-53.34 76.23h32.15l27-38.62h74.24l10.44-14.91h-74.23l13.59-19.39a3.33 3.33 0 012-1 16.19 16.19 0 013.48-.33H237l10.44-15.08z"
                    />
                    <g clipPath="url(#prefix__clip-path-2)">
                        <path
                            id="prefix__SVGID-6"
                            data-name="SVGID"
                            className="prefix__cls-1"
                            transform="rotate(-30 149.843 148.97)"
                            d="M42.97 61.5h213.72v174.96H42.97z"
                        />
                        <g clipPath="url(#prefix__clip-path-3)">
                            <path
                                className="prefix__cls-1"
                                d="M101.03 278.17l185.09-106.86v106.86H101.03z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M96.92 278.17l189.2-109.24v2.38L101.03 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M92.8 278.17l193.32-111.61v2.37L96.92 278.17H92.8z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M88.68 278.17l197.44-113.99v2.38L92.8 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M84.57 278.17L286.12 161.8v2.38L88.68 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M80.45 278.17l205.67-118.74v2.37L84.57 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M76.34 278.17l209.78-121.12v2.38L80.45 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M72.22 278.17l213.9-123.49v2.37L76.34 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M68.11 278.17L286.12 152.3v2.38L72.22 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M63.99 278.17l222.13-128.25v2.38L68.11 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M59.88 278.17l226.24-130.62v2.37L63.99 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M55.76 278.17l230.36-133v2.38L59.88 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M51.65 278.17L286.12 142.8v2.37l-230.36 133h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M47.53 278.17l238.59-137.75v2.38L51.65 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M43.42 278.17l242.7-140.13v2.38L47.53 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M39.3 278.17l246.82-142.5v2.37L43.42 278.17H39.3z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M35.19 278.17l250.93-144.88v2.38L39.3 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M31.07 278.17l255.05-147.25v2.37L35.19 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M26.96 278.17l259.16-149.63v2.38L31.07 278.17h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M22.84 278.17l263.28-152v2.37L26.96 278.17h-4.12z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M18.73 278.17l267.39-154.38v2.38l-263.28 152h-4.11z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M14.61 278.17l271.51-156.76v2.38L18.73 278.17h-4.12zM13.55 276.4l272.57-157.36v2.37L14.61 278.17h-1.06v-1.77z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 274.03l272.57-157.37v2.38L13.55 276.4v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 271.65l272.57-157.37v2.38L13.55 274.03v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 269.27l272.57-157.36v2.37L13.55 271.65v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 266.9l272.57-157.37v2.38L13.55 269.27v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 264.52l272.57-157.36v2.37L13.55 266.9v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 262.15l272.57-157.37v2.38L13.55 264.52v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 259.77l272.57-157.36v2.37L13.55 262.15v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 257.4l272.57-157.37v2.38L13.55 259.77v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 255.02L286.12 97.65v2.38L13.55 257.4v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 252.64L286.12 95.28v2.37L13.55 255.02v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 250.27L286.12 92.9v2.38L13.55 252.64v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 247.89L286.12 90.53v2.37L13.55 250.27v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 245.52L286.12 88.15v2.38L13.55 247.89v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 243.14L286.12 85.77v2.38L13.55 245.52v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 240.76L286.12 83.4v2.37L13.55 243.14v-2.38zM13.55 238.39L286.12 81.02v2.38L13.55 240.76v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 236.01L286.12 78.65v2.37L13.55 238.39v-2.38zM13.55 233.64L286.12 76.27v2.38L13.55 236.01v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 231.26L286.12 73.89v2.38L13.55 233.64v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 228.88L286.12 71.52v2.37L13.55 231.26v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 226.51L286.12 69.14v2.38L13.55 228.88v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 224.13L286.12 66.77v2.37L13.55 226.51v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 221.76L286.12 64.39v2.38L13.55 224.13v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 219.38L286.12 62.02v2.37L13.55 221.76v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 217L286.12 59.64v2.38L13.55 219.38V217z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 214.63L286.12 57.26v2.38L13.55 217v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 212.25L286.12 54.89v2.37L13.55 214.63v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 209.88L286.12 52.51v2.38L13.55 212.25v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 207.5L286.12 50.13v2.38L13.55 209.88v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 205.13L286.12 47.76v2.37L13.55 207.5v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 202.75L286.12 45.38v2.38L13.55 205.13v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 200.37L286.12 43.01v2.37L13.55 202.75v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 198L286.12 40.63v2.38L13.55 200.37V198z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 195.62L286.12 38.26v2.37L13.55 198v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 193.25L286.12 35.88v2.38L13.55 195.62v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 190.87L286.12 33.5v2.38L13.55 193.25v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 188.49L286.12 31.13v2.37L13.55 190.87v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 186.12L286.12 28.75v2.38L13.55 188.49v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 183.74L286.12 26.38v2.37L13.55 186.12v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 181.37L286.12 24v2.38L13.55 183.74v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 178.99L286.12 21.62V24L13.55 181.37v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 176.61L285.18 19.79h.94v1.83L13.55 178.99v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 174.24L281.07 19.79h4.11L13.55 176.61v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 171.86l263.4-152.07h4.12L13.55 174.24v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 169.49l259.29-149.7h4.11L13.55 171.86v-2.37zM13.55 167.11L268.72 19.79h4.12L13.55 169.49v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 164.73L264.61 19.79h4.11L13.55 167.11v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 162.36L260.49 19.79h4.12L13.55 164.73v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 159.98L256.38 19.79h4.11L13.55 162.36v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 157.61L252.26 19.79h4.12L13.55 159.98v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 155.23l234.6-135.44h4.11L13.55 157.61v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 152.85L244.03 19.79h4.12L13.55 155.23v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 150.48L239.91 19.79h4.12L13.55 152.85v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 148.1L235.8 19.79h4.11L13.55 150.48v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 145.73L231.68 19.79h4.12L13.55 148.1v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 143.35L227.57 19.79h4.11L13.55 145.73v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 140.97l209.9-121.18h4.12L13.55 143.35v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 138.6L219.34 19.79h4.11L13.55 140.97v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 136.22L215.22 19.79h4.12L13.55 138.6v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 133.85L211.11 19.79h4.11L13.55 136.22v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 131.47L206.99 19.79h4.12L13.55 133.85v-2.38z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 129.1L202.88 19.79h4.11L13.55 131.47v-2.37z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M13.55 126.65L198.64 19.79h4.24L13.55 129.1v-2.45z"
                            />
                            <path
                                className="prefix__cls-1"
                                d="M198.64 19.79L13.55 126.65V19.79h185.09z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default FundoFPlain

import {Fragment} from "react";
import HomeNewAmateurs from "../../blocks/HomeNewAmateurs";
import HomeNewDvds from "../../blocks/HomeNewDvds";
import HomeHeader from "../../blocks/HomeHeader";
import PurpleThinCta from "../../../core/components/general/PurpleThinCta";
import BlueThinCta from "../../../core/components/general/BlueThinCta";
import HomeLivecams from "../../blocks/HomeLivecams";
import HomeTop100Dvds from "../../blocks/HomeTop100Dvds";
import HomePrivateCams from "../../blocks/HomePrivateCams";
import CategoriesBlock from "../../blocks/CategoriesBlock";
import {useTranslation} from "react-i18next";

const HomeFsk = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <HomeHeader fsk={true} />
            <div className="text-center">
                <h2>FSK HOME</h2>
            </div>
            <BlueThinCta textBold="Das Neue Fundorado " text="ab jetzt alles mit drin von Community bis Webcam + Erotic Videothek" />
            <HomeLivecams title={t('home.livecams')} linkTitle={t('home.alllivecams')} fsk={true} />
            <HomeTop100Dvds title={t('home.top100dvds')}  linkTitle={t('home.alltop100dvds')} path="/channels/specials/top100" fsk={true} />
            <HomePrivateCams title={t('home.privatecams')} linkTitle={t('home.allprivatecams')} fsk={true} />
            <PurpleThinCta textBold="Das Neue Fundorado " text="ab jetzt alles mit drin von Community bis Webcam + Erotic Videothek" />
            <HomeNewAmateurs title='Neue Amateure' />
            <HomeNewDvds title={t('home.newdvds')}  linkTitle={t('home.allnewdvds')} path="/channels/specials/newdvds" fsk={true} />
            <CategoriesBlock fsk={true} />
        </Fragment>
    );
}
export default HomeFsk;

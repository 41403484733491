import {Fragment, useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SceneItem from "../video/SceneItem";
import {AdjustmentsIcon} from "@heroicons/react/solid";
import DvdItem from "../video/DvdItem";
import {pickBestAvailableLanguage} from "../../helpers/TranslationHelpers";
import InfiniteEndReached from "../InfiniteEndReached";
import InfiniteLoading from "../InfiniteLoading";
import {useDispatch, useSelector} from "react-redux";
import {openSignup} from "../../../store/base-slice";

const ChannelVideoBrowser = (props) => {
    const { channel } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedChannel, setSelectedChannel] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [endReached, setEndReached] = useState(false);
    const [isInitial, setIsInitial] = useState(true);
    const [videos, setVideos] = useState([]);
    const [labels, setLabels] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const fskSite = useSelector(state => state.base.fskSite);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);
    const { i18n, t } = useTranslation();

    const fetchVideos = async (videoChannel, loadPage) => {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/videos/channel/' + videoChannel, {
            params: {
                page: loadPage
            }
        });

        if (response.status !== 200) {
            throw new Error('Unable to load videos!');
        }

        const responseData = await response.data;
        setPage(responseData.current_page + 1);

        if (loadPage === 1) {
            setVideos(responseData.data);
            setTotalCount(responseData.total)
            setIsInitial(false);
        } else {
            setVideos(videos.concat(responseData.data));
        }
        if (responseData.current_page === responseData.last_page ) {
            setEndReached(true);
        }

        setIsLoading(false);
    }

    const fetchLabels = async (videoChannel) => {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/videos/labels/' + videoChannel);

        if (response.status !== 200) {
            throw new Error('Unable to load labels!');
        }

        const responseData = await response.data;

        setLabels(responseData);

        setIsLoading(false);
    }

    const loadMore = () => {
        fetchVideos(channel,page).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    }


    useEffect(() => {
        setVideos([]);
        setSelectedChannel(channel)
        setPage(1);
        setEndReached(false);
        if (props.channel === 'teencoreclub') {
            fetchLabels(props.channel);
        }
        fetchVideos(channel, 1).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    },[props.channel]);


    const onItemClick = (video) => {
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                navigate('/video/' + video.id + '/' + video.slug)
            } else {
                dispatch(openSignup());
            }
        } else {
            navigate('/video/' + video.id + '/' + video.slug)
        }
    }

    return (
        <Fragment>
            <div className="flex px-3">
                <div className="flex-grow">
                    <h2 className="text-fundo-gray-dark font-light text-3xl">
                        {t('navigation.fundo_specials.' + channel)} <span className="text-sm ml-3">({totalCount})</span>
                    </h2>
                </div>
                <div>
                    {/*<button*/}
                    {/*    type='button'*/}

                    {/*    className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium text-white bg-fundo-purple hover:bg-fundo-purple-light"*/}
                    {/*>*/}
                    {/*    <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />*/}
                    {/*    {t('filter')}*/}
                    {/*</button>*/}
                </div>
            </div>

            <div className="mt-3">
                <InfiniteScroll
                    dataLength={videos.length}
                    next={loadMore}
                    hasMore={!endReached}
                    loader={ <InfiniteLoading /> }
                    endMessage={ <InfiniteEndReached /> }
                    scrollableTarget="scrollContainer"

                >
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
                    {videos.map((video, index) => (
                        <Fragment key={video.id}>
                            {video.collection !== 1 &&
                                <SceneItem image={video.artwork.large}
                                           title={pickBestAvailableLanguage(video.title, i18n.language)}
                                           onClick={() => onItemClick(video)}
                                           actors={video.actors}
                                           duration={video.meta.duration}
                                           publicationDate={video.publication_date}
                                />
                            }
                            {video.collection === 1 &&
                                <DvdItem image={video.cover.large}
                                         title={pickBestAvailableLanguage(video.title, i18n.language)}
                                         onClick={() => onItemClick(video)}
                                         duration={video.meta.duration}
                                         publicationDate={video.publication_date}
                                />
                            }
                        </Fragment>
                    ))}
                    </div>
                </InfiniteScroll>
            </div>
        </Fragment>
    );
}
export default ChannelVideoBrowser;

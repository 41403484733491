import { createSlice } from "@reduxjs/toolkit";

const initialSignupState = {
    showSignup: false,
    signupStatus: 0
};

const signupSlice  = createSlice({
    name: 'signup',
    initialState: initialSignupState,
    reducers: {
        openSignup(state) {
            state.showSignup = true;
        },
        closeSignup(state) {
            state.showSignup = false;
        },
        createUser(state) {
            //
        }
    }
});

export const { openSignup, closeSignup } = signupSlice.actions;
export default signupSlice.reducer;

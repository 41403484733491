import FlatFeeCamItem from "../pages/cams/components/FlatFeeCamItem";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BlockHeading from "../../core/components/general/BlockHeading";
import PrivateCamItem from "../pages/cams/components/PrivateCamItem";
import {useSelector} from "react-redux";

const HomePrivateCams = ( props ) => {
    const { fsk, title, linkTitle } = props;
    const [performers, setPerformers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()
    const fskSite = useSelector(state => state.base.fskSite);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    useEffect(() => {
        const fetchPerformers = async () => {
            let url = new URL(process.env.REACT_APP_BACKEND_URL + '/cams/private')
            url.search = new URLSearchParams({
                limit: 8,
                category: 'all'
            });
            const response = await fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setPerformers(responseData.cams);
            setIsLoading(false);
        }
        fetchPerformers().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    },[]);


    return (
        <div className="px-3 mb-10">
            <BlockHeading title={title} linkTitle={linkTitle} path={fskSite && needsAgeVerification ? false : '/cams/private'} />
            <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-4 md:grid-cols-3 lg:grid-cols-4">
                {performers.map((performer, index) => (
                    <PrivateCamItem key={index} cam={performer} fsk={fsk} />
                ))}
            </div>
        </div>
    )
};
export default HomePrivateCams;
import {SearchIcon} from "@heroicons/react/solid";
import {Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {pickBestAvailableLanguage} from "../../helpers/TranslationHelpers";
import {truncateString} from "../../helpers/StringHelpers";
import {useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import {useNavigate} from "react-router-dom";
import classes from './SearchBar.module.css';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

let timer;


const SearchBar = () => {
    const navigate = useNavigate();
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [query, setQuery] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [httpError, setHttpError] = useState(null)
    const searchField = useRef();
    const { i18n, t} = useTranslation();
    const fskSite = useSelector(state => state.base.fskSite);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);
    const compRef = useRef(null);


    const onSearchChanged = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setQuery(searchField.current.value);
        }, 900)
    }

    const closeSearch = () => {
        setSearchOpen(false);
    }

    useEffect(() => {
        const fetchSearchResults = async () => {
            setIsLoading(true);
            const append = '?q=' + query + '&l=' + i18n.language;
            const searchResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/search' + append)

            if (!searchResponse.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await searchResponse.json();
            setSearchResults(responseData);
            setIsLoading(false);
        }

        if (query && query.length > 2) {
            setSearchOpen(true);
            fetchSearchResults().catch(error => {
                setIsLoading(false);
                setHttpError(error.message)
            });
        } else {
            setSearchOpen(false);
        }

    },[query])


    const onItemClicked = (type, path) => {
        if (fskSite && needsAgeVerification) {
            // subscribe
        } else {
            setSearchOpen(false);
            navigate(path);
        }
    }

    const handleOutsideClick = (event) => {
        if (compRef.current && !compRef.current.contains(event.target)) {
            setSearchOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, true);
        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    });

    return (
        <div ref={compRef} className="relative" onClose={closeSearch}>
            <div className="w-full relative text-gray-400 focus-within:text-gray-500">
                <label htmlFor="desktop-search" className="sr-only">
                    {t('search_bar.placeholder')}
                </label>
                <input
                    id="desktop-search"
                    ref={searchField}
                    onChange={onSearchChanged}
                    type="search"
                    placeholder={t('search_bar.placeholder')}
                    className="block w-full border-transparent pl-12 placeholder-gray-500 focus:border-transparent sm:text-sm focus:ring-0"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                    {!isLoading &&
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    }
                    {isLoading &&
                        <ClipLoader size="20" />

                    }
                </div>
            </div>
            <div className={classNames(
                searchOpen ? 'h-auto shadow-xl' : 'hidden h-0',
                'absolute z-20 bg-white'
            )}>
                <div className={classes.innerWrapper}>
                    <div className={classes.resultsContainer}>
                        {searchResults?.dvds?.length > 0 &&
                            <div className="mb-5">
                                <h3 className="text-lg lg:text-xl py-2">{t('search_bar.dvds')}</h3>
                                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg: grid-cols-4 xl:grid-cols-6 gap-3">
                                    {searchResults.dvds.map((item, index) =>
                                        <div key={item.id} className="col-span-1" onClick={() => onItemClicked('dvds', `/video/${item.id}/${item.slug}`)}>
                                            <div className="">
                                                <img src={item.cover.large} className="w-full" alt=""/>
                                            </div>
                                            <div className="flex-grow">
                                                <h4 className="text-xs md:text-sm">{truncateString(pickBestAvailableLanguage(item.title, i18n.language),22)}</h4>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {searchResults?.scenes?.length > 0 &&
                            <div className="mb-5">
                                <h3 className="text-lg lg:text-xl py-2">{t('search_bar.scenes')}</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-3">
                                    {searchResults.scenes.map((item, index) =>
                                        <div key={item.id} className="col-span-1" onClick={() => onItemClicked('scenes', `/video/${item.id}/${item.slug}`)}>
                                            <div className="">
                                                <img src={item.artwork.large} className="w-full" alt=""/>
                                            </div>
                                            <div className="flex-grow">
                                                <h4 className="text-xs md:text-sm">{truncateString(pickBestAvailableLanguage(item.title, i18n.language),22)}</h4>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {searchResults?.privatecams?.length > 0 &&
                            <div className="mb-5">
                                <h3 className="text-lg lg:text-xl py-2">{t('search_bar.private')}</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-3">
                                    {searchResults.privatecams.map((item, index) =>
                                        <div key={item.id} className="col-span-1" onClick={() => onItemClicked('privatecams', `/cams/private/${item.id}/${item.pseudo}`)}>
                                            <div className="">
                                                <img src={fskSite && needsAgeVerification ? item.previewimageurlsoft : item.previewimageurl} className="w-full" alt=""/>
                                            </div>
                                            <div className="flex-grow">
                                                <h4 className="text-xs md:text-sm">{truncateString(item.pseudo)}</h4>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        {searchResults?.livecams?.length > 0 &&
                            <div className="mb-5">
                                <h3 className="text-lg lg:text-xl py-2">{t('search_bar.live')}</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-6 gap-3">
                                    {searchResults.livecams.map((item, index) =>
                                        <div key={item.id} className="col-span-1" onClick={() => onItemClicked('livecams', `/cams/live/${item.slug}`)}>
                                            <div className="">
                                                <img src={fskSite && needsAgeVerification ? 'https://api.fundo.work/storage' + item.image_fsk : 'https://api.fundo.work/storage' + item.image} className="w-full" alt=""/>
                                            </div>
                                            <div className="flex-grow">
                                                <h4 className="text-xs md:text-sm">{truncateString(item.modelName)}</h4>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}
export default SearchBar;
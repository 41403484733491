import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

const FlatFeeImages = props => {
    const { images, performerName } = props;
    const [items, setItems] = useState(images);
    const { t } = useTranslation();


    const onInit = useCallback((detail) => {
        console.log('initttt');
    }, []);

    const getItems = useCallback(() => {
        return props.images.map((item, index) => {
            return (
                <div
                    key={index}
                    className="aspect-w-16 aspect-h-9 p-2"
                    data-src={item.url}
                >
                    <img
                        className="rounded-md w-full h-full object-top object-cover"
                        src={`${item.url}&width=320`}
                    />
                </div>
            );
        });
    }, [props.images]);

    const settings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        controls: false // remove built-in nav buttons
    }

    return (
        <div className="mt-3">
            {props.images.length > 0 &&
                <div>
                    <div className="flex flex-wrap">
                        <div>
                            <h2 className="font-light text-xl">{t('cams.images')}</h2>
                        </div>
                    </div>
                    <LightGallery
                        download={false}
                        mode="lg-fade"
                        licenseKey="BC52E29C-F84D47CD-BD6A5B3E-1F08CED9"
                        elementClassNames="grid grid-cols-1 md:grid-cols-4 gap-2"
                        onInit={onInit}
                    >
                        {props.images.map((item, index) => (
                            <div
                                key={index}
                                className="aspect-w-16 aspect-h-9"
                                data-src={item.url}
                            >
                                <img
                                    className="rounded-md w-full h-full object-top object-cover"
                                    src={`${item.url}&width=320`}
                                />
                            </div>
                        ))}
                    </LightGallery>


                </div>
            }
        </div>
    )
}
export default FlatFeeImages;

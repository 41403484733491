import MainLayout from "../../../layout/MainLayout";
import { Outlet } from "react-router-dom";
import FundoLayout from "../../../layout/FundoLayout";

const Cams = () => {
    return (
        <FundoLayout>
            <Outlet />
        </FundoLayout>
    );
}

export default Cams;

import axios from "axios";
import {loginUser, setUser, clearUser, logoutUser} from "./user";
import {verifyUser} from "./auth-actions";

export const userUpdate = (updateData) => {
    let updateDataReponse;
    return async (dispatch) => {
        const doRegister = async () => {
            const token = localStorage.getItem('token');
            const updateResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/user/update',
                {
                    firstname: updateData.firstname,
                    lastname: updateData.lastname,
                    email: updateData.email,
                    country: updateData.country,
                }, {
                    'headers': {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });

            if (updateResponse.status !== 200) {
                throw new Error('Registration failed')
            }

            updateDataReponse = await updateResponse.data;
            return updateDataReponse;
        }
        try {
            updateDataReponse = await doRegister(updateData);
            console.log('userUpdate success:', updateDataReponse);
            dispatch(setUser({
                authData: updateDataReponse
            }));
            return {
                "status":   "success"
            }
        } catch (e) {
            console.error('userUpdate failed: ', e.message, e.response);
            return {
                "status": "error",
                "data": e.response.data
            }
        }
    }
};

export const userChangePassword = (updateData) => {
    let updateDataReponse;
    return async (dispatch) => {
        const doRegister = async () => {
            const token = localStorage.getItem('token');
            const updateResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/user/update',
                {
                    firstname: updateData.firstname,
                    lastname: updateData.lastname,
                    email: updateData.email,
                    country: updateData.country,
                }, {
                    'headers': {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });

            if (updateResponse.status !== 200) {
                throw new Error('Registration failed')
            }

            updateDataReponse = await updateResponse.data;
            return updateDataReponse;
        }
        try {
            updateDataReponse = await doRegister(updateData);
            console.log('userUpdate success:', updateDataReponse);
            dispatch(setUser({
                authData: updateDataReponse
            }));
            return {
                "status":   "success"
            }
        } catch (e) {
            console.error('userUpdate failed: ', e.message, e.response);
            return {
                "status": "error",
                "data": e.response.data
            }
        }
    }
};

export const userAvsUpdate = (code, avsState) => {
    let updateDataReponse;
    return async (dispatch) => {
        const doAvsUpdate = async () => {
            const token = localStorage.getItem('token');
            const updateResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/user/vma_update',
                {
                    code: code,
                    state: avsState,
                    sourceHost: window.location.hostname
                }, {
                    'headers': {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                });

            if (updateResponse.status !== 200) {
                throw new Error('AVS Update failed')
            }

            updateDataReponse = await updateResponse.data;
            return updateDataReponse;
        }
        try {
            updateDataReponse = await doAvsUpdate(code, avsState);
            console.log('avs update success:', updateDataReponse);
            dispatch(verifyUser(localStorage.getItem('token')));
            return {
                "status":   "success"
            }
        } catch (e) {
            console.error('userAvsUpdate failed: ', e.message, e.response);
            return {
                "status": "error",
                "data": e.response.data
            }
        }
    }
};
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {SearchIcon, UserIcon} from '@heroicons/react/solid'
import { MenuAlt1Icon, XIcon } from '@heroicons/react/outline'
import {NavLink, useNavigate} from "react-router-dom";
import {logoutUser} from "../../../auth/store/user";
import {useDispatch} from "react-redux";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const UserDropDownMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate('/', { replace: true });
    };
    return (
    <Menu as="div" className="ml-4 relative flex-shrink-0">
        <div>
            <Menu.Button className="bg-fundo-purple-light flex text-sm rounded-full text-white ">
                <span className="sr-only">Open user menu</span>
                <UserIcon className="h-8 w-8 rounded-full" />
            </Menu.Button>
        </div>
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                    {({ active }) => (
                        <NavLink
                            className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                            )}
                             to="/user/profile"
                        >
                            View Profile
                        </NavLink>
                    )}
                </Menu.Item>
                {/*<Menu.Item>*/}
                {/*    {({ active }) => (*/}
                {/*        <a*/}
                {/*            href="#"*/}
                {/*            className={classNames(*/}
                {/*                active ? 'bg-gray-100' : '',*/}
                {/*                'block px-4 py-2 text-sm text-gray-700'*/}
                {/*            )}*/}
                {/*        >*/}
                {/*            Settings*/}
                {/*        </a>*/}
                {/*    )}*/}
                {/*</Menu.Item>*/}
                <Menu.Item>
                    {({ active }) => (
                        <a
                            onClick={handleLogout}
                            className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                            )}
                        >
                            Logout
                        </a>
                    )}
                </Menu.Item>
            </Menu.Items>
        </Transition>
    </Menu>
    )
 }
 export default UserDropDownMenu;
import {useSelector} from "react-redux";
import HomeFsk from "./HomeFsk";
import HomeNoneFsk from "./HomeNoneFsk";
import FundoLayout from "../../../layout/FundoLayout";
import FaqAccordion from "../../../core/components/FaqAccordion";

const Home = () => {

    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);
    const fskSite = useSelector(state => state.base.fskSite);


    return (
        <FundoLayout>
            { fskSite && needsAgeVerification ? <HomeFsk /> : <HomeNoneFsk /> }
            <FaqAccordion />
        </FundoLayout>
    );
}

export default Home;

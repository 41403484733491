import {NavLink} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/solid";
import {useDispatch} from "react-redux";
import {Fragment} from "react";
import { openSignup } from "../../../store/base-slice";

const BlockHeading = ( props ) => {
    const dispatch = useDispatch();
    const { title, linkTitle, path} = props;

    const openSignupModal = () => {
        dispatch(openSignup());
    }

    return (
        <div className="header flex mb-4">
            <div className="flex-grow">
                <span className="text-2xl md:text-3xl font-light">
                    {title}
                </span>
            </div>
            <div className="flex-none text-right">
                {linkTitle &&
                    <Fragment>
                        {path &&
                            <NavLink
                                to={path}
                                className="text-xl md:text-2xl font-base text-fundo-gray-dark flex hover:text-fundo-gray"
                            >
                                <span className="flex-grow">{linkTitle.toLowerCase()}</span>
                                <ChevronRightIcon className="w-8 h-8 flex-none" />
                            </NavLink>
                        }
                        {!path &&
                            <span
                                onClick={openSignupModal}
                                className="text-xl md:text-2xl font-base text-fundo-gray-dark flex hover:text-fundo-gray"
                            >
                                <span className="flex-grow">{linkTitle.toLowerCase()}</span>
                                <ChevronRightIcon className="w-8 h-8 flex-none" />
                            </span>
                        }
                    </Fragment>
                }
            </div>
        </div>
    )
}
export default BlockHeading;
import {useParams} from "react-router-dom";
import {Fragment} from "react";
import VideoBrowser from "../../../core/components/video/VideoBrowser";

const VideosFeatured = () => {
    const { listtype, listid } = useParams();


    return (
        <Fragment>
            <VideoBrowser listType={listtype} listId={listid} browseType="dvd" showGenres={true} />
        </Fragment>
    )
};
export default VideosFeatured;

import {useSearchParams} from "react-router-dom";
import {Fragment, useEffect} from "react";
import {useTranslation} from "react-i18next";
import LogoImage from '../../../assets/images/FundoradoLogo.svg';
import {useDispatch} from "react-redux";
import {userAvsUpdate} from "../../auth/store/user-actions";

const VerifyMyAgeReturn = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    useEffect(() => {
        dispatch(userAvsUpdate(code,state));
    },[])

    return (
        <Fragment>
            <div className="h-full w-full bg-fundo-purple flex justify-center items-center">

                <div className="mx-10 bg-fundo-purple-light mx-auto p-3 text-white">
                    <div className="flex justify-center mb-10">
                        <img src={LogoImage} alt=""/>
                    </div>
                    <div className="text-center mb-10">
                        <h2 className="text-2xl">{t('age_verification.vma_return_title_success')}</h2>
                    </div>
                    <div className="text-center p-5 mb-5">
                        {t('age_verification.vma_return_description_success')}
                    </div>
                    <div className="text-center mb-10">
                        <button
                            className="bg-fundo-purple-dark rounded-md px-3 py-3"
                            onClick={() => window.close() }
                        >
                            {t('age_verification.vma_return_button_close').toUpperCase()}
                        </button>
                    </div>

                </div>
            </div>
        </Fragment>
    );

}
export default VerifyMyAgeReturn;

import {useParams} from "react-router-dom";
import {Fragment} from "react";

import ChannelVideoBrowser from "../../../core/components/channels/ChannelVideoBrowser";

const ChannelsSpecials = () => {
    const { channel } = useParams();


    // useEffect()

    return (
        <Fragment>
            <ChannelVideoBrowser channel={channel} />
        </Fragment>
    )
}
export default ChannelsSpecials;

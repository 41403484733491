import classes from './HomeNewDvds.module.css';
import {Link, useNavigate} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BlockHeading from "../../core/components/general/BlockHeading";
import DvdItem from "../../core/components/video/DvdItem";
import {pickBestAvailableLanguage} from "../../core/helpers/TranslationHelpers";
import {openSignup} from "../../store/base-slice";
import {useDispatch, useSelector} from "react-redux";

const HomeNewDvds = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { title, linkTitle, path, fsk} = props;
    const [videos, setVideos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()
    const fskSite = useSelector(state => state.base.fskSite);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    useEffect(() => {
        const fetchVideos = async () => {
            const append = fsk ? '?fsk=true' : '';
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/videos/new' + append);

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setVideos(responseData);
            setIsLoading(false);
        }
        fetchVideos().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    }, []);

    const loadingImage =
        "data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";
    const imgStyles = {
        // width: "auto",
        // height: "225px",
        // objectFit: "cover"
    };

    const onItemClick = (video) => {
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                navigate('/video/' + video.id + '/' + video.slug)
            } else {
                dispatch(openSignup());
            }
        } else {
            navigate('/video/' + video.id + '/' + video.slug)
        }
    }


    return (
        <div className='homeNewDvds px-3 mb-10'>
            <BlockHeading title={title} linkTitle={linkTitle} path={fskSite && needsAgeVerification ? false : path}  />
            <div className={classes.dvdContainer}>
                {!isLoading &&
                    <Fragment>
                        {videos?.map((video, index) => (
                            // onClick, image, title, duration, publicationDate, actors
                            <DvdItem key={index}
                                     title={pickBestAvailableLanguage(video.title, i18n.language)}
                                     duration={video.meta.duration}
                                     image={video.cover.large}
                                     actors={video.actors}
                                     onClick={() => onItemClick(video)}
                            />
                        ))}
                    </Fragment>
                }
            </div>
        </div>
    );
}
export default HomeNewDvds;

import {Fragment, useEffect, useRef, useState} from "react";


const VerifyAge = (props) => {
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const [openWindow, setOpenWindow] = useState(false);
    // const authToken = useSelector(state => state.)

    const openVerifyWindow = (url) => {
        var left = (window.screen.width - 480) / 2;
        var top = (window.screen.height - 754) / 4;
        window.open(url, 'vmawindow', 'toolbar=0,status=0,width=480,height=754,top=' + top +',left=' + left);
    }

    useEffect(() => {
        const fetchRedirectUrl = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/vmaredirect', {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }

                });
            if (!response.ok) {
                throw new Error('VMA Redirect URL')
            }
            const responseData = await response.text();

            setRedirectUrl(responseData);
            setIsLoading(false);
            setOpenWindow(true);
            openVerifyWindow(redirectUrl);
        }

        fetchRedirectUrl().catch(error => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    },[]);


    return (
        <Fragment >
            {isLoading && (
                <h1>is loading....</h1>
            )}
            {!isLoading && (
                <div>
                    <div>
                        {redirectUrl}
                    </div>
                    <button className='rounded-md px-3 py-2 bg-fundo-purple text-white' onClick={() => openVerifyWindow(redirectUrl)}>Verify My Age</button>
                </div>

            )}

        </Fragment>
    )
}
export default VerifyAge;

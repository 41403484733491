import {useTranslation} from "react-i18next";

const InfiniteEndReached = () => {
    const { t } = useTranslation();

    return (
        <div className="grid grid-cols-3 bg-fundo-gray-light mt-5 py-4">
            <div className="col-start-2 col-end-2 text-center ">
                <span className="font-light text-2xl text-fundo-gray-dark">{t('endreached')}</span>
            </div>
        </div>
    )
}
export default InfiniteEndReached;

import {NavLink, useParams} from "react-router-dom";
import MainLayout from "../../../layout/MainLayout";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import HomeNewDvds from "../../blocks/HomeNewDvds";
import {IoPlayCircleOutline} from "react-icons/all";
import FlatFeeVertical from "../cams/components/FlatFeeVertical";
import {pickBestAvailableLanguage} from "../../../core/helpers/TranslationHelpers";
import {useDispatch, useSelector} from "react-redux";
import SvodPlayer from "../../../core/components/video/SvodPlayer";
import { openSignup } from "../../../store/base-slice";
import FundoLayout from "../../../layout/FundoLayout";

const VideoDetail = (props) => {
    const dispatch = useDispatch();
    let { id, slug } = useParams();
    const [video, setVideo] = useState();
    const [showPlayer, setShowPlayer] = useState(false);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);

    const [ isLoading, setIsloading] = useState(true);
    const { i18n, t } = useTranslation()

    useEffect(() => {
        document.getElementById('scrollContainer').scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setShowPlayer(false);
        const fetchVideo = async (videoId) => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/video/' + id);

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setVideo(responseData);
            setIsloading(false);
        }
        fetchVideo(id).catch(error => {
            setIsloading(false);
            //setHttpError(error.message)
            console.log(error.message)
        });
    },[id]);

    const onPLayerClick = () => {
        // check if use has valid Subscription
        if (hasActiveSubscription) {
            setShowPlayer(true);
        } else {
            dispatch(openSignup());
        }
    }

    return (
        <FundoLayout>
            <div className="flex flex-wrap">
                <div className="w-full md:w-3/4">
                    <div className="text-2xl font-light py-2">{video?.title[i18n.language]}</div>
                    <div className="playerContainer">
                        {!showPlayer &&
                        <div className="relative cursor-pointer" onClick={onPLayerClick}>
                            <img src={video?.screenshots[14]} alt={video?.title} className="shadow-lg rounded-md w-full"/>
                            <div className="absolute top-0 left-0 w-full h-full flex">
                                <IoPlayCircleOutline color="#ffffff" size="40%" className="m-auto shadow-xl" />
                            </div>
                        </div>
                        }
                        {showPlayer &&
                            <SvodPlayer id={video?.id} />
                        }
                    </div>
                    <div className="py-4 border-b-2 text-center md:text-left">
                        {video?.genres.map((genre, index) => (
                            <NavLink to={`/videos/browse/genre/${genre.id}`} key={genre.id} type="button" className="bg-gray-600 text-white px-2 py-1 rounded-md uppercase text-xs md:text-sm mr-2 mb-2">{pickBestAvailableLanguage(genre.title, i18n.language)}</NavLink>
                        ))}

                    </div>
                    <div className="flex flex-wrap py-3">
                        {video?.cover.large &&
                        <div className="hidden md:block md:w-1/6">
                            <img src={video?.cover.large} alt={video?.title[i18n.language]}/>
                        </div>
                        }
                        <div className="w-full md:w-5/6">
                            <div className="grid grid-cols-1 md:grid-cols-6 px-3 py-2">
                                <div className="col-span-2">
                                    <span className="text-sm md:text-lg text-fundo-gray-dark">{t('video_detail.actors')}:</span>
                                </div>
                                <div className="col-span-4">
                                    {video?.actors.map((actor) => {
                                        return (
                                            <NavLink to={`/videos/browse/cast/${actor.id}`} key={actor.id} className="bg-gray-600 text-white px-2 py-1 rounded-md uppercase text-xs md:text-sm mr-2 mb-2">{actor.name}</NavLink>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-6 px-3 py-2">
                                <div className="col-span-2">
                                    <span className="text-sm md:text-lg text-fundo-gray-dark">{t('video_detail.studio')}:</span>
                                </div>
                                <div className="col-span-4">
                                    <NavLink to={`/videos/browse/studio/${video?.licensor.id}`} className="bg-gray-600 text-white px-2 py-1 rounded-md uppercase text-xs md:text-sm mr-2 mb-2">{video?.licensor.name}</NavLink>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-6 px-3 py-2">
                                <div className="col-span-2">
                                    <span className="text-sm md:text-lg text-fundo-gray-dark">{t('video_detail.description')}:</span>
                                </div>
                                <div className="col-span-4">
                                    {video?.description[i18n.language]}
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-6 px-3 py-2">
                                <div className="col-span-2">
                                    <span className="text-sm md:text-lg text-fundo-gray-dark">{t('video_detail.duration_format')}:</span>
                                </div>
                                <div className="col-span-4">
                                    {video?.meta.duration}, {video?.meta.audio} {video?.meta.aspect === '16:9' && <span className="bg-gray-600 text-white px-2 py-1 rounded-md text-base">HD</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <HomeNewDvds title={t('home.newdvds')} />
                    </div>
                </div>
                <div className="w-full md:w-1/4">
                    <FlatFeeVertical  />
                </div>
            </div>
        </FundoLayout>
    );
}
export default VideoDetail;

import {Link, NavLink} from "react-router-dom";
import LogoImage from '../../../../assets/images/FundoradoLogo.svg';
import {useTranslation} from "react-i18next";

const NotFound = props => {
    const { t } = useTranslation();
    return (
        <div className="absolute h-full top-0 bottom-0 w-full">
            <div className="bg-fundo-purple min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="max-w-max mx-auto">
                    <div className="flex justify-center pb-10">
                        <div>
                            <img src={LogoImage} alt="" className="w-64"/>
                        </div>

                    </div>
                    <main className="sm:flex">
                        <p className="text-4xl font-extrabold text-fundo-gray sm:text-5xl">404</p>
                        <div className="sm:ml-6">
                            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                <h1 className="text-4xl font-extrabold text-gray-300 tracking-tight sm:text-5xl">{t('errors.pagenotfound')}</h1>
                                <p className="mt-2 text-base text-gray-100">{t('errors.pagenotfound_description')}</p>
                            </div>
                            <div className="mt-10 flex justify-center space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                                <NavLink
                                    to="/"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-fundo-purple-light hover:bg-fundo-purple-dark"
                                >
                                    {t('general.go_home')}
                                </NavLink>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
export default NotFound;

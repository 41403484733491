import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import FlatFeeImages from "./components/FlatFeeImages";
import FlatFeeVideos from "./components/FlatFeeVideos";
import {useTranslation} from "react-i18next";
import {PulseLoader} from "react-spinners";
import FlatFeeHorizontal from "./components/FlatFeeHorizontal";
import HomeLivecams from "../../blocks/HomeLivecams";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FlatFeeCamDetail = (props) => {
    const { slug } = useParams();
    const token = localStorage.getItem('token');
    const [roomData, setRoomData] = useState();
    const [performer, setPerformer] = useState();
    const [streamData, setStreamData] = useState();
    const [assets, setAssets] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const [playerLoaded, setPlayerLoaded] = useState(false);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        setPerformer(null)
        setStreamData(null);
        setRoomData(null);
        setAssets(null);

    },[]);

    useEffect(() => {
        const getRoom = async () => {
            setPerformer(null)
            setStreamData(null);
            setRoomData(null);
            setAssets(null);
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/ffcams/chat/regular/' + slug, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (!response.ok) {
                throw new Error('Unable to load chat room!');
            }
            const responseData = await response.json();
            setPerformer(responseData.performer);
            setStreamData(responseData.stream);
            setRoomData(responseData.room);
            setAssets(responseData.assets);
            setIsLoading(false);
        }

        getRoom().catch(error => {
            setIsLoading(false);
            console.log('performer error:', error);
        });

    },[slug]);

    useEffect(() => {
        console.log('playerLoaded',playerLoaded);
    },[playerLoaded])


    return (
        <div>
            {isLoading ? (
                <div>
                    <div className="aspect-w-16 aspect-h-9 relative">
                        <img src="/img/placeholders/video_01.svg" alt=""/>
                        <div className="absolute flex justify-center items-center">
                            <div className="pt-28">
                                <PulseLoader  />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {performer?.isOnline &&
                        <div className={classNames(playerLoaded ? 'block' : 'hidden', 'aspect-w-16 aspect-h-9')} >
                            <iframe src={streamData?.videoStreamUrl} width="800" height="600" onLoad={() => setPlayerLoaded(true)} />
                        </div>
                    }
                    {!performer?.isOnline &&
                        <div className="relative">
                            <div className="grid grid-cols-4">
                                {assets?.images.slice(0,4).map((image, index) =>
                                    <div key={index} className="">
                                        <img src={image.url} className="w-full h-full object-top object-cover" alt=""/>
                                    </div>
                                )}
                            </div>
                            <div className="absolute top-0 bottom-0 w-full flex justify-center items-center bg-black bg-opacity-50">
                                <div className="text-white text-2xl text-center">
                                    <span className="font-bold">{performer?.modelName}</span> {t('cams.model_is_offline')}<br/>
                                    <span className="text-lg">{t('cams.check_back_later')}</span>
                                </div>
                            </div>
                        </div>
                    }
                    <div>
                        <div>
                            <dl className=" grid grid-cols-2  bg-fundo-gray-light overflow-hidden shadow divide-y divide-gray-200 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 md:divide-y-0 md:divide-x">

                                <div className="px-4 py-5 sm:p-6 col-span-2 sm:col-span-1 lg:col-span-2">
                                    <dt className="text-base font-normal text-gray-900">{t('name')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.modelName}
                                        </div>
                                    </dd>
                                </div>

                                <div className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('age')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.age}
                                        </div>
                                    </dd>
                                </div>

                                <div  className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('ethnicity')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.ethnicity}
                                        </div>
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('height')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.height}
                                        </div>
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('weight')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.weight}
                                        </div>
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('bodyType')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.bodyType}
                                        </div>
                                    </dd>
                                </div>
                                <div className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('eyeColor')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.eyeColor}
                                        </div>
                                    </dd>
                                </div>
                                <div className="hidden lg:block lg:col-span-2"></div>
                                <div className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{t('hairColor')}</dt>
                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                        <div className="flex items-baseline text-lg font-semibold text-fundo-purple">
                                            {performer?.hairColor}
                                        </div>
                                    </dd>
                                </div>


                            </dl>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-10">
                            <FlatFeeImages performerName={performer?.modelName} images={assets?.images} />
                            <FlatFeeVideos  performerName={performer?.modelName} videos={assets?.videos} />
                        </div>
                        <div>
                            <HomeLivecams title={t('cams.live_cams')} linkTitle={t('cams.all_live_cams')} />
                        </div>

                </div>
                </Fragment>
            )}
            <div>

                {(!playerLoaded && performer?.isOnline) &&
                    <div className="aspect-w-16 aspect-h-9 relative">
                        <img src="/img/placeholders/video_01.svg" alt=""/>
                        <div className="absolute flex justify-center items-center">
                            <div className="pt-28">
                                <PulseLoader  />
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}
export default FlatFeeCamDetail;

import {AdjustmentsIcon} from "@heroicons/react/solid";
import FlatFeeCamItem from "./components/FlatFeeCamItem";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PrivateCamItem from "./components/PrivateCamItem";
import InfiniteLoading from "../../../core/components/InfiniteLoading";
import InfiniteEndReached from "../../../core/components/InfiniteEndReached";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PrivateCams = (props) => {
    const [cams, setCams] = useState([]);
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(1);
    const [endReached, setEndReached] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const [totalCount, setTotalCount] = useState(0);

    const [category, setCategory] = useState('all');

    const { t } = useTranslation();

    const fetchPrivateCams = async (loadPage, cat) => {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/cams/private', {
            params: {
                category: cat,
                page: loadPage
            }
        });

        if (response.status !== 200) {
            throw new Error('Unable to load private cams!');
        }

        const responseData = await response.data;
        setPage(responseData.current_page + 1);

        if (loadPage === 1) {
            setCams(responseData.cams.data);
            setCategories(responseData.categories);
            setTotalCount(responseData.cams.total)
        } else {
            setCams(cams.concat(responseData.data));
        }
        if (responseData.current_page === responseData.last_page ) {
            setEndReached(true);
        }

        setIsLoading(false);
    }

    const loadMore = () => {
        console.log('load more');
        fetchPrivateCams(page).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    }

    useEffect(() => {
        setCams([]);
        // setSelectedListType(listType);
        // setSelectedListId(listId);
        setPage(1);
        setEndReached(false);

        fetchPrivateCams(1 ,category).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    }, [category]);


    return (
        <div>
            <div className="flex px-3">
                <div className="flex-grow">
                    <h2 className="text-fundo-gray-dark font-light text-3xl">
                        {t('privatecams')} <span className="text-sm ml-3">({totalCount})</span>
                    </h2>
                </div>
                <div>
                    {/*<button*/}
                    {/*    type='button'*/}
                    {/*    className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium text-white bg-fundo-purple hover:bg-fundo-purple-light"*/}
                    {/*>*/}
                    {/*    <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />*/}
                    {/*    {t('filter')}*/}
                    {/*</button>*/}
                </div>
            </div>
            <div>
                <div>
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            Select a tab
                        </label>
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue={category}
                        >
                            <option key="all">{t('cams.private_categories.all')}</option>
                            {categories?.map((cat) => (
                                <option key={cat.slug}>{t('cams.private_categories.' + cat.slug)}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {categories?.map((cat) => (
                                    <button
                                        key={cat.slug}
                                        onClick={() => setCategory(cat.slug)}
                                        className={classNames(
                                            cat.current
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                            'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={cat.current ? 'page' : undefined}
                                    >
                                        {t('cams.private_categories.' + cat.slug)}
                                        {cat.onlineCount ? (
                                            <span
                                                className={classNames(
                                                    cat.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                                                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                )}
                                            >
                                            {cat.onlineCount}
                                          </span>
                                        ) : null}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
                <InfiniteScroll
                    dataLength={cams.length}
                    next={loadMore}
                    hasMore={!endReached}
                    loader={ <InfiniteLoading /> }
                    endMessage={ <InfiniteEndReached /> }
                    scrollableTarget="scrollContainer"

                >
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mt-3">
                        {cams.map((cam, index) => (
                            <PrivateCamItem key={cam.id} cam={cam} />
                        ))}
                    </div>
                </InfiniteScroll>
        </div>
    );
}
export default PrivateCams;
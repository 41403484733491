import {Fragment} from "react";
import {NavLink} from "react-router-dom";

const navigation = {
    main: [
        { name: 'Impressum', local: true, href: '/page/impressum' },
        { name: 'AGB', local: true, href: '/page/agb' },
        { name: 'Widerrufsrecht', local: true, href: '/page/widerrufsrecht' },
        { name: 'Service/Hilfe', local: true, href: '/page/impressum' },
        { name: 'Datenschutz', local: true, href: '/page/datenschutz' },
        { name: 'Webmaster', href: 'https://www.cashdorado.de/' },
    ]
}

const Footer = () => {

    return (
        <footer className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <Fragment key={item.name}>
                            {item.local &&
                                <div  className="px-5 py-2">
                                    <NavLink to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                                        {item.name}
                                    </NavLink>
                                </div>
                            }
                            {!item.local &&
                                <div  className="px-5 py-2">
                                    <a href={item.href} target="_blank" className="text-base text-gray-500 hover:text-gray-900">
                                        {item.name}
                                    </a>
                                </div>
                            }
                        </Fragment>
                    ))}
                </nav>
                <div className="mt-8 flex justify-center space-x-6">
                </div>
                <p className="mt-8 text-center text-base text-gray-400">&copy; 2021 Jestoro GmbH. All rights reserved.</p>
            </div>
        </footer>
    )
}
export default Footer;
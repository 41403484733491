import FundoLayout from "../../../layout/FundoLayout";
import GenreDropdown from "../../../core/components/video/GenreDropdown";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {capitalizeFirstOfEveryWord} from "../../../core/helpers/StringHelpers";
import {NavLink} from "react-router-dom";

const letters = ["#","a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];

const StudioOverview = ( props ) => {
    const  { title } = props;
    const [studios, setStudios] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const [selectedLetter, setSelectedLetter] = useState();
    const [query, setQuery] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        const fetchCountries = async (selLet, searchQuery) => {
            let append = '?l=' + selLet + '&q=' + searchQuery;


            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/meta/studios' + append);

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }

            const responseData = await response.json();
            setStudios(responseData.data);
            setIsLoading(false);
        }

        fetchCountries(selectedLetter, query).catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    },[selectedLetter, query]);


    return (
        <FundoLayout>
            <div className="flex px-3">
                <div className="flex-grow">
                    <h2 className="text-fundo-gray-dark font-light text-lg md:text-3xl">
                        {t('navigation.featured.allstudios')}
                    </h2>
                </div>
            </div>
            <div>
                <div className="my-10">
                    <div className="max-w-7xl mx-auto">
                        <input type="text"
                               className="shadow-sm block p-4 w-full sm:text-sm md:text-lg border-gray-300 rounded-md"
                               placeholder={t('search')}
                        />
                    </div>
                </div>
                <div className="my-10">
                    <div className="max-w-7xl mx-auto flex justify-center">
                        {letters.map((ch) =>
                            <button key={ch}
                                    className="text-xl font-bold px-3 py-1 bg-fundo-purple text-white m-1"
                                    onClick={() => setSelectedLetter(ch)}
                            >
                                {ch.toUpperCase()}
                            </button>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {studios?.map((studio) =>
                    <NavLink
                        to={`/videos/featured/studio/${studio.id}`}
                        key={studio.id}
                        className="cursor-pointer"
                    >
                        <div>
                            <img src={studio.video.cover.large} alt=""/>
                        </div>
                        <div className="text-center text-xl font-light bg-fundo-purple text-white py-2 px-3">
                            { capitalizeFirstOfEveryWord(studio.name)}
                        </div>

                    </NavLink>
                    )}
                </div>
            </div>
        </FundoLayout>
    )

};
export default StudioOverview;
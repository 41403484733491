import MainLayout from "../../../layout/MainLayout";
import {Outlet} from "react-router-dom";
import FundoLayout from "../../../layout/FundoLayout";

const Videos = () => {
    return (
        <FundoLayout>
            <Outlet />
        </FundoLayout>
    );
};
export default Videos;

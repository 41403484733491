import { createSlice } from "@reduxjs/toolkit";

const getNavOpenItems = () => {

    if (localStorage.getItem('mainNav')) {
        const items = localStorage.getItem('mainNav')
        return items.split(',')
    }

    return ['navigation.fundo_specials.name'];
}

const initialBaseState = {
    showLogin: false,
    showBuyCoins: false,
    showSignup: false,
    showMobileMenu: false,
    ageVerificationActive: false,
    mainNavExpanded: true,
    mainNavOpenItems: getNavOpenItems(),
    fskSite: false
};

const baseSlice  = createSlice({
    name: 'base',
    initialState: initialBaseState,
    reducers: {
        openLogin(state) {
            state.showLogin = true;
        },
        closeLogin(state) {
            state.showLogin = false;
        },
        openBuyCoins(state) {
            state.showBuyCoins = true;
        },
        closeBuyCoins(state) {
            state.showBuyCoins = false;
        },
        toggleMobileMenu(state) {
            console.log('toggle');
            state.showMobileMenu = !state.showMobileMenu;
        },
        toggleMainNav(state) {
            state.mainNavExpanded = !state.mainNavExpanded;
            localStorage.setItem('navExp', state.mainNavExpanded);
        },
        setNavExpanded(state, action) {
            state.mainNavExpanded = action.payload;
        },
        openSignup(state) {
            state.showSignup = true;
        },
        closeSignup(state) {
            state.showSignup = false;
        },
        toggleMainNavItem(state,action) {
            console.log(action.payload);
            if (state.mainNavOpenItems.includes(action.payload)) {
                let index = state.mainNavOpenItems.indexOf(action.payload);
                if (index > -1) {
                    state.mainNavOpenItems.splice(index, 1);
                }
            } else {
                state.mainNavOpenItems.push(action.payload);
            }
            localStorage.setItem('mainNav', state.mainNavOpenItems);
        },
        setAgeVerificationActive(state) {
            state.ageVerificationActive = true;
        },
        setFskSite(state) {
            state.fskSite = true;
        }
    }
});

export const { openLogin, closeLogin, openBuyCoins, closeBuyCoins, openSignup, closeSignup, toggleMobileMenu, toggleMainNav, setNavExpanded, toggleMainNavItem, setAgeVerificationActive, setFskSite } = baseSlice.actions;
export default baseSlice.reducer;

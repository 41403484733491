import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import ReactHtmlParser from 'react-html-parser'
import {useDispatch} from "react-redux";
import {authSubscribeUser} from "../store/subscription-actions";
import {PulseLoader} from "react-spinners";

const plans = [
    { id: 1, name: 'Schnuper', short_description:'Short description', subtitle:'Test', price: '4,95', currency: '&euro;', bgcolor: 'bg-fundo-snupper' },
    { id: 2, name: 'Basis', short_description:'Short description', subtitle:'Der Klassiker', price: '24,95', currency: '&euro;', bgcolor: 'bg-fundo-basic' },
    { id: 3, name: 'Silber', short_description:'Short description', subtitle:'Alles Klahr', price: '119,70', currency: '&euro;', bgcolor: 'bg-fundo-silver' },
    { id: 4, name: 'Gold', short_description:'Short description', subtitle:'Das Beste Fur Den Mann', price: '179,40', currency: '&euro;', bgcolor: 'bg-fundo-gold' },
    { id: 5, name: 'Platin', short_description:'Short description', subtitle:'Der Alleskonner', price: '238,80', currency: '&euro;', bgcolor: 'bg-fundo-platinum' },
]

const paymentMethods = [
    {method: 'cc', title: 'Kreditkarte'},
    {method: 'sepa', title: 'Lastschrift'},
];


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SubscribeForm = (props) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(1)
    const [isLoading, setIsLoading] = useState(false);

    const handleSubscribe = (plan, method) => {
        setIsLoading(true);
        const subscribeResponse = dispatch(authSubscribeUser(selected, method));
        subscribeResponse.then((data) => {
            if (data.status === 'success') {
                window.location.href = data.url;
            } else {
                setIsLoading(false);
            }
        });
    };


    return (
        <form>
        <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
            <div className="space-y-4">
                {plans.map((plan) => (
                    <RadioGroup.Option
                        key={plan.id}
                        value={plan.id}
                        disabled={isLoading}
                        className={({ active, checked }) =>
                            classNames(
                                checked ? '-ml-6 ring-1 ring-offset-2 ring-indigo-500' : '',
                                'relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none text-fundo-text',
                                plan.bgcolor
                            )
                        }
                    >
                        {({ checked }) => (
                            <>
                                <div className="flex items-center">
                                    <div className="text-sm">
                                        <RadioGroup.Label as="p" className="font-medium text-fundo-text text-2xl">
                                            <span className='uppercase text-2xl font-bold'>{plan.name}</span> <span>{plan.subtitle}</span>
                                        </RadioGroup.Label>
                                        <RadioGroup.Description as="div" className="text-gray-500">
                                            <p className="sm:inline">
                                                {plan.short_description}
                                            </p>
                                        </RadioGroup.Description>
                                    </div>
                                </div>
                                <RadioGroup.Description as="div" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                    <div className="font-medium text-gray-900 text-2xl">{plan.price} {ReactHtmlParser(plan.currency)}</div>
                                    <div className="ml-1 text-gray-500 sm:ml-0">monatlich</div>
                                </RadioGroup.Description>
                                <div
                                    className={classNames(
                                        checked ? 'border-indigo-500' : 'border-transparent',
                                        'absolute -inset-px rounded-lg border-2 pointer-events-none'
                                    )}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>

            <div className="mt-10 px-0 md:px-10">
                <div className="grid grid-cols-6 gap-4">
                    {paymentMethods.map((method) => (
                        <div key={method.method} className="col-span-6 md:col-span-3">
                            <button
                                type='button'
                                disabled={isLoading}
                                onClick={() => handleSubscribe(selected, method.method)}
                                className='relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer text-fundo-text w-full'
                            >
                                <div className="flex-items center">
                                    <PulseLoader color='#999999' loading={isLoading}  size={10} margin={5} />
                                    {!isLoading && <span className='uppercase text-2xl font-bold'>{method.title}</span>}
                                </div>
                            </button>
                        </div>
                    ))};
                </div>
            </div>


        </form>
    )

};
export default SubscribeForm;

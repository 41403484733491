import {useDispatch, useSelector} from "react-redux";
import {NavLink, useSearchParams} from "react-router-dom";
import {verifyUser} from "../../../auth/store/auth-actions";
import {useEffect} from "react";
import FundoLayout from "../../../layout/FundoLayout";

const ReturnPage = () => {
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');
    const transaction = searchParams.get('transaction');
    const code = searchParams.get('code');
    const reference = searchParams.get('reference');

    const verify = () => {
        dispatch(verifyUser(localStorage.getItem('token')));
    };

    useEffect(() => {
        verify();
    },[])

    return (
        <FundoLayout>
            {code === '200' &&
                <div className="bg-fundo-purple">
                    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-light text-white sm:text-4xl">
                            <span className="block">Welcome to <span className="text-fundo-gold">Fundorado</span></span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-indigo-200">
                            Thanks for signing up!
                        </p>
                        <NavLink
                            to="/"
                            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-black bg-fundo-gold hover:bg-fundo-gray-light sm:w-auto"
                        >
                            Get Started
                        </NavLink>
                    </div>
                </div>
            }
            {code !== '200' &&
                <div className="bg-fundo-purple">
                    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-light text-white sm:text-4xl">
                            <span className="block">Something went wrong</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-white">
                            <span className="block">Your transaction failed or is still in progress. It's possible that the transaction is still pending, in this case the subscription will automatically activate once it is successful.</span>

                        </p>
                    </div>
                </div>
            }
            {/*<div>*/}
            {/*    status: {status}<br/>*/}
            {/*    transaction: {transaction}<br/>*/}
            {/*    code: {code}<br/>*/}
            {/*    reference: {reference}<br/>*/}
            {/*</div>*/}
        </FundoLayout>

    );
};
export default ReturnPage;
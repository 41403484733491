import {Fragment} from "react";
import FundoLayout from "../../../layout/FundoLayout";
import {useParams} from "react-router-dom";

const InfoPage = ( props ) => {
    const { page } = useParams();
    return (
        <FundoLayout>
        <Fragment>
            {page === 'impressum' &&
                <div id="impressum" className="container_content_welcome color_1 mb-10">
                    <div className="container">
                        <div className="row caption">
                            <div className="col-xs-12 col-sm6">
                                <h1 className="text-2xl">Impressum</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <p className="textbox">
                                    <br/>
                                        <strong>Anbieter:</strong>
                                        <br/>
                                            Jestoro GmbH
                                            <br/>
                                                Deelbögenkamp 4
                                                <br/>
                                                    22297 Hamburg
                                                    <br/>
                                                        <br/>
                                                            <strong>Geschäftsführer:</strong>
                                                            <br/>
                                                                <br/>
                                                                    <br/>
                                                                        Amtsgericht Hamburg, HRB 82980
                                                                        <br/>
                                                                            USt-ID Nummer: DE 813274551
                                                                            <br/>
                                                                                Finanzamt Hamburg-Nord
                                                                                <br/>
                                                                                    <br/>
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <p className="textbox">
                                    <br/>
                                        <strong>Jugendschutzbeauftragter</strong>
                                        gemäß §7 Jugendmedienschutz-Staatsvertrag nach dem Recht der Bundesrepublik
                                        Deutschland<br/>
                                        Rechtsanwalt Marko Dörre<br/>
                                        Marienstraße 8<br/>
                                        10117 Berlin, Germany<br/>
                                        Tel.: +49 (0) 30 400 544 99<br/>
                                        Fax.: +49 (0) 30 400 544 98<br/>
                                        <br/>
                                            <strong>Public Relations: </strong><a
                                            href="mailto:pr@jestoro.com">pr@jestoro.com</a>
                                            <br/>
                                                <br/>
                                                    <strong>Kundenservice: </strong><a
                                                    href="mailto:service@jestoro.com">service@jestoro.com</a>
                                                    <br/>
                                                        <br/>
                                                            <strong>Hotline: </strong>+49 (0)40 328 905 353
                                                            <br/>
                                                                <br/>
                                                                    Das Service-Team ist <strong>Mo-Fr von 08.00-20.00
                                                                    Uhr und am Wochenende von 11.00-18.00
                                                                    Uhr</strong> für Sie da!
                                                                    <br/>
                                                                        <br/>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="textbox">
                                    Plattform der EU-Kommission zur Online-Streitbeilegung:
                                    <a href="http://ec.europa.eu/consumers/odr/" target="_blank"
                                       style={{wordBreak: "break-all"}}>www.ec.europa.eu/consumers/odr</a><br/>
                                    Hinweis nach § 36 Verbraucherstreitbeilegungsgesetz: Jestoro ist weder verpflichtet
                                    noch bereit,
                                    an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                                    teilzunehmen.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {page === 'agb' &&
                <div className="container mb-10">

                    <div className="row caption">
                        <div className="col-lg-12">
                            <h1 className="text-2xl mb-2">AGB FunDorado</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 textbox">
                            <p>
                                <strong>
                                    Jestoro GmbH<br/>
                                    Deelbögenkamp 4<br/>
                                    22297 Hamburg</strong><br/>

                            </p>
                            (nachfolgend "Jestoro" genannt)<br/><br/><h4><strong>Präambel</strong></h4>
                            <p>
                                Jestoro bietet den Nutzern unter dem im Internet abrufbaren Portal www.fundorado.de den
                                Zugang zu erotischen Bildern,
                                Videos und Live-Cams (nachfolgend "Inhalte" genannt) an.
                            </p>
                            <div className="divider"></div>

                            <h4><strong>1. Nutzungsvoraussetzungen und Registrierung</strong></h4>
                            <p>
                                1.1 Durch die Nutzung bzw. Inanspruchnahme aller von Jestoro angebotenen Inhalte und
                                Leistungen (nachfolgend "Dienste" genannt)
                                erkennt der Nutzer diese AGB und die Bestimmungen zum Datenschutz an. Es gelten
                                ausschließlich diese Bestimmungen. Mündliche
                                Nebenabreden bestehen nicht.<br/><br/>

                                1.2 Das Aufrufen und der Besuch der "FunDorado-Startseiten" sind kostenlos und ohne
                                vorherige Registrierung möglich. Es entstehen
                                lediglich die Kosten für die Internetverbindung.<br/><br/>

                                1.3 Die weiteren Dienste, insbesondere die Dienste, die der Altersbeschränkung ab 18
                                unterliegen (18er-Dienste), sind den
                                Mitgliedern von Jestoro vorbehalten. Um Mitglied zu werden, ist eine kostenpflichtige
                                Registrierung bei Jestoro
                                erforderlich (siehe unter Ziffer 2 "Mitgliedschaften").<br/><br/>

                                1.3.1 Die Registrierung bei Jestoro ist nur Nutzern, die das 18. Lebensjahr vollendet
                                haben und über eine gültige E-Mail-Adresse
                                verfügen, gestattet.<br/><br/>

                                1.3.2 Das Angebot zum Abschluss eines Vertrags über eine kostenpflichtige Mitgliedschaft
                                gibt der Nutzer online durch
                                seine Anmeldung ab.<br/><br/>

                                1.3.3 Jestoro nimmt das Angebot des Nutzers an, indem sie dem Nutzer per E-Mail sein
                                persönliches Passwort für den
                                Login mitteilt.<br/><br/>

                                1.4 Aus Jugendschutzgründen erhält der registrierte Nutzer nur nach erfolgreicher
                                Altersverifikation Zugang zu den 18er-Diensten
                                (geschlossene Benutzergruppe). Dafür ist es zwingend erforderlich, dass er nach dem
                                ersten Login eine Altersverifikation
                                durchläuft.<br/><br/>

                                1.5 Der Nutzer hat im Rahmen der Registrierung und Altersverifikation vollständige und
                                wahrheitsgemäße Angaben zu machen.
                                Eine spätere Änderung seiner Daten (dazu gehört auch die E-Mail-Adresse) hat der Nutzer
                                Jestoro unverzüglich mitzuteilen.<br/><br/>

                                1.6 Die Nutzung im oder unter dem Namen eines Dritten - ohne dessen Einverständnis -
                                sowie die Mehrfachanmeldung eines Nutzers unter
                                verschiedenen Namen ist nicht zulässig. <br/><br/>

                                1.7 Jestoro bietet ihre Dienste ausschließlich für private, nicht gewerbliche Zwecke an.
                                Die Nutzung des Dienstes für gewerbliche
                                Zwecke ist nicht zulässig.
                            </p>

                            <div className="divider"></div>

                            <h4><strong>2. Mitgliedschaften und Vertragslaufzeit</strong></h4>
                            <p>
                                2.1 Jestoro bietet dem Nutzer folgende Mitgliedschaften mit unterschiedlichen Laufzeiten
                                und unterschiedlichen Leistungen an:
                                <br/>
                                    <br/>
                                        2.1.1 das Schnupper-Paket mit einer Mindestvertragslaufzeit von 21 Tagen;
                                        <br/>
                                            <br/>
                                                2.1.2 das Basis-Paket mit einer Mindestvertragslaufzeit von einem Monat;
                                                <br/>
                                                    <br/>
                                                        2.1.3 das Silber-Paket mit einer Mindestvertragslaufzeit von
                                                        sechs Monaten;
                                                        <br/>
                                                            <br/>
                                                                2.1.4 das Gold-Paket mit einer Mindestvertragslaufzeit
                                                                von zwölf Monaten;
                                                                <br/>
                                                                    <br/>
                                                                        2.1.5 das Platin-Paket mit einer
                                                                        Mindestvertragslaufzeit von vierundzwanzig
                                                                        Monaten.
                                                                        <br/>
                                                                            <br/>
                                                                                2.2 Die Beschreibungen der durch Erwerb
                                                                                des jeweiligen Pakets zugänglichen
                                                                                Bereiche und Dienste (nachfolgend
                                                                                "Leistungsbeschreibungen"
                                                                                genannt) können unter www.fundorado.de
                                                                                eingesehen bzw. auf den entsprechenden
                                                                                Dialogfeldern im Rahmen des
                                                                                Anmeldeprozesses entnommen
                                                                                werden. Jestoro behält sich eine
                                                                                Änderung der Leistungsbeschreibungen und
                                                                                zugänglichen Dienste vor, soweit der
                                                                                Nutzer durch die
                                                                                Änderung nicht unangemessen
                                                                                benachteiligt wird.
                            </p>

                            <div className="divider"></div>

                            <h4><strong>3. Pflichten des Nutzers</strong></h4>
                            <p>
                                3.1 Der Nutzer verpflichtet sich, im Rahmen der Nutzung der Dienste von Jestoro die
                                allgemeinen Rechtsvorschriften einzuhalten, keine
                                strafrechtlich relevanten Inhalte zu verbreiten, sowie nicht gegen sonstige Rechte
                                Dritter (insbesondere gewerbliche Schutzrechte,
                                das Wettbewerbsrecht und das allgemeine Persönlichkeitsrecht) zu verstoßen. Vor allem
                                ist die Verbreitung von Jugend gefährdenden,
                                kinderpornografischen, extremistischen und rassistischen Inhalten untersagt.
                                <br/>
                                    <br/>
                                        3.2 Der Nutzer hat sein Login nur für den persönlichen Gebrauch und so
                                        aufzubewahren, dass Dritte keinen Zugang zu diesem Login
                                        erhalten können. Er hat die Geheimhaltung und den ordnungsgemäßen Gebrauch
                                        sicherzustellen. Das Login kann von unterschiedlichen
                                        Rechnern aus benutzt werden, allerdings erklärt der Nutzer, sich nicht von
                                        mehreren Rechnern aus gleichzeitig einzuloggen.
                                        <br/>
                                            <br/>
                                                3.3 Der Nutzer hat insbesondere darauf zu achten, dass die Einsichtnahme
                                                der 18er-Dienste von Jestoro durch Minderjährige
                                                ausgeschlossen ist.
                                                <br/>
                                                    <br/>
                                                        3.4 Der Nutzer ist dafür verantwortlich, dass die von ihm
                                                        eingestellten Inhalte frei von Viren, Trojanern und Würmern
                                                        sind. Darüber
                                                        hinaus ist der Nutzer nicht berechtigt, Mechanismen, Software,
                                                        Programme oder sonstige Routinen zu verwenden, die die Systeme
                                                        der
                                                        "FunDorado-Webseite und -Dienste" stören könnten. Der Nutzer
                                                        darf keine Maßnahmen ergreifen, die zu einer unzumutbaren
                                                        überbelastung
                                                        der Systeme führen könnten.
                                                        <br/>
                                                            <br/>
                                                                3.5 Bereits bei erstmaligem Verstoß gegen die
                                                                Verpflichtungen aus Ziffer 3.1 - 3.4 ist Jestoro ohne
                                                                weitere Benachrichtigung zur
                                                                sofortigen Sperrung des Zugangs des Nutzers berechtigt.
                                                                Die Geltendmachung eines Schadensersatzanspruchs behält
                                                                sich Jestoro ausdrücklich vor.
                                                                <br/>
                                                                    <br/>
                                                                        3.6 Soweit der Nutzer die Dienste von Jestoro
                                                                        entgegen den Regelungen dieser AGB zu
                                                                        gewerblichen Zwecken missbraucht und/oder
                                                                        mit seiner Anmeldung geschäftliche Interessen
                                                                        verbindet, verpflichtet er sich, für jede
                                                                        nachgewiesene, schuldhafte Zuwiderhandlung eine
                                                                        Vertragsstrafe in Höhe von EURO 2.000,00 (in
                                                                        Worten: "zweitausend") an Jestoro zu zahlen.
                            </p>

                            <div className="divider"></div>


                            <h4><strong>4. Zahlungsbedingungen und Abrechnungsmodalitäten</strong></h4>

                            <p>
                                4.1 Der Nutzer ist zur Zahlung des fälligen Entgeltes verpflichtet. Die fälligen
                                Rechnungsbeträge bestimmen sich
                                nach der bei Vertragsschluss gültigen Leistungsbeschreibung und Preisliste, die unter
                                www.fundorado.de eingesehen werden können.
                                Der Gesamtbetrag für die Laufzeit der Mitgliedschaft wird zu Beginn der Laufzeit
                                eingezogen.
                                <br/>
                                    <br/>
                                        4.2 Einzelne Leistungen und Funktionen des Webangebotes sind über die
                                        portaleigene Währung "Coins" zu beziehen. Dafür wird dem Nutzer ein eigenes
                                        "Coin"-Konto eingerichtet. Die Einzahlung auf das Coin-Konto erfolgt durch die
                                        nach Ziffer 4.3 angegebene Zahlart.
                                        <br/>
                                            <br/>
                                                4.3 Die für die Zahlung erforderlichen Daten hat der Nutzer bei
                                                Vertragsschluss anzugeben. Durch Angabe der Bezahldaten ermächtigt
                                                der Nutzer Jestoro bis auf Widerruf zur Einziehung der fälligen Entgelte
                                                von dem angegebenen Konto, Kreditkarte oder sonstigen
                                                ausgewählten Zahlarten. Wählt der Nutzer als Zahlart die Lastschrift
                                                aus, ermächtigt er zugleich Jestoro, die fälligen Entgelte
                                                im SEPA (Single Europe Payments Area) -Lastschriftverfahren vom
                                                angegebenen Konto einzuziehen. Die Einziehung der ersten
                                                Mitgliedschaftsgebühr erfolgt am 8. Tag nach der Erst-Anmeldung. Die
                                                folgend turnusmäßig zu zahlenden Mitgliedschaftsgebühren
                                                werden jeweils am ersten Tag einer neuen Laufzeit eingezogen. Die
                                                Einziehung der durch Zusatzleistungen entstandenen Gebühren
                                                erfolgt am Folgetag. Befindet sich der Nutzer in der ersten
                                                Mitgliedswoche, so erfolgt die Abbuchung erst am 8. Tag nach der
                                                Anmeldung.
                                                Der Nutzer wird sein Kreditinstitut anweisen, die von Jestoro gezogenen
                                                Lastschriften einzulösen.
                                                Der Nutzer kann nur innerhalb von acht (8) Wochen ab Belastungsdatum,
                                                die Erstattung des Betrages verlangen.
                                                Es gelten dabei die mit seinem Kreditinstitut vereinbarten Bedingungen.
                                                Die Verifizierung der hinterlegten Bankverbindung erfolgt durch
                                                die Überweisung von einem Cent (EUR 0,01 inkl. der gesetzlich
                                                geschuldeten MwSt.). Im Rahmen der Mitgliedschaft fällig gewordene
                                                Beträge
                                                werden erst ab dem 8. Tag nach der Anmeldung abgebucht. Wählt der Nutzer
                                                als Zahlart die Kreditkarte aus, so steht als Deskription auf seiner
                                                Kreditkartenabrechnung: "www.fundorado.com, Hamburg". Wählt der Nutzer
                                                die Zahlung per Rechnung aus, wird der Kaufpreis mit Erhalt der Rechnung
                                                fällig. In diesem Fall ist der Kaufpreis innerhalb von 7 (sieben) Tagen
                                                ab Erhalt der Rechnung ohne Abzug zu zahlen, sofern nichts anderes
                                                vereinbart ist. Jestoro behält sich vor, bestimmte Zahlarten nicht
                                                anzubieten und auf andere Zahlarten zu verweisen.
                                                <br/>
                                                    <br/>
                                                        4.4 Folgende Mahnkosten sind vom Nutzer zu erstatten:<br/>
                                                        Rücklastschrift bei SEPA Verfahren vom Nutzer zu vertreten: EUR
                                                        4,50 <br/>
                                                        Aufwand wegen Nichtabbuchung bei Kreditkartenzahlung vom Nutzer
                                                        zu vertreten: EUR 4,50 <br/>
                                                        Mahngebühr: EUR 2,80<br/>
                                                        Dem Nutzer bleibt vorbehalten, geringere Kosten nachzuweisen.
                                                        <br/>
                                                            <br/>
                                                                4.5 Gerät der Nutzer mit der Zahlung in Verzug, ist
                                                                Jestoro berechtigt, den jeweiligen Dienst zu sperren.
                                                                Die Sperre wird
                                                                unverzüglich nach Verbuchung des rückständigen Betrages
                                                                bei Jestoro aufgehoben. Die Sperre entbindet den Nutzer
                                                                nicht von seiner
                                                                Zahlungspflicht.
                                                                <br/>
                                                                    <br/>
                                                                        4.6 Sämtliche Informationen zur Mitgliedschaft
                                                                        werden auf elektronischem Weg auf die vom Nutzer
                                                                        mitgeteilte E-Mail-Adresse
                                                                        übermittelt. Bei Auswahl der Zahlung per
                                                                        Rechnung, stimmt der Nutzer zu, dass er
                                                                        Rechnungen elektronisch erhält. Elektronische
                                                                        Rechnungen werden dem Nutzer im PDF Format an
                                                                        die mitgeteilte E-Mail-Adresse zugestellt.
                            </p>
                            <div className="divider"></div>
                            <h4><strong>5. Vertragsverlängerung und Kündigung</strong></h4>
                            <p>
                                5.1 Der Vertrag über die Mitgliedschaft ist von beiden Parteien jeweils mit einer Frist
                                von zwei Wochen zum Ablauf der vereinbarten
                                Vertragslaufzeit kündbar. Die Kündigung des Nutzers hat in Textform zu erfolgen.
                                Die Frist ist gewahrt, wenn die Kündigung vor Fristablauf Jestoro zugeht; auf die
                                Rechtzeitigkeit der Absendung kommt es nicht an.
                                <br/>
                                    <br/>
                                        5.2 Erfolgt eine Kündigung nicht, verlängert sich der Vertrag jeweils
                                        automatisch um die vereinbarte Mindestlaufzeit. Handelt es sich
                                        bei der Mitgliedschaft um das Platin-Paket, dann wird der Vertrag automatisch um
                                        weitere zwölf Monate zum halben Platin-Paket Preis
                                        verlängert. Handelt es sich bei der Mitgliedschaft um das Schnupper-Paket, dann
                                        geht das Paket ungekündigt nach Ablauf der Laufzeit
                                        in das Basis-Paket über.
                                        <br/>
                                            <br/>
                                                5.3 Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt
                                                unberührt. Ein wichtiger Grund liegt für Jestoro
                                                insbesondere vor:
                                                <br/>
                                                    <br/>
                                                        5.3.1 wenn der Nutzer nicht hinreichend sichergestellt hat, dass
                                                        Minderjährige keinen Zugang zu den Diensten von Jestoro haben,
                                                        <br/>
                                                            <br/>
                                                                5.3.2 wenn der Nutzer nach Verzugseintritt offene
                                                                Rechnungsbeträge in nicht unerheblicher Höhe grundlos
                                                                nicht begleicht,
                                                                <br/>
                                                                    <br/>
                                                                        5.3.3 wenn der Nutzer bei seinen Gläubigern ein
                                                                        Schuldenmoratorium anstrebt, gegen den Nutzer
                                                                        ein Verfahren zur Abgabe der
                                                                        eidesstattlichen Versicherung eingeleitet, über
                                                                        sein Vermögen ein Vergleichs- oder
                                                                        Insolvenzverfahren eröffnet wird oder er die
                                                                        Eröffnung eines solchen Verfahrens beantragt hat
                                                                        oder in den Vermögensverhältnissen des Nutzers
                                                                        eine sonstige wesentliche
                                                                        Verschlechterung eintritt, die befürchten lässt,
                                                                        dass dieser seinen Verpflichtungen zeitweise
                                                                        oder dauernd nicht nachkommen kann,
                                                                        es sei denn, er leistet innerhalb von 10 Tagen
                                                                        nach Aufforderung eine angemessene
                                                                        Sicherheitsleistung,
                                                                        <br/>
                                                                            <br/>
                                                                                5.3.4 wenn der Nutzer falsche Angaben
                                                                                hinsichtlich der Bankverbindung, der
                                                                                Kreditkarte oder der Anschrift macht (in
                                                                                diesem Fall
                                                                                wird dem Nutzer zunächst eine
                                                                                zweiwöchige Frist zur Berichtigung der
                                                                                Angaben eingeräumt).
                                                                                <br/>
                                                                                    <br/>
                                                                                        5.4 Der Nutzer hat bei einer
                                                                                        außerordentlichen Kündigung,
                                                                                        deren Gründe er zu vertreten
                                                                                        hat, alle bei der
                                                                                        Abwicklung des
                                                                                        Vertragsverhältnisses
                                                                                        entstehenden Kosten zu tragen.
                                                                                        Der Nachweis eines geringeren
                                                                                        Schadens bleibt dem Nutzer
                                                                                        vorbehalten.
                            </p>
                            <div className="divider"></div>
                            <h4><strong>6. Contenteinstellung</strong></h4>
                            <p>
                                6.1 Mit dem Einstellen des Contents auf dem Portal fundorado.com kommt zwischen Jestoro
                                und dem Nutzer ein Lizenzvertrag mit folgenden Nutzungsrechten zustande: Mit dem
                                Hochladen des Contents überträgt der Nutzer Jestoro für die Dauer der Veröffentlichung
                                das zeitlich und räumlich unbeschränkte Recht, den vom Nutzer auf dem Portal
                                hochgeladenen Content zu vervielfältigen, zu verbreiten, zu veröffentlichen, öffentlich
                                zugänglich zu machen und zu senden und zu Angebotszwecken einzelne Ausschnitte
                                bereitzustellen. Jestoro ist berechtigt, unter Verwendung der eingestellten Inhalte
                                Werbung zu schalten oder diese für andere Werbezwecke zu nutzen. Ferner umfasst ist das
                                Recht, den Content zu bearbeiten, umzugestalten und neu zusammenzustellen, insbesondere
                                den Content an die zur Nutzung erforderlichen Dateiformate anzupassen und die
                                Darstellungsqualität zu ändern und/oder zu verbessern.
                                <br/>
                                    <br/>
                                        6.2 Das Nutzungsrecht kann vom Nutzer für einzelnen Content durch manuelles
                                        Löschen oder insgesamt durch Kündigung der Mitgliedschaft gemäß Ziffer 5.1
                                        widerrufen werden.
                                        <br/>
                                            <br/>
                                                6.3 Mit der Übertragung von Content auf die Server von Jestoro
                                                versichert und verpflichtet sich der Nutzer gegenüber Jestoro,
                                                <br/>
                                                    <br/>
                                                        6.3.1 nicht gegen gesetzliche Bestimmungen oder Rechte Dritter
                                                        zu verstoßen, insbesondere Verletzung(en) von Strafrecht,
                                                        Urheberrecht, Wettbewerbsrecht, Jugendschutzrecht, Marken-,
                                                        Kennzeichen- und Persönlichkeitsrechten und sonstige gewerbliche
                                                        Schutzrechte zu unterlassen. Ausdrücklich ist zudem verboten,
                                                        illegale Inhalte wie zum Beispiel gewaltverherrlichende
                                                        Darstellungen, extremistische, beleidigende, diffamierende und
                                                        rassistische Inhalte, sexuelle Handlungen mit Kindern,
                                                        Minderjährigen, Schutzbefohlenen oder Tieren oder ähnliche
                                                        Inhalte über das Portal anzubieten oder sonst zu fördern. Auch
                                                        in nachgestellter Form ist die Abbildung der genannten Inhalte
                                                        untersagt.
                                                        <br/>
                                                            <br/>
                                                                6.3.2 dass er das vollständige und alleinige Recht an
                                                                diesem Content, sowie eine Einverständniserklärung aller
                                                                in dem Content audiovisuell erfassten Personen besitzt,
                                                                was der Contenteinsteller auf Verlangen nachzuweisen
                                                                hat. Zudem versichert der Contenteinsteller, dass
                                                                sämtliche audiovisuell erfasste Personen das 18.
                                                                Lebensjahr vollendet haben.
                                                                <br/>
                                                                    <br/>
                                                                        6.3.3 die von Jestoro aufgestellte
                                                                        Content-Policy unter
                                                                        https://community.fundorado.de/guidelines (der
                                                                        Nutzer muss eingeloggt sein) einzuhalten. Hierzu
                                                                        zählt insbesondere, dass sämtliche Darsteller
                                                                        sichtbar freiwillig an den Handlungen teilnehmen
                                                                        müssen. Jestoro untersagt ausdrücklich die
                                                                        Darstellung von Teens/Teenagern,
                                                                        Gewaltdarstellungen und Gewalttexten, Inzestuöse
                                                                        Darstellungen und Texten sowie jegliche
                                                                        Darstellungen und Texte mit Kirchen- oder
                                                                        Religionsbezug oder mit religiösem Umfeld.
                                                                        <br/>
                                                                            <br/>
                                                                                6.4 Dem Nutzer ist es untersagt, Content
                                                                                doppelt einzustellen, auch nicht in
                                                                                verschiedenen Versionen oder unter
                                                                                verschiedenen Accounts.
                                                                                <br/>
                                                                                    <br/>
                                                                                        6.5 Der Nutzer ist dafür
                                                                                        verantwortlich, dass die von ihm
                                                                                        eingestellten Inhalte frei von
                                                                                        Viren, Trojanern und Würmern
                                                                                        sind, die die Funktionsfähigkeit
                                                                                        oder den Bestand von Jestoro.de
                                                                                        gefährden oder beeinträchtigen
                                                                                        können.
                                                                                        <br/>
                                                                                            <br/>
                                                                                                6.6 Jestoro behält sich
                                                                                                vor, die eingestellten
                                                                                                Licht- und Laufbilder
                                                                                                ohne vorherige
                                                                                                Benachrichtigung oder
                                                                                                Einwilligung des Nutzers
                                                                                                zu löschen oder zu
                                                                                                sperren, wenn diese oder
                                                                                                der Nutzer gegen diese
                                                                                                AGB, die Content-Policy
                                                                                                und/oder gegen das
                                                                                                Gesetz verstoßen. Dies
                                                                                                gilt auch, wenn der
                                                                                                Verdacht einer
                                                                                                Verletzung vorliegt.
                                                                                                <br/>
                                                                                                    <br/>
                                                                                                        6.7 Der Nutzer
                                                                                                        stellt Jestoro
                                                                                                        auf erstes
                                                                                                        Anfordern von
                                                                                                        allen Ansprüchen
                                                                                                        Dritter frei,
                                                                                                        die diese wegen
                                                                                                        der Verletzung
                                                                                                        ihrer Rechte,
                                                                                                        insbesondere von
                                                                                                        Urheber-,
                                                                                                        Lizenz-,
                                                                                                        Wettbewerbs-,
                                                                                                        Persönlichkeits-
                                                                                                        oder sonstigen
                                                                                                        Schutzrechten
                                                                                                        aufgrund der von
                                                                                                        ihm
                                                                                                        übermittelten
                                                                                                        Inhalte gegen
                                                                                                        Jestoro geltend
                                                                                                        machen. Sollte
                                                                                                        eine Belastung
                                                                                                        bestehen, so
                                                                                                        verpflichtet
                                                                                                        sich der Nutzer,
                                                                                                        diese Belastung
                                                                                                        unverzüglich auf
                                                                                                        eigene Kosten zu
                                                                                                        beseitigen.
                                                                                                        <br/>
                                                                                                            <br/>
                                                                                                                6.8 Der
                                                                                                                Nutzer
                                                                                                                erstattet
                                                                                                                Jestoro
                                                                                                                die
                                                                                                                Kosten
                                                                                                                der
                                                                                                                Rechtsverfolgung,
                                                                                                                die
                                                                                                                aufgrund
                                                                                                                der
                                                                                                                rechtswidrigen
                                                                                                                Inhalte
                                                                                                                entstehen.
                                                                                                                Der
                                                                                                                Nutzer
                                                                                                                wird
                                                                                                                Jestoro
                                                                                                                bei der
                                                                                                                Erlangung
                                                                                                                und
                                                                                                                Verteidigung
                                                                                                                von
                                                                                                                Rechten
                                                                                                                an von
                                                                                                                diesem
                                                                                                                Vertrag
                                                                                                                erfassten
                                                                                                                Content
                                                                                                                unterstützen,
                                                                                                                insbesondere
                                                                                                                erforderliche
                                                                                                                Auskünfte
                                                                                                                erteilen.
                                                                                                                Der
                                                                                                                Nutzer
                                                                                                                wird
                                                                                                                außerdem
                                                                                                                alle
                                                                                                                erforderlichen
                                                                                                                Erklärungen
                                                                                                                abgeben
                                                                                                                und alle
                                                                                                                Dokumente
                                                                                                                unterzeichnen,
                                                                                                                die für
                                                                                                                den
                                                                                                                genannten
                                                                                                                Zweck
                                                                                                                erforderlich
                                                                                                                oder von
                                                                                                                Jestoro
                                                                                                                gewünscht
                                                                                                                werden,
                                                                                                                um
                                                                                                                dieser
                                                                                                                den
                                                                                                                vollen
                                                                                                                und
                                                                                                                ausschließlichen
                                                                                                                Nutzen
                                                                                                                und die
                                                                                                                Vorteile
                                                                                                                des
                                                                                                                vertragsgegenständlichen
                                                                                                                Contents
                                                                                                                zu
                                                                                                                sichern
                                                                                                                und
                                                                                                                diesen
                                                                                                                zu
                                                                                                                verwerten.
                                                                                                                Die
                                                                                                                vorstehenden
                                                                                                                Verpflichtungen
                                                                                                                gelten
                                                                                                                auch
                                                                                                                nach der
                                                                                                                Beendigung
                                                                                                                dieses
                                                                                                                Vertrages.
                            </p>
                            <div className="divider"></div>
                            <h4><strong>7. Haftung</strong></h4>
                            <p>
                                7.1 Der Nutzer stellt Jestoro von jeglichen Ansprüchen Dritter aufgrund widerrechtlicher
                                Nutzung der "FunDorado-Webseite
                                und/oder -Dienste" durch den Nutzer frei. Der Nutzer unterrichtet Jestoro unverzüglich
                                von jeglicher Inanspruchnahme Dritter
                                aufgrund einer widerrechtlichen Nutzung von der "FunDorado-Webseite und/oder -Dienste".
                                <br/>
                                    <br/>
                                        7.2 Der Nutzer erklärt, dass er, wenn er absichtlich oder unabsichtlich,
                                        wissentlich oder unwissentlich Dritten die Benutzung
                                        seines Zugangs bzw. seines Login-Namens ermöglicht, voll verantwortlich für alle
                                        Online-Handlungen dieser Personen ist. Der Nutzer
                                        haftet für jeglichen insoweit auftretenden Missbrauch und hat alle anfallenden
                                        Kosten der unberechtigten Nutzung zu tragen. Dies
                                        gilt insbesondere auch für Umsätze, Käufe, Vertragserweiterungen und die
                                        Bestellung von zusätzlichen kostenpflichtigen Angeboten.
                                        <br/>
                                            <br/>
                                                7.3 Jestoro ist für fremde Inhalte nur dann verantwortlich,
                                                <br/>
                                                    <br/>
                                                        7.3.1 wenn Jestoro Kenntnis von den rechtswidrigen Handlungen
                                                        oder den Informationen hat und im Falle von
                                                        Schadensersatzansprüchen auch die Tatsachen oder Umstände kennt,
                                                        aus denen die rechtswidrige Handlung oder die Information
                                                        offensichtlich wird und
                                                        <br/>
                                                            <br/>
                                                                7.3.2 wenn Jestoro nicht unverzüglich tätig geworden
                                                                ist, um den Inhalt zu entfernen oder den Zugang zu ihm
                                                                zu sperren, sobald
                                                                Jestoro diese Kenntnis erlangt hat. Die
                                                                Verantwortlichkeit von Jestoro für fremde Links richtet
                                                                sich nach Satz 1. Dasselbe gilt
                                                                für alle Inhalte der Seiten, die innerhalb des Angebotes
                                                                von Jestoro in sog. "Frames" unter einer
                                                                "FunDorado-Navigationsleiste"
                                                                dargestellt werden.
                                                                <br/>
                                                                    <br/>
                                                                        7.4 Jestoro haftet darüber hinaus für
                                                                        vorsätzlich und grob fahrlässig verursachte
                                                                        Schäden, Ansprüche nach dem
                                                                        Produkthaftungsgesetz
                                                                        sowie bei einer Verletzung des Lebens, des
                                                                        Körpers oder der Gesundheit nach den
                                                                        gesetzlichen Bestimmungen. Für Schäden, die auf
                                                                        einfacher Fahrlässigkeit von Jestoro oder ihrer
                                                                        Erfüllungsgehilfen beruhen, haftet Jestoro nur,
                                                                        wenn eine wesentliche Vertragspflicht
                                                                        verletzt wurde, deren Erfüllung die
                                                                        ordnungsgemäße Durchführung des Vertrages
                                                                        überhaupt erst ermöglicht, deren Verletzung die
                                                                        Erreichung
                                                                        des Vertragszwecks gefährdet und auf deren
                                                                        Einhaltung der Nutzer regelmäßig vertraut. Im
                                                                        letztgenannten Fall haftet Jestoro jedoch
                                                                        nicht auf nicht vorhersehbaren, nicht
                                                                        vertragstypischen Schaden.
                                                                        <br/>
                                                                            <br/>
                                                                                7.5 Jestoro haftet nicht für
                                                                                Beeinträchtigungen, Beschränkungen oder
                                                                                Leistungshindernisse, sowie
                                                                                Verzögerungen und Unterbrechungen
                                                                                der Dienstleistungen, die auf Umständen
                                                                                außerhalb ihres Verantwortungsbereiches
                                                                                beruhen. Insbesondere übernimmt Jestoro
                                                                                keine Gewähr
                                                                                für eine ununterbrochene Verfügbarkeit
                                                                                und das jederzeitige Zustandekommen von
                                                                                Verbindungen und die konstante
                                                                                Aufrechterhaltung eines
                                                                                bestimmten Datendurchsatzes oder die
                                                                                Datendurchführung über ein bestimmtes
                                                                                Netz.
                            </p>

                            <div className="divider"></div>

                            <h4><strong>8. Schlussbestimmungen</strong></h4>
                            <p>
                                8.1 Die von Jestoro zur Verfügung gestellten Angebote und Inhalte sind urheberrechtlich
                                geschützt. Es wird dem Nutzer nicht gestattet,
                                diese Inhalte auf seinen privaten oder gewerblich genutzten Rechner herunter zu laden
                                oder auszudrucken. Zu weitergehenden
                                Nutzungshandlungen, wie z.B. Kopieren, Vertrieb oder Veröffentlichung ist der Nutzer
                                nicht berechtigt.
                                <br/>
                                    <br/>
                                        8.2 Jestoro behält sich das Recht vor, diese AGB, die Leistungsbeschreibungen
                                        oder die Preislisten zur Wiederherstellung der
                                        Ausgewogenheit des Vertragsverhältnisses zu ändern, sofern dies aufgrund von
                                        unvorhersehbaren, insbesondere technischen, rechtlichen
                                        oder regulatorischen Veränderungen nach Vertragsschluss erforderlich ist oder
                                        dadurch erforderlich geworden ist, dass Dritte, von denen
                                        Jestoro notwendige Vorleistungen bezieht, ihr Leistungsangebot oder ihre Preise
                                        ändern. Eine Preisänderung ist auf den Umfang dieser
                                        Kostenänderung begrenzt.
                                        <br/>
                                            <br/>
                                                8.3 Eine Änderung gemäß Ziffer 8.2 ist nur dann zulässig, soweit
                                                hierdurch wesentliche Regelungen des Vertragsverhältnisses (also
                                                insbesondere solche über Art und Umfang der vertraglich vereinbarten
                                                Leistungen und die Laufzeit einschließlich der Regelung zur Kündigung)
                                                nicht berührt werden und die Änderung unter Abwägung der beiderseitigen
                                                Interessen der Parteien für den Nutzer zumutbar ist.
                                                <br/>
                                                    <br/>
                                                        8.4 Nach Ziffer 8.2 beabsichtigte Änderungen - die nicht
                                                        ausschließlich durch eine Erhöhung der Umsatzsteuer bedingt sind
                                                        - werden
                                                        dem Nutzer mindestens 6 Wochen vor ihrem Wirksamwerden
                                                        schriftlich mitgeteilt. Widerspricht der Nutzer der Änderung
                                                        nicht innerhalb
                                                        einer 6-wöchigen Frist ab Zugang der Mitteilung, wird diese zum
                                                        Zeitpunkt des Wirksamwerdens Vertragsbestandteil. Jestoro
                                                        verpflichtet
                                                        sich, den Nutzer in der Änderungsmitteilung auf die Folgen eines
                                                        unterlassenen Widerspruchs hinzuweisen.
                                                        <br/>
                                                            <br/>
                                                                8.5 Die Übertragung der Rechte und Pflichten aus diesem
                                                                Vertrag durch eine Partei auf einen oder mehrere Dritte
                                                                ist nur mit
                                                                vorheriger Zustimmung der jeweils anderen Partei
                                                                zulässig. Ungeachtet des vorstehenden
                                                                Übertragungsverbots ist es Jestoro gestattet,
                                                                diesen Vertrag auf ein mit Jestoro i.S.d. §§ 15 ff. AktG
                                                                verbundenes Unternehmen zu übertragen.
                                                                <br/>
                                                                    <br/>
                                                                        8.6 Sollten eine oder mehrere Bestimmungen
                                                                        dieser Vereinbarung unwirksam oder
                                                                        undurchführbar sein oder werden, so wird die
                                                                        Vereinbarung im Übrigen nicht davon berührt. An
                                                                        Stelle der unwirksamen oder undurchführbaren
                                                                        Klausel soll nach dem Willen der
                                                                        Parteien, eine dem wirtschaftlichen und
                                                                        haftungsrechtlichen Zweck des Vertrags
                                                                        entsprechende zulässige und rechtmäßige Klausel
                                                                        treten.
                                                                        <br/>
                                                                            <br/>
                                                                                8.7 Für sämtliche Rechtsgeschäfte und
                                                                                Rechtsverhältnisse zwischen Jestoro und
                                                                                dem Nutzer gilt das Recht der
                                                                                Bundesrepublik Deutschland
                                                                                unter Ausschluss des UN-Übereinkommens
                                                                                über Verträge über den internationalen
                                                                                Warenkauf, gegenüber Verbrauchern jedoch
                                                                                nur insoweit,
                                                                                als dem Verbraucher nicht der Schutz
                                                                                entzogen wird, der durch zwingende
                                                                                Bestimmungen des Staates gewährt wird,
                                                                                in dem er seinen gewöhnlichen Aufenthalt
                                                                                hat.
                                                                                <br/>
                                                                                    <br/>
                                                                                        8.8 Zur außergerichtlichen
                                                                                        Streitbeilegung steht
                                                                                        Verbrauchern bei Online-Kauf-
                                                                                        und Dienstleistungsverträgen die
                                                                                        Möglichkeit der
                                                                                        Online-Streitbeilegung der
                                                                                        Europäischen Kommission (im
                                                                                        Folgenden „OS“) über die
                                                                                        OS-Plattform zur Verfügung.
                                                                                        Diese ist unter folgendem
                                                                                        Link erreichbar: <a
                                                                                        href="http://ec.europa.eu/consumers/odr/"
                                                                                        target="_blank">http://ec.europa.eu/consumers/odr/</a>.
                                                                                        <br/>
                                                                                            <br/>
                                                                                                8.9 Hinweis nach § 36
                                                                                                Verbraucherstreitbeilegungsgesetz:
                                                                                                Jestoro ist weder
                                                                                                verpflichtet noch
                                                                                                bereit, an einem
                                                                                                Streitbeilegungsverfahren
                                                                                                vor einer
                                                                                                Verbraucherschlichtungsstelle
                                                                                                teilzunehmen.
                                                                                                <br/>
                                                                                                    <br/>
                                                                                                        8.10 Als
                                                                                                        Erfüllungsort
                                                                                                        für alle
                                                                                                        beidseitigen,
                                                                                                        aus dem Vertrag
                                                                                                        geschuldeten
                                                                                                        Leistungen,
                                                                                                        einschließlich
                                                                                                        eventueller
                                                                                                        Rückgewährleistungsansprüche,
                                                                                                        wird - soweit
                                                                                                        gesetzlich
                                                                                                        zulässig -
                                                                                                        Hamburg
                                                                                                        vereinbart.
                                                                                                        <br/>
                                                                                                            <br/>
                                                                                                                8.11 Für
                                                                                                                Streitigkeiten
                                                                                                                aus oder
                                                                                                                im
                                                                                                                Zusammenhang
                                                                                                                mit der
                                                                                                                Nutzung
                                                                                                                von
                                                                                                                Angeboten
                                                                                                                von
                                                                                                                Jestoro
                                                                                                                oder aus
                                                                                                                diesen
                                                                                                                Geschäftsbedingungen
                                                                                                                sind die
                                                                                                                Gerichte
                                                                                                                in
                                                                                                                Hamburg
                                                                                                                ausschließlich
                                                                                                                zuständig,
                                                                                                                sofern
                                                                                                                der
                                                                                                                Nutzer
                                                                                                                Kaufmann
                                                                                                                ist oder
                                                                                                                keinen
                                                                                                                festen
                                                                                                                Wohnsitz
                                                                                                                in
                                                                                                                Hamburg
                                                                                                                hat,
                                                                                                                seinen
                                                                                                                Wohnsitz
                                                                                                                oder
                                                                                                                gewöhnlichen
                                                                                                                Aufenthaltsort
                                                                                                                nach
                                                                                                                Wirksamwerden
                                                                                                                dieser
                                                                                                                Bedingungen
                                                                                                                ins
                                                                                                                Ausland
                                                                                                                verlegt
                                                                                                                hat oder
                                                                                                                wenn
                                                                                                                sein
                                                                                                                Wohnsitz
                                                                                                                oder
                                                                                                                gewöhnlicher
                                                                                                                Aufenthaltsort
                                                                                                                zum
                                                                                                                Zeitpunkt
                                                                                                                der
                                                                                                                Klageerhebung
                                                                                                                nicht
                                                                                                                bekannt
                                                                                                                ist.
                                                                                                                <br/>
                                                                                                                    <br/>
                                                                                                                        <strong>Stand:
                                                                                                                            03.03.2021</strong>
                            </p>

                            <div className="textbox download">
                                <i className="fa fa-download fa"></i>
                                <a href="downloads/agb_fundorado.pdf" target="_blank" title="agb_fundorado">Download als
                                    pdf</a>
                            </div>

                            <div className="divider"></div>
                            <p><a href="index.html#agb" className="backlink" title="AGB">zurück zur Übersicht</a></p>
                        </div>
                    </div>
                </div>
            }
            {page === 'widerrufsrecht' &&
                <div className="container">
                    <div className="row caption">
                        <div className="col-lg-12">
                    <span>
                        <h1 className="text-2xl">Widerrufsrecht FunDorado.com</h1>
                    </span>
                            <span>
                        <h2>Widerrufsbelehrung</h2>
                    </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 textbox">
                            <br/>
                                <h4><strong>Widerrufsrecht:</strong></h4>
                                Du hast das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
                                widerrufen.
                                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
                                Um dein Widerrufsrecht auszuüben, musst du uns (Jestoro GmbH, Kundenservice,
                                Deelbögenkamp 4, 22297 Hamburg, Service-Hotline +49 40 328 905 353, oder
                                E-Mail: service@fundorado.com) mittels einer eindeutigen
                                Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über deinen
                                Entschluss,
                                diesen Vertrag zu widerrufen, informieren. Du kannst dafür das beigefügte
                                Muster-Widerrufsformular verwenden,
                                das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus,
                                dass du die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                                Widerrufsfrist absendest.
                                <br/><br/>
                                    <h4><strong>Widerrufsfolgen:</strong></h4>
                                    Wenn du diesen Vertrag widerrufst, haben wir dir alle Zahlungen, die wir von dir
                                    erhalten haben,
                                    einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich
                                    daraus ergeben,
                                    dass du eine andere Art der Lieferung als die von uns angebotene, günstigste
                                    Standardlieferung gewählt hast),
                                    unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem
                                    die Mitteilung über
                                    deinen Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                                    verwenden wir dasselbe Zahlungsmittel,
                                    das du bei der ursprünglichen Transaktion eingesetzt hast, es sei denn, mit dir
                                    wurde ausdrücklich
                                    etwas anderes vereinbart; in keinem Fall werden dir wegen dieser Rückzahlung
                                    Entgelte berechnet.
                                    Hast du verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen
                                    sollen,
                                    so hast du uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem
                                    Zeitpunkt,
                                    zu dem du uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags
                                    unterrichtest,
                                    bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag
                                    vorgesehenen
                                    Dienstleistungen entspricht.
                                    <br/><br/>
                                        Hast Du von uns verlangt, dass wir vor Ablauf der Widerrufsfrist mit der
                                        Dienstleistung beginnen sollen, verlierst Du mit Beginn der Ausführung Dein
                                        Widerrufsrecht.
                                        <br/><br/>
                                            <h4><strong>- Ende der Widerrufsbelehrung -</strong></h4>
                                            Das Widerrufsrecht besteht nur, sofern du ein Rechtsgeschäft zu Zwecken
                                            abschließt,
                                            die weder überwiegend deiner gewerblichen noch deiner selbständigen
                                            beruflichen
                                            Tätigkeit zugerechnet werden können.
                                            <br/><br/>
                                                <a href="widerrufsformular_muster_fundorado.html" className="link"
                                                   title="Muster-Widerrufsformular FunDorado.com">zum
                                                    Muster-Widerrufsformular</a>
                                                <br/><br/>
                                                    <div className="textbox download">
                                                        <i className="fa fa-download fa"></i>
                                                        <a href="downloads/muster_widerrufsformular_fundorado.pdf"
                                                           target="_blank"
                                                           title="Muster-Widerrufsformular FunDorado.com">Download
                                                            Muster-Widerrufsformular als pdf</a>
                                                    </div>
                                                    <br/><br/>
                                                        <p><a href="index.html#agb" className="backlink">zurück zur
                                                            Übersicht</a></p>
                        </div>
                    </div>
                </div>
            }
            {page === 'datenschutz' &&
                <div className="container">
                    <div className="row caption">
                        <div className="col-lg-12">
                            <div className="fixed_navi_margin"></div>
                            <h2 style={{wordWrap: "break-word"}} className="text-2xl">Datenschutzbestimmungen</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="textbox">
                                <h3 className="up">Einleitung und Begriffe</h3>
                                <p className="bold up">
                                    1. Einleitung
                                </p>
                                <p className="mesh">
                                    Mit dem Betrieb unserer FunDorado Webseite (im Folgenden "Webseite" genannt)
                                    verarbeiten wir personenbezogene Daten. Diese werden von uns vertraulich behandelt
                                    und nach den geltenden Gesetzen - insbesondere der Datenschutzgrundverordnung
                                    (DSGVO) und des Bundesdatenschutzgesetzes (BDSG) verarbeitet. Mit unseren
                                    Datenschutzbestimmungen wollen wir Sie informieren, welche personenbezogenen Daten
                                    wir von Ihnen erheben, zu welchen Zwecken und auf welcher Rechtsgrundlage wir sie
                                    verwenden und gegebenenfalls wem wir sie offenlegen. Darüber hinaus werden wir Ihnen
                                    erklären, welche Rechte Ihnen zur Wahrung und Durchsetzung Ihres Datenschutzes
                                    zustehen.
                                </p>
                                <p className="bold up">
                                    2. Begriffe
                                </p>
                                <p className="mesh">
                                    Unsere Datenschutzbestimmungen enthalten Fachbegriffe, die in der DSGVO und dem BDSG
                                    stehen. Zu Ihrem besseren Verständnis wollen wir diese Begriffe in einfachen Worten
                                    vorab erklären:
                                </p>
                                <p className="boldmesh">
                                    2.1 Personenbezogene Daten
                                </p>
                                <p className="mesh">
                                    "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte
                                    oder identifizierbare Person beziehen (Art. 4 Nr. 1 DSGVO). Angaben einer
                                    identifizierten Person können z.B. der Name oder die E-Mail-Adresse sein.
                                    Personenbezogen sind aber auch Daten, bei denen die Identität nicht unmittelbar
                                    ersichtlich ist, sich aber ermitteln lässt, indem man eigene oder fremde
                                    Informationen kombiniert und so erfährt, um wen es sich handelt. Eine Person wird
                                    z.B. über die Angabe ihrer Anschrift oder Bankverbindung, ihres Geburtsdatums oder
                                    Benutzernamens, ihrer IP-Adressen und/oder Standortdaten identifizierbar. Relevant
                                    sind hier alle Informationen, die in irgendeiner Weise einen Rückschluss auf eine
                                    Person zulassen.
                                </p>
                                <p className="boldmesh">
                                    2.2 Verarbeitung
                                </p>
                                <p className="mesh">
                                    Unter einer "Verarbeitung" versteht Art. 4 Nr. 2 DSGVO jeden Vorgang im Zusammenhang
                                    mit personenbezogenen Daten. Dies betrifft insbesondere das Erheben, das Erfassen,
                                    die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
                                    Auslesen, das Abfragen, die Verwendung, die Offenlegung, Übermittlung, Verbreitung
                                    oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
                                    Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten.
                                </p>

                                <h3 className="up">VERANTWORTLICHES UNTERNEHMEN UND DATENSCHUTZBEAUFTRAGTER</h3>

                                <p className="bold up">
                                    3. VERANTWORTLICHER
                                </p>
                                <p className="mesh">
                                    Verantwortlich für die Datenverarbeitung ist:
                                </p>
                                <div className="table">
                                    <div className="tab_row">
                                        <div className="tab_cell">Unternehmen:</div>
                                        <div className="tab_cell">Jestoro GmbH ("wir")</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">Gesetzlicher Vertreter:</div>
                                        <div className="tab_cell"><img src="img/gf_dsb.svg" /> (Geschäftsführer)</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">Anschrift:</div>
                                        <div className="tab_cell">Deelbögenkamp 4, 22297 Hamburg</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">Telefon:</div>
                                        <div className="tab_cell">+49 (0)40 328 905 353</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">E-Mail:</div>
                                        <div className="tab_cell">datenschutz@jestoro.com</div>
                                    </div>
                                </div>
                                <p></p>
                                <p className="bold up">
                                    4. DATENSCHUTZBEAUFTRAGTER
                                </p>
                                <p className="mesh">
                                    Wir haben für unser Unternehmen einen externen Datenschutzbeauftragten bestellt. Sie
                                    erreichen ihn unter:
                                </p>
                                <div className="table">
                                    <div className="tab_row">
                                        <div className="tab_cell">Name:</div>
                                        <div className="tab_cell">HABEWI GmbH &amp; Co. KG</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">Ansprechpartner:</div>
                                        <div className="tab_cell">Arne Platzbecker</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">Anschrift:</div>
                                        <div className="tab_cell">Palmaille 96, 22767 Hamburg</div>
                                    </div>
                                    <div className="tab_row">
                                        <div className="tab_cell">E-Mail:</div>
                                        <div className="tab_cell">datenschutz@habewi.de</div>
                                    </div>
                                </div>
                                <p></p>

                                <h3 className="up">VERARBEITUNGSRAHMEN</h3>
                                <p className="bold up">
                                    5. VERARBEITUNGSRAHMEN: WEBSEITE
                                </p>
                                <p className="mesh">
                                    Im Rahmen der Webseite mit der URL www.fundorado.de verarbeiten wir die nachfolgend
                                    unter Ziffer 6-18 im Einzelnen aufgeführten personenbezogenen Daten von Ihnen. Wir
                                    verarbeiten nur Daten von Ihnen, die Sie aktiv auf unserer Webseite angeben (z.B.
                                    durch das Ausfüllen von Formularen) oder die Sie bei der Nutzung unseres Angebotes
                                    automatisch zur Verfügung stellen.
                                    <br/><br/>
                                        Ihre Daten werden ausschließlich von uns verarbeitet und grundsätzlich nicht an
                                        Dritte verkauft, verliehen oder weitergegeben. Sofern wir uns bei der
                                        Verarbeitung Ihrer personenbezogenen Daten der Hilfe externer Dienstleister
                                        bedienen, erfolgt dies im Rahmen einer sogenannten Auftragsverarbeitung, bei der
                                        wir als Auftraggeber unserem Auftragnehmern gegenüber weisungsbefugt sind. Zum
                                        Betrieb unserer Webseite setzen wir für das Hosting, sowie zur Wartung, Pflege
                                        und Weiterentwicklung externe Dienstleister ein. Sollten bei einzelnen, der in
                                        Ziffern 6-18 aufgeführten Verarbeitungen weitere externe Dienstleister zum
                                        Einsatz kommen, werden sie dort benannt. Soweit wir gemeinsam mit anderen über
                                        Zwecke und Mittel der Verarbeitung personenbezogener Daten entscheiden,
                                        begründet dies eine sogenannte gemeinsame Verantwortlichkeit. In diesem Fall
                                        haben wir mit der anderen Verantwortlichen Stelle eine Vereinbarung getroffen,
                                        in der festgelegt wurde, wer welche Verpflichtung gemäß der DSGVO erfüllt,
                                        insbesondere was die Wahrnehmung der Rechte der betroffenen Personen angeht und
                                        wer welchen Informationspflichten nachkommt. über den Inhalt dieser Vereinbarung
                                        und die Kontaktdaten einer zentralen Anlaufstelle informieren wir Sie an
                                        entsprechender Stelle. Eine Datenübermittlung in Drittstaaten findet
                                        grundsätzlich nicht statt und ist auch nicht geplant. über Ausnahmen von diesem
                                        Grundsatz werden wir in den nachfolgend dargestellten Verarbeitungen
                                        informieren.
                                </p>

                                <h3 className="up">DIE VERARBEITUNGEN IM EINZELNEN</h3>
                                <p className="bold up">
                                    6. BEREITSTELLUNG DER WEBSEITE UND SERVER-LOGFILES
                                </p>
                                <p className="boldmesh">
                                    6.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Bei jedem Aufruf der Webseite erfassen wir automatisch Informationen, die Ihr
                                    Browser an unseren Server übermittelt (sogenannte Logfiles). Dabei handelt es sich
                                    um die folgenden Daten:
                                </p>
                                <ul>
                                    <li>Ihre IP-Adresse</li>
                                    <li>die von Ihnen verwendete Browsersoftware, sowie deren Version und Sprache</li>
                                    <li>das von Ihnen verwendete Betriebssystem</li>
                                    <li>die Webseite, von der Sie auf unsere Webseite gelangt sind (sogenannter
                                        Referrer)
                                    </li>
                                    <li>die von Ihnen auf unserer Webseite aufgerufenen Unterseiten</li>
                                    <li>das Datum und die Uhrzeit Ihres Aufrufes unserer Webseite</li>
                                </ul>
                                <p className="mesh">
                                    Diese werden auch in den sogenannten Logfiles unseres Systems gespeichert. Die
                                    vorübergehende Speicherung Ihrer IP-Adresse durch das System ist notwendig, um
                                    unsere Webseite an das Endgerät eines Nutzers ausliefern zu können. Hierfür muss die
                                    IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Ihre
                                    IP-Adresse wird jedoch nicht in unseren Logfiles erfasst.
                                </p>
                                <p className="boldmesh">
                                    6.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt, um den Aufruf der Webseite zu ermöglichen, sowie deren
                                    Stabilität und Sicherheit zu gewährleisten. Darüber hinaus dient die Verarbeitung
                                    der statistischen Auswertung und Verbesserung unseres Online-Angebotes.
                                </p>
                                <p className="boldmesh">
                                    6.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 6.2 benannten Zweck.
                                </p>
                                <p className="boldmesh">
                                    6.4 Speicherdauer
                                </p>
                                <p className="mesh">
                                    Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
                                    nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung
                                    der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Eine Löschung
                                    der Logfiles erfolgt nach 14 Tagen.
                                </p>
                                <p className="bold up">
                                    7. REGISTRIERUNG
                                </p>
                                <p className="boldmesh">
                                    7.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Einzelne Funktionen und Angebote unserer Webseite stehen Ihnen nur als registrierter
                                    Nutzer zur Verfügung. Mit der Registrierung schließen Sie einen unentgeltlichen
                                    (Schnupper-Tarif für einen Zeitraum von 21 Tagen) bzw. einen kostenpflichtigen
                                    (Basis-, Silber-, Gold- oder Platintarif) Nutzungsvertrag mit uns ab. Sie erhalten
                                    durch die Registrierung einen eigenen Nutzer-Account auf unserer Webseite. Die
                                    Registrierung erfolgt, indem Sie das Registrierungsformular auf der FunDorado
                                    Webseite ausfüllen und uns elektronisch übermitteln. Zur Registrierung müssen Sie
                                    Ihren Namen, Vornamen, Ihre Anrede, Ihr Geburtsdatum, Ihre E-Mail-Adresse, einen
                                    frei gewählten Benutzernamen sowie das Land, den Tarif und die Daten zur Bezahlung
                                    angeben. Darunter fallen die Kontonummer, BLZ, IBAN oder alternativ
                                    Kreditkartendaten. Zusätzlich können bei der Zahlart Lastschrift freiwillig die
                                    Adressdaten angegeben werden. Bei der Zahlart Kreditkarte ist die Angabe von
                                    Adressdaten Pflicht. Mit dem Anklicken des Buttons "Anmeldung kostenpflichtig
                                    abschließen" übermitteln Sie uns das Formular. Sie erhalten anschließend eine
                                    Anmeldebestätigung mit Ihrem persönlichen Passwort, an die bei der Registrierung
                                    angegebene E-Mail-Adresse.
                                </p>
                                <p className="boldmesh">
                                    7.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt, um Ihnen die Funktionen unserer Webseite für registrierte
                                    Nutzer bereitstellen zu können.
                                </p>
                                <p className="boldmesh">
                                    7.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zum Abschluss und zur Erfüllung des Nutzungsvertrages
                                    erforderlich (Art. 6 Abs. 1 lit. b DSGVO). Ohne Angabe Ihrer personenbezogenen Daten
                                    im Rahmen der Registrierung können wir unsere vertraglich geschuldeten Leistungen
                                    nicht erbringen.
                                </p>
                                <p className="boldmesh">
                                    7.4 Speicherdauer
                                </p>
                                <p className="mesh">
                                    Ihre gespeicherten personenbezogenen Daten werden 50 Tage nach Ablauf der
                                    Mitgliedschaft pseudonymisiert. Sie haben bei einer gewünschten Reaktivierung
                                    mittels Eingabe Ihres Benutzernamens und Ihres Passwortes 50 Tage nach Kündigung die
                                    Möglichkeit Ihr Kundenkonto wieder zu reaktivieren. Nach 50 Tage werden Ihre Daten
                                    vollständig gelöscht.
                                </p>
                                <p className="bold up">
                                    8. EINKÄUFE
                                </p>
                                <p className="boldmesh">
                                    8.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Sie können als registrierter Nutzer auf unserer Webseite "Coin Guthaben" sowie
                                    Extrapakete einkaufen. Im Rahmen Ihres Bestellprozesses verarbeiten wir
                                    personenbezogene Daten von Ihnen. Die in unserem Online-Shop angegebenen Felder
                                    müssen von Ihnen ausgefüllt werden. Ansonsten ist es uns nicht möglich, mit Ihnen
                                    einen Vertrag zu schließen. Bei einem Einkauf auf unserer Webseite können Sie
                                    darüber hinaus eine der angebotenen Zahlungsarten (Kreditkarte oder Lastschrift) zur
                                    Begleichung des Kaufpreises auswählen. Beim Abschluss Ihrer Bestellung werden die
                                    für die Zahlung erforderlichen Daten an den jeweiligen Zahlungsdienstleister
                                    weitergegeben.
                                </p>
                                <p className="boldmesh">
                                    8.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt zum Abschluss und zur Abwicklung von Verträgen.
                                </p>
                                <p className="boldmesh">
                                    8.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zum Abschluss und zur Erfüllung der Kaufverträge erforderlich
                                    (Art. 6 Abs. 1 lit. b DSGVO).
                                </p>
                                <p className="boldmesh">
                                    8.4 Speicherdauer
                                </p>
                                <p className="mesh">
                                    Wir sind aufgrund von handels- und steuerrechtlichen Vorgaben verpflichtet, Ihre
                                    Adress-, Zahlungs- und Bestelldaten für die Dauer von zehn Jahren zu speichern.
                                    Allerdings nehmen wir nach zwei Jahren eine Einschränkung der Verarbeitung vor. D.h.
                                    Ihre Daten werden dann nur noch zur Einhaltung der gesetzlichen Aufbewahrungsfristen
                                    gesondert aufbewahrt und nach deren Ablauf unverzüglich gelöscht.
                                </p>
                                <p className="boldmesh">
                                    8.5 Empfänger
                                </p>
                                <p className="mesh">
                                    Zur Abwicklung Ihrer Zahlung werden personenbezogene Daten an einen der nachfolgend
                                    aufgeführten und von Ihnen im Rahmen Ihres Einkaufs ausgewählten externen
                                    Zahlungsdienstleister weitergegeben:
                                </p>
                                <ul>
                                    <li><span className="text_bold">Kreditkarte, Lastschrift:</span> ConCardis GmbH,
                                        Helfmann-Park 7, 65760 Eschborn, Deutschland
                                    </li>
                                    <li><span className="text_bold">Sofortüberweisung:</span> Klarna GmbH, Theresienhöhe
                                        12, 80339 München. Weitere Informationen zum Datenschutz bei Sofortüberweisung
                                        finden Sie unter <a
                                            href="https://www.sofort.com/payment/wizard/getCmsContent/data_protection/DE/0/de"
                                            target="_blank">https://www.sofort.com/payment/wizard/getCmsContent/data_protection/DE/0/de</a>.
                                    </li>
                                    <li><span className="text_bold">Kreditkarte:</span> American Express Company,
                                        Theodor-Heuss-Allee 112, 60486 Frankfurt am Main
                                    </li>
                                    <li><span className="text_bold">Lastschrift:</span> Computop Wirtschaftsinformatik
                                        GmbH, Schwarzenbergstr. 4, 96050 Bamberg, Deutschland
                                    </li>
                                    <li><span className="text_bold">Kreditinstitute:</span> Commerzbank AG, Frankfurt am
                                        Main, Nord-Ostsee Sparkasse, Anstalt des Öffentlichen Rechts, Südergraben 8-14,
                                        24937 Flensburg
                                    </li>
                                </ul>
                                <p></p>
                                <p className="bold up">
                                    9. KONTAKTFORMULAR UND KONTAKTAUFNAHME PER E-MAIL
                                </p>
                                <p className="boldmesh">
                                    9.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Zur Kontaktaufnahme haben wir auf unserer Webseite ein Kontaktformular
                                    bereitgestellt. In diesem Formular werden Sie gebeten, Ihre E-Mail-Adresse Ihren
                                    Namen und eine Nachricht an uns einzugeben. Wenn Sie den Button "Absenden"
                                    betätigen, werden die Daten unter Verwendung einer SSL-Verschlüsselung (siehe Ziffer
                                    19) an uns übertragen. Dadurch werden Ihre persönlichen Daten vor fremdem Zugriff
                                    geschützt. Das Kontaktformular kann nur übermittelt werden, wenn Sie durch Anklicken
                                    der entsprechenden Checkbox unsere Datenschutzbestimmungen akzeptieren. Sie können
                                    auch über die auf der Webseite angegebenen E-Mail-Adressen Kontakt mit uns
                                    aufnehmen. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen
                                    Daten durch uns verarbeitet.
                                    <br/><br/>
                                        Bitte beachten Sie, dass wir Ihnen derzeit aus technischen Gründen keine
                                        gesicherte, verschlüsselte beziehungsweise signierte Übertragung Ihrer E-Mails
                                        an uns anbieten können. Die Kommunikation per E-Mail hat unter anderem den
                                        Nachteil, dass Unbefugte unverschlüsselte E-Mails abfangen können. Diese können
                                        Ihre E-Mails lesen, verändern und den Absender einer E-Mail manipulieren. Damit
                                        geht die Verletzung von Vertraulichkeit, Integrität und Authentizität einher.
                                        Versenden Sie daher vertrauliche Informationen alternativ bitte per Post an die
                                        oben angegebene Anschrift an uns.
                                </p>
                                <p className="boldmesh">
                                    9.2 Zweck
                                </p>
                                <p className="mesh">
                                    Mit der Bereitstellung eines Kontaktformulars auf unserer Webseite wollen wir Ihnen
                                    eine bequeme Möglichkeit anbieten, sich mit uns in Verbindung zu setzen. Die mit und
                                    in dem Kontaktformular bzw. Ihrer E-Mail übermittelten Daten werden ausschließlich
                                    zum Zwecke der Bearbeitung und Beantwortung Ihres Anliegens verwendet.
                                </p>
                                <p className="boldmesh">
                                    9.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 9.2 benannten Zweck. Es ist Ihnen selbst
                                    überlassen, ob Sie die Kommunikation mit uns über das bereitgestellte
                                    Kontaktformular oder via E-Mail führen wollen. Die Kommunikation über das
                                    Kontaktformular ermöglicht sogar eine sichere Übermittlung der Daten und ist im
                                    Verhältnis zur E-Mail-Kommunikation weniger invasiv. Sofern der E-Mail-Kontakt auf
                                    den Abschluss oder der Erfüllung eines Vertrages abzielt, so erfolgt die
                                    Datenverarbeitung zur Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO).
                                </p>
                                <p className="boldmesh">
                                    9.4 Speicherdauer
                                </p>
                                <p className="mesh">
                                    Die Daten werden von uns gelöscht, sobald sie für die Erreichung des Zweckes ihrer
                                    Erhebung nicht mehr erforderlich sind. Dies ist für gewöhnlich der Fall, wenn die
                                    jeweilige Kommunikation mit Ihnen beendet ist. Beendet ist die Kommunikation dann,
                                    wenn sich aus den Umständen entnehmen lässt, dass Ihr Anliegen abschließend geklärt
                                    ist. Sofern gesetzliche Aufbewahrungsfristen einer Löschung entgegenstehen, erfolgt
                                    eine Löschung unverzüglich nach Ablauf der gesetzlichen Aufbewahrungsfrist.
                                </p>
                                <p className="bold up">
                                    10. COOKIES
                                </p>
                                <p className="boldmesh">
                                    10.1 Beschreibung der Verarbeitung
                                </p>
                                <div className="mesh">
                                    Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um kleine
                                    Textdateien, die beim Besuch einer Webseite auf dem Endgerät des Nutzers gespeichert
                                    werden. Cookies enthalten Informationen, die die Wiedererkennung eines Endgerätes
                                    und ggf. bestimmte Funktionen einer Webseite ermöglichen. Zumeist setzen wir
                                    lediglich so genannte „Session-Cookies“ ein. Diese werden automatisch gelöscht, wenn
                                    Sie Ihre Internetsitzung beenden und den Browser schließen. Andere Cookies bleiben
                                    für einen längeren Zeitraum auf Ihrem Endgerät gespeichert. Auf unserer Webseite
                                    nutzen wir die folgenden Cookies:

                                    <div>
                                        <div className="CookieDeclaration" lang="en" dir="ltr"><p
                                            className="CookieDeclarationDialogText">This website uses cookies. We use
                                            cookies to personalise content and ads, to provide social media features and
                                            to analyse our traffic. We also share information about your use of our site
                                            with our social media, advertising and analytics partners who may combine it
                                            with other information that you’ve provided to them or that they’ve
                                            collected from your use of their services. </p>  <p
                                            className="CookieDeclarationIntro">Cookies are small text files that can be
                                            used by websites to make a user's experience more efficient.<br/><br/>The law
                                                states that we can store cookies on your device if they are strictly
                                                necessary for the operation of this site. For all other types of cookies
                                                we need your permission.<br/><br/>This site uses different types of
                                                    cookies. Some cookies are placed by third party services that appear
                                                    on our pages.<br/><br/>You can at any time change or withdraw your
                                                        consent from the Cookie Declaration on our website.<br/><br/>Learn
                                                            more about who we are, how you can contact us and how we
                                                            process personal data in our Privacy Policy.<br/><br/> Please
                                                                state your consent ID and date when you contact us
                                                                regarding your consent.</p>  <p>Your consent applies to
                                            the following domains: www.fundorado.de, stellamica.fundorado.de,
                                            rednex2020.de, mtlp.fundorado.de, model-aische.de, mobil.fundorado.de,
                                            micaela.fundorado.de, info.fundorado.com, girldorado.de, fundorado.de,
                                            club.fundorado.de, anmeldung.fundorado.de</p>
                                            <div id="CookieDeclarationUserStatusPanel"
                                                 style={{display:"block", margin: "18px 0px 0px"}}
                                                 data-responsemode="leveloptin"
                                                 data-dialogtemplate="slideup_customcolor">
                                                <div id="CookieDeclarationUserStatusLabel" style={{display: "block"}}>
                                                    <div id="CookieDeclarationUserStatusLabelOff"
                                                         style={{display: "none"}}>Your current state: Deny.&nbsp;</div>
                                                    <div id="CookieDeclarationUserStatusLabelOffDoNotSell"
                                                         style={{display: "none"}}>Your current state: Do Not Sell My
                                                        Personal Information.&nbsp;</div>
                                                    <div id="CookieDeclarationUserStatusLabelMulti"
                                                         style={{display: "none"}}>Your current state: Allow
                                                        selection&nbsp;(Necessary<span
                                                            id="CookieDeclarationUserStatusLabelMultiSettingsPref"
                                                            style={{display: "inline"}}>, Preferences</span><span
                                                            id="CookieDeclarationUserStatusLabelMultiSettingsStat"
                                                            style={{display: "inline"}}>, Statistics</span><span
                                                            id="CookieDeclarationUserStatusLabelMultiSettingsMark"
                                                            style={{display: "inline"}}>, Marketing</span>).&nbsp;</div>
                                                    <div id="CookieDeclarationUserStatusLabelOn"
                                                         style={{display: "inline-block"}}>Your current state: Allow
                                                        all.&nbsp;</div>
                                                    <p style={{display: "block"}} id="CookieDeclarationConsentIdAndDate">
                                                        <span style={{display: "block"}}>Your consent ID: <span
                                                            id="CookieDeclarationUserStatusLabelConsentId">2a5ziRgtfYAzMN69JSKgJRiTsjWMNIGCc43pOH4uwMyy0HPJ4aecoQ==</span></span><span
                                                        style={{display: "block"}}>Consent date: <span
                                                        id="CookieDeclarationUserStatusLabelConsentDate">Tuesday, July 6, 2021, 04:13:12 PM GMT+2</span></span>
                                                    </p></div>
                                                <div id="CookieDeclarationChangeConsent" style={{display: "block"}}><a
                                                    id="CookieDeclarationChangeConsentChange" href="#">Change your
                                                    consent</a><span id="CookieDeclarationUserStatusLabelWithdraw"
                                                                     style={{display: "inline-block"}}>&nbsp;&nbsp;|&nbsp;&nbsp;
                                                    <a id="CookieDeclarationChangeConsentWithdraw" href="#">Withdraw your consent</a></span>
                                                </div>
                                                <div id="CookieDeclarationDoNotSell" style={{display: "none"}}><a
                                                    id="CookieDeclarationChangeConsentDoNotSell" href="#">Do Not Sell My
                                                    Personal Information</a></div>
                                            </div>
                                            <br/><p className="CookieDeclarationLastUpdated">Cookie declaration last
                                                updated on 12/3/21 by <a href="https://www.cookiebot.com"
                                                                         target="_blank" rel="noopener"
                                                                         title="Cookiebot">Cookiebot</a>: </p>
                                                <div className="CookieDeclarationType" lang="en" dir="ltr"><p
                                                    className="CookieDeclarationTypeHeader">Necessary (27) </p>    <p
                                                    className="CookieDeclarationTypeDescription">Necessary cookies help
                                                    make a website usable by enabling basic functions like page
                                                    navigation and access to secure areas of the website. The website
                                                    cannot function properly without these cookies.</p>
                                                    <table className="CookieDeclarationTable">
                                                        <colgroup>
                                                            <col style={{width: "20%"}} />
                                                                <col style={{width: "20%"}} />
                                                                    <col style={{width: "35%"}} />
                                                                        <col style={{width: "15%"}} />
                                                                            <col style={{width: "10%"}} />
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Name
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Provider
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Purpose
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Expiry
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Type
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_anbkd</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">6 days</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_dc_gtm_UA-#
                                                                [x3]
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de<br/><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Used by Google
                                                                Tag Manager to control the loading of a Google Analytics
                                                                script tag.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">CONSENT [x3]</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a><br/><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Used to detect if
                                                                the visitor has accepted the marketing category in the
                                                                cookie banner. This cookie is necessary for
                                                                GDPR-compliance of the website.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">2 years</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">CookieConsent
                                                                [x11]
                                                            </td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://www.cookiebot.com/goto/privacy-policy/"
                                                                title="Cookiebot's privacy policy">Cookiebot</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                cookie consent state for the current domain
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 year</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">CookieConsentBulkSetting-#</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://www.cookiebot.com/goto/privacy-policy/"
                                                                title="Cookiebot's privacy policy">Cookiebot</a></td>
                                                            <td className="CookieDeclarationTableCell">Enables cookie
                                                                consent across multiple websites
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">elementor</td>
                                                            <td className="CookieDeclarationTableCell">stellamica.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Used in context
                                                                with the website's WordPress theme. The cookie allows
                                                                the website owner to implement or change the website's
                                                                content in real-time.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">ffplugin</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">funRefererCookie</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">funtarget2</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">gtm_dev_id</td>
                                                            <td className="CookieDeclarationTableCell">fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">origin</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">rtrID</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">visitLog</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="CookieDeclarationType" lang="en" dir="ltr"><p
                                                    className="CookieDeclarationTypeHeader">Statistics (26) </p>    <p
                                                    className="CookieDeclarationTypeDescription">Statistic cookies help
                                                    website owners to understand how visitors interact with websites by
                                                    collecting and reporting information anonymously.</p>
                                                    <table className="CookieDeclarationTable">
                                                        <colgroup>
                                                            <col style={{width: "20%"}} />
                                                                <col style={{width: "20%"}} />
                                                                    <col style={{width: "35%"}} />
                                                                        <col style={{width: "15%"}} />
                                                                            <col style={{width: "10%"}} />
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Name
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Provider
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Purpose
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Expiry
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Type
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">__utm.gif</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Google Analytics
                                                                Tracking Code that logs details about the visitor's
                                                                browser and computer.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">Pixel Tracker
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">__utma</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Collects data on
                                                                the number of times a user has visited the website as
                                                                well as dates for the first and most recent visit. Used
                                                                by Google Analytics.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">2 years</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">__utmb</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                timestamp with the exact time of when the user accessed
                                                                the website. Used by Google Analytics to calculate the
                                                                duration of a website visit.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">__utmc</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                timestamp with the exact time of when the user leaves
                                                                the website. Used by Google Analytics to calculate the
                                                                duration of a website visit.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">__utmt</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Used to throttle
                                                                the speed of requests to the server.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">__utmz</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Collects data on
                                                                where the user came from, what search engine was used,
                                                                what link was clicked and what search term was used.
                                                                Used by Google Analytics.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">6 months</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_ga [x5]</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de<br/><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google Tag Manager's privacy policy">Google Tag
                                                                Manager</a><br/><a target="_blank"
                                                                                  rel="noopener noreferrer nofollow"
                                                                                  href="https://policies.google.com/privacy"
                                                                                  title="Google's privacy policy">Google</a><br/>www.girldorado.de
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                unique ID that is used to generate statistical data on
                                                                how the visitor uses the website.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">2 years</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_ga_# [x2]</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google Tag Manager's privacy policy">Google Tag
                                                                Manager</a><br/>www.girldorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Used by Google
                                                                Analytics to collect data on the number of times a user
                                                                has visited the website as well as dates for the first
                                                                and most recent visit.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">2 years</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_gat [x3]</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de<br/><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Used by Google
                                                                Analytics to throttle request rate
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_gid [x3]</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de<br/><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                unique ID that is used to generate statistical data on
                                                                how the visitor uses the website.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_pk.id.2.0c7d</td>
                                                            <td className="CookieDeclarationTableCell">fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_pk.ref.2.0c7d</td>
                                                            <td className="CookieDeclarationTableCell">fundorado.de</td>
                                                            <td className="CookieDeclarationTableCell">Pending</td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_pk_id# [x2]</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google Tag Manager's privacy policy">Google Tag
                                                                Manager</a></td>
                                                            <td className="CookieDeclarationTableCell">Collects
                                                                statistics on the user's visits to the website, such as
                                                                the number of visits, average time spent on the website
                                                                and what pages have been read.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 year</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_pk_ses# [x2]
                                                            </td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google Tag Manager's privacy policy">Google Tag
                                                                Manager</a></td>
                                                            <td className="CookieDeclarationTableCell">Used by Piwik
                                                                Analytics Platform to track page requests from the
                                                                visitor during the session.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">collect</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Used to send data
                                                                to Google Analytics about the visitor's device and
                                                                behavior. Tracks the visitor across devices and
                                                                marketing channels.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">Pixel Tracker
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="CookieDeclarationType" lang="en" dir="ltr"><p
                                                    className="CookieDeclarationTypeHeader">Marketing (19) </p>    <p
                                                    className="CookieDeclarationTypeDescription">Marketing cookies are
                                                    used to track visitors across websites. The intention is to display
                                                    ads that are relevant and engaging for the individual user and
                                                    thereby more valuable for publishers and third party
                                                    advertisers.</p>
                                                    <table className="CookieDeclarationTable">
                                                        <colgroup>
                                                            <col style={{width: "20%"}}  />
                                                                <col style={{width: "20%"}} />
                                                                    <col style={{width: "35%"}} />
                                                                        <col style={{width: "15%"}} />
                                                                            <col style={{width: "10%"}} />
                                                        </colgroup>
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Name
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Provider
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Purpose
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Expiry
                                                            </th>
                                                            <th scope="col" className="CookieDeclarationTableHeader"
                                                                dir="ltr">Type
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">_gcl_au [x3]</td>
                                                            <td className="CookieDeclarationTableCell">www.fundorado.de<br/><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google Tag Manager's privacy policy">Google Tag
                                                                Manager</a></td>
                                                            <td className="CookieDeclarationTableCell">Used by Google
                                                                AdSense for experimenting with advertisement efficiency
                                                                across websites using their services.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">3 months</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">pagead/landing
                                                                [x2]
                                                            </td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Collects data on
                                                                visitor behaviour from multiple websites, in order to
                                                                present more relevant advertisement - This also allows
                                                                the website to limit the number of times that they are
                                                                shown the same advertisement.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">Pixel Tracker
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">r/collect</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">This cookie is
                                                                used to send data to Google Analytics about the
                                                                visitor's device and behavior. It tracks the visitor
                                                                across devices and marketing channels.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">Pixel Tracker
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">test_cookie</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="Google's privacy policy">Google</a></td>
                                                            <td className="CookieDeclarationTableCell">Used to check if
                                                                the user's browser supports cookies.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">1 day</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">VISITOR_INFO1_LIVE</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Tries to estimate
                                                                the users' bandwidth on pages with integrated YouTube
                                                                videos.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">179 days</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">YSC</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                unique ID to keep statistics of what videos from YouTube
                                                                the user has seen.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTTP Cookie</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt.innertube::nextId</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                unique ID to keep statistics of what videos from YouTube
                                                                the user has seen.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt.innertube::requests</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Registers a
                                                                unique ID to keep statistics of what videos from YouTube
                                                                the user has seen.
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">ytidb::LAST_RESULT_ENTRY_KEY</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-cast-available</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-cast-installed</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-connected-devices</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-device-id</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Persistent</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-fast-check-period</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-session-app</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="CookieDeclarationTableCell">yt-remote-session-name</td>
                                                            <td className="CookieDeclarationTableCell"><a
                                                                target="_blank" rel="noopener noreferrer nofollow"
                                                                href="https://policies.google.com/privacy"
                                                                title="YouTube's privacy policy">YouTube</a></td>
                                                            <td className="CookieDeclarationTableCell">Stores the user's
                                                                video player preferences using embedded YouTube video
                                                            </td>
                                                            <td className="CookieDeclarationTableCell">Session</td>
                                                            <td className="CookieDeclarationTableCell">HTML Local
                                                                Storage
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div></div>
                                    </div>
                                    <script id="CookieDeclaration"
                                            src="https://consent.cookiebot.com/c2a9a811-caa2-4b61-ac8d-ff65c285b269/cd.js"
                                            type="text/javascript" async=""></script>
                                </div>
                                <p className="boldmesh">
                                    10.2 Zweck
                                </p>
                                <p className="mesh">
                                    Wir setzen Cookies ein, um unsere Webseite nutzerfreundlicher zu gestalten und die
                                    in Ziffer 10.1 beschrieben Funktionen anzubieten.
                                </p><p className="boldmesh">
                                10.3 Rechtsgrundlage
                            </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 10.2 benannten Zweck. Sofern Sie von uns im Rahmen
                                    eines Cookie-Banners bzw. Cookie-Consent-Tools um eine Einwilligung gebeten werden,
                                    besteht die Rechtsgrundlage in Art. 6 Abs. 1 lit. a DSGVO. Eine solche Einwilligung
                                    ist freiwillig.
                                </p>
                                <p className="boldmesh">
                                    10.4 Speicherdauer, Widerruf der Einwilligung
                                </p>
                                <p className="mesh">
                                    Cookies werden automatisch mit dem Ende einer Sitzung bzw. mit Ablauf der
                                    angegebenen Speicherdauer gelöscht. Da Cookies auf Ihrem Endgerät gespeichert
                                    werden, haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von
                                    Cookies. Durch eine Änderung der Einstellungen in Ihrem Internetbrowser können Sie
                                    die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte
                                    Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
                                    Werden Cookies für unsere Webseite deaktiviert, können einzelne Funktionen unserer
                                    Webseite nicht oder nur eingeschränkt genutzt werden. Sofern wir Einwilligungen zur
                                    Verwendung von Cookies über ein Cookie-Banner oder ein Cookie-Consent-Tool einholen,
                                    können diese Einwilligungen von Ihnen jederzeit innerhalb der Einstellungen des
                                    Cookie-Banners bzw. des Cookie-Consent-Tools mit Wirkung für die Zukunft widerrufen
                                    werden.
                                </p>

                                <p className="bold up">
                                    11. ALTERSVERIFIKATION
                                </p>
                                <p className="boldmesh">
                                    11.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Wir sind gemäß den Vorschriften des Jugendschutzgesetzes (JuSchG) und des
                                    Jugendmedienstaatsvertrages (JMStV) verpflichtet, unsere Angebote ausschließlich
                                    volljährigen Nutzern im Rahmen einer geschlossenen Benutzergruppe zur Verfügung zu
                                    stellen. Für den Zugriff auf diese geschlossene Benutzergruppe müssen Sie ein
                                    mehrstufiges Verfahren zur Altersverifizierung durchlaufen. Zur Durchführung der
                                    Altersverifikation sind verschiede Verfahren unterschiedlicher Dienstleister
                                    möglich. Je nachdem, für welches Altersverifikationsverfahren Sie sich entscheiden,
                                    können folgende Kategorien personenbezogener Daten verarbeitet werden: Vor- und
                                    Nachname; Geburtsdatum, Adressdaten; Bildnis auf dem Personalausweis bzw. Reisepass,
                                    IBAN und E-Mail-Adresse. Die eingesetzten Dienstleister fungieren im Zusammenhang
                                    mit der Altersverifikation als weisungsgebundene Auftragsverarbeiter, mit denen wir
                                    entsprechende Verträge abgeschlossen haben. Nachfolgend erklären wir Ihnen die
                                    möglichen Verfahren der unterschiedlichen Dienstleister:
                                    <br/><br/>
                                        CheckTech Service GmbH<br/>
                                        Die Altersverifikation erfolgt in Echtzeit online per Webcam bzw. Handycam und
                                        wird durchgeführt durch einen Mitarbeiter der CheckTech Service GmbH,
                                        Deelbögenkamp 4, 22297 Hamburg. Dabei werden Sie aufgefordert, einen gültigen
                                        amtlichen Personalausweis bzw. Reisepass in die Kamera zu halten. Dabei werden
                                        von dem Dokument Bildaufnahmen angefertigt (Screenshot). Auf dem Personalausweis
                                        für die Altersverifikation nicht erforderlichen Daten werden durch Schwärzung
                                        vor der Speicherung und Übermittlung an uns anonymisiert. Auf dem Screenshot
                                        sind dann lediglich folgende personenbezogene Daten verarbeitet: Gesichtsbild
                                        auf dem Personalausweis, Vor- und Nachname sowie Geburtsdatum. Weitergehende
                                        Informationen zum Dienstleister und dessen Datenschutzbestimmungen finden Sie
                                        unter: <a href="https://www.checktechservice.com/datenschutz.html"
                                                  target="_blank">https://www.checktechservice.com/datenschutz.html</a>.
                                        <br/><br/>
                                            AltdatenCheck<br/>
                                            Die Altersverifikation kann durch die Eingabe von Zugangsdaten aus einer
                                            bereits altersverifizierten Mitgliedschaft durchgeführt werden.
                                            <br/><br/>
                                                Postident-Verfahren durch Postfiliale<br/>
                                                Das Postident-Verfahren wird durchgeführt durch die Deutsche Post AG,
                                                Charles-de-Gaulle-Straße 20, 53113 Bonn. Sie suchen eine Filiale der
                                                Deutschen Post auf, in der Sie dem zuständigen Mitarbeiter einen
                                                Postident-Coupon vorlegen, den Sie zuvor von uns erhalten haben.
                                                Anschließend wird ein Lichtbildabgleich Ihres Ausweisdokuments
                                                vorgenommen. Nachdem Ihr Alter verifiziert wurde, erhalten wir eine
                                                entsprechende Benachrichtigung durch die Post und der geschützte Bereich
                                                auf Fundorado.com wird für Sie freigeschaltet. Weitere Informationen zum
                                                Datenschutz der Deutschen Post finden Sie unter:
                                                <a href="https://www.deutschepost.de/de/f/footer/datenschutz.html"
                                                   target="_blank">https://www.deutschepost.de/de/f/footer/datenschutz.html</a>
                                                <br/><br/>
                                                    FreischaltPIN<br/>
                                                    Dieses Verfahren steht ausschließlich solchen Kunden zur Verfügung,
                                                    die während des Anmeldeprozesses Adressdaten angegeben haben. Bei
                                                    Kunden aus Deutschland erfolgt dann zunächst eine Identifizierung
                                                    der Adressdaten über die Schufa Holding AG, Kormoranweg 5, 65201
                                                    Wiesbaden. Ihnen wird nach der Identifizierung Ihres Alters eine
                                                    Freischalt-PIN mitgeteilt. Die Verifikation des Alters wird geknüpft
                                                    an den Übermittlungsvorgang der vorbenannten PIN. Dies kann auf
                                                    verschiedene Weise erfolgen. Erstens: Die PIN wird (für Deutsche
                                                    Kunden) an die von Ihnen übermittelte Adresse postalisch per
                                                    "Einschreiben eigenhändig" übermittelt, sodass der Mitarbeiter der
                                                    Post die Altersverifikation durch einen Abgleich des angegebenen
                                                    Alters und Ihrer Person vornimmt. Für Kunden aus Österreich und der
                                                    Schweiz erfolgt eine Übermittlung per Brief. Zweitens: Die
                                                    Übermittlung der FreischaltPIN erfolgt mit der Abbuchung des
                                                    Mitgliedsbeitrages, indem die PIN im Verwendungszweck eingetragen
                                                    wird. Drittens: Sie können die PIN per Fax an ein Orion Fachgeschäft
                                                    Ihrer Wahl anfordern. In diesem Verfahren lässt sich der Mitarbeiter
                                                    des Orion Fachgeschäfts für den Abgleich des Alters einen
                                                    Personalausweis vorlegen.
                                                    <br/><br/>
                                                        SofortCheck<br/>
                                                        Die Altersverifikation über den Service "SofortCheck" wird
                                                        angeboten von dem Dienstleister Sofort GmbH, Theresienhöhe 12,
                                                        80339 München. Nach dem Login auf unserer FunDorado Webseite und
                                                        Auswahl dieser Altersverifikationsmethode werden Sie
                                                        aufgefordert Ihre IBAN einzugeben. Sie werden an den
                                                        vorbenannten Dienstleister weitergeleitet, der dann Ihre
                                                        Online-Banking Zugangsdaten (IBAN und persönliche
                                                        online-Banking-PIN) abfragt. "SofortCheck" prüft nun die bei der
                                                        Bank hinterlegten Daten. Erfolgt sodann die positive Mitteilung,
                                                        dass Sie volljährig sind, erfolgt von unserer Seite eine
                                                        Freischaltung der Inhalte und sie werden auf unsere Webseite
                                                        zurückgeleitet.
                                                        <br/><br/>
                                                            DeltaVista-Check (nur für Kunden aus der Schweiz und aus
                                                            Österreich)<br/>
                                                            Bei der Eingabe Ihres Vor- und Nachnamens, Geburtsdatum und
                                                            Adresse übernimmt die Dienstleisterin CRIF Bürgel GmbH,
                                                            Radlkoferstraße 2, 81373 München einen Identitätscheck, der
                                                            die Echtheit Ihrer Identität überprüft. Kann die Echtheit
                                                            der Daten bestätigt werden, erfolgt eine Freigabe der
                                                            Inhalte. Weitere Datenschutzinformationen des Dienstleisters
                                                            finden Sie unter <a
                                                            href="https://www.crifbuergel.de/de/datenschutz"
                                                            target="blank">https://www.crifbuergel.de/de/datenschutz</a>.
                                </p>
                                <p className="boldmesh">
                                    11.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die für die Altersverifikation erforderliche Verarbeitung Ihrer personenbezogenen
                                    Daten erfolgt zur Erfüllung der gesetzlichen Verpflichtung zur Altersverifikation
                                    für jugendgefährdende Inhalte gemäß dem JuSchG und dem JMStV.
                                </p>
                                <p className="boldmesh">
                                    11.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich,
                                    Art. 6 Abs. 1 S. 1 lit. c) DSGVO in Verbindung mit § 4 Abs. 2 S. 1 Nr. 1 und S. 2
                                    JMStV.
                                </p>
                                <p className="boldmesh">
                                    11.4 Speicherdauer
                                </p>
                                <p className="mesh">
                                    Ihre gespeicherten personenbezogenen Daten werden 50 Tage nach Ablauf der
                                    Mitgliedschaft pseudonymisiert.
                                </p>
                                <p className="boldmesh">
                                    11.5 Empfänger und Übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    Eine Übermittlung Ihrer Daten in Drittstaaten erfolgt nicht und ist auch nicht
                                    geplant.
                                    <br/><br/>
                                        Je nachdem, für welchen Dienstleister Sie sich für die Durchführung der
                                        Altersverifikation entscheiden, können folgende Empfänger Ihre
                                        verfahrensabhängigen personenbezogenen Daten empfangen:
                                        <br/><br/>
                                            CRIF Bürgel GmbH, Leopoldstraße 244, 80807 München
                                            <br/><br/>
                                                CheckTech Service GmbH, Deelbögenkamp 4, 22297 Hamburg
                                                <br/><br/>
                                                    Deutsche Post AG, Charles-de-Gaulle-Straße 20, 53113 Bonn
                                                    <br/><br/>
                                                        SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden
                                                        <br/><br/>
                                                            Sofort GmbH, Theresienhöhe 12, 80339 München
                                </p>
                                <p className="bold up">
                                    12. GOOGLE WEBFONTS
                                </p>
                                <p className="boldmesh">
                                    12.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Unsere Webseite verwendet "Google Webfonts", einen Schriftersetzungsdienst des
                                    Unternehmens Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
                                    (nachfolgend "Google" genannt). Mit Google Webfonts werden bei der Darstellung
                                    unserer Webseite die Standardschriften Ihres Endgerätes durch Schriftarten aus dem
                                    Katalog von Google ausgetauscht. Falls Ihr Browser die Einbindung von Google
                                    Webfonts unterbindet, wird der Text unserer Webseite in den Standardschriften Ihres
                                    Endgerätes angezeigt. Die Google-Fonts werden direkt von einem Google-Server
                                    geladen. Damit dies geschehen kann, sendet Ihr Browser eine Anfrage an einen
                                    Google-Server. Dadurch wird ggf. auch Ihre IP-Adresse in Verbindung mit der Adresse
                                    unserer Webseite an Google übermittelt. Google Webfonts hinterlegt jedoch keine
                                    Cookies auf Ihrem Endgerät. Laut Google werden Daten, die im Rahmen des Dienstes
                                    Google Webfonts verarbeitet werden, auf ressourcenspezifischen Domains wie
                                    fonts.googleapis.com oder fonts.gstatic.com übertragen. Sie werden nicht mit Daten
                                    in Verbindung gebracht, die ggf. im Zusammenhang mit der Nutzung anderer
                                    Google-Dienste wie z.B. der gleichnamigen Suchmaschine oder Gmail stehen. Weitere
                                    Informationen zum Datenschutz bei Google Webfonts finden sie unter <a
                                    href="https://developers.google.com/fonts/faq?hl=de-DE&amp;csw=1">https://developers.google.com/fonts/faq?hl=de-DE&amp;csw=1</a>.
                                    Allgemeine Informationen zum Datenschutz bei Google sind unter <a
                                    href="http://www.google.com/intl/de-DE/policies/privacy/">http://www.google.com/intl/de-DE/policies/privacy/</a> hinterlegt.
                                </p>
                                <p className="boldmesh">
                                    12.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt, um Ihnen den Text unserer Webseite besser lesbar und
                                    ästhetisch ansprechender anzeigen zu können.
                                </p>
                                <p className="boldmesh">
                                    12.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 12.2 benannten Zweck.
                                </p>
                                <p className="boldmesh">
                                    12.4 Empfänger und Übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    Durch die Verwendung von Google Webfonts werden ggf. personenbezogenen Daten an
                                    Google übermittelt. Google verarbeitet Ihre personenbezogenen Daten auch in den USA.
                                </p>

                                <p className="bold up">
                                    13. GOOGLE ANALYTICS
                                </p>
                                <p className="boldmesh">
                                    13.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Unsere Webseite verwendet „Google Analytics“, einen Webanalysedienst der Google
                                    Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (nachfolgend „Google“
                                    genannt). Google Analytics setzt Cookies ein (siehe Ziffer 10), die eine Analyse
                                    Ihrer Nutzung unseres Angebotes ermöglichen. Wir verwenden Google Analytics in der
                                    angebotenen Version "Universal Analytics", die diese Analyse geräteübergreifend
                                    durch Zuordnung der Daten zu einer pseudonymen User-ID erlaubt. Die durch den Cookie
                                    erzeugten Informationen werden in der Regel an einen Server von Google in den USA
                                    übertragen und dort gespeichert. Wir verwenden Google Analytics jedoch
                                    ausschließlich mit einer IP-Anonymisierung. Dadurch wird Ihre IP-Adresse von Google
                                    innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
                                    des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
                                    Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA
                                    übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser
                                    übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die
                                    von Google Analytics erstellten Statistiken erfassen insbesondere wie viele Nutzer
                                    unsere Website besuchen, aus welchem Land bzw. Ort der Zugriff erfolgt, welche
                                    Unterseiten aufgerufen werden und über welche Links oder Suchbegriffe Besucher auf
                                    unsere Webseite gelangen. Die Nutzerbedingungen von Google Analytics finden Sie
                                    unter <a href="https://marketingplatform.google.com/about/analytics/terms/de/"
                                             target="_blank"
                                             style={{wordBreak: "break-all"}}>https://marketingplatform.google.com/about/analytics/terms/de/</a>.
                                    Eine Übersicht zum Datenschutz bei Google Analytics ist unter <a
                                    href="http://www.google.com/intl/de/analytics/learn/privacy.html" target="_blank"
                                    style={{wordBreak: "break-all"}}>http://www.google.com/intl/de/analytics/learn/privacy.html</a> abrufbar.
                                    Die Datenschutzerklärung von Google lässt sich unter <a
                                    href="https://policies.google.com/privacy?hl=de-DE" target="_blank"
                                    style={{wordBreak: "break-all"}}>https://policies.google.com/privacy?hl=de-DE</a> einsehen.
                                </p>
                                <p className="boldmesh">
                                    13.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt, um die Nutzung unserer Webseite auswerten zu können. Die
                                    dadurch gewonnenen Informationen dienen der Verbesserung und bedarfsgerechten
                                    Gestaltung unseres Online-Auftritts.
                                </p>
                                <p className="boldmesh">
                                    13.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt auf Grundlage einer Einwilligung gemäß Art. 6 Abs. 1 lit. a
                                    DSGVO. Diese wird von uns über das Consent-Tool (siehe Ziffer 10) eingeholt. Eine
                                    solche Einwilligung ist freiwillig.
                                </p>
                                <p className="boldmesh">
                                    13.4 Speicherdauer und Widerspruchsrecht, Widerruf der Einwilligung
                                </p>
                                <p className="mesh">
                                    Die Speicherdauer, sowie Ihre Kontroll- und Einstellungsmöglichkeiten bei Cookies
                                    haben wir in Ziffer 10 erläutert. Die von Ihnen erteilte Einwilligung im Hinblick
                                    auf Google Analytics können Sie jederzeit in den Einstellungen des Consent-Tools mit
                                    Wirkung für die Zukunft widerrufen. Alternativ können Sie der Datenverarbeitung
                                    durch Google Analytics jederzeit widersprechen, indem Sie das von Google unter <a
                                    href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                                    style={{wordBreak: "break-all"}}>https://tools.google.com/dlpage/gaoptout?hl=de</a> angebotene
                                    Browser-Add-on herunterladen und installieren. Die mit Google Analytics
                                    verarbeiteten und gespeicherten Analysedaten werden von uns nach 14 Monaten
                                    automatisch gelöscht.
                                </p>
                                <p className="boldmesh">
                                    13.5 Empfänger und Übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    Google Analytics ist für uns laut der deutschen Datenschutzaufsichtsbehörden
                                    (Datenschutzkonferenz) in gemeinsamer Verantwortlichkeit an der Datenverarbeitung
                                    tätig. Vor diesem Hintergrund haben wir auch die „Google Measurement
                                    Controller-Controller Data Protection Terms“ mit Google abgeschlossen. Google
                                    verarbeitet Ihre personenbezogenen Daten auch in den USA.
                                </p>

                                <p className="bold up">
                                    14. GOOGLE TAG MANAGER
                                </p>
                                <p className="mesh">
                                    Unsere Webseite verwendet den "Google Tag Manager", einen Dienst des Unternehmens
                                    Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (nachfolgend
                                    "Google" genannt). über den Google Tag Manager werden keine personenbezogenen Daten
                                    erfasst und keine Cookies gesetzt. Dieser Dienst ermöglicht uns lediglich die
                                    Einbindung und Verwaltung von Tags auf unserer Webseite. Tags sind kleine
                                    Codeelemente auf unserer Website, die hilfreich sind, um darauf aufbauend mit
                                    anderen Tools Traffic und Besucherverhalten zu messen, die Auswirkung von
                                    Online-Werbung und sozialen Kanälen zu erfassen, Remarketing und die Ausrichtung auf
                                    Zielgruppen einzusetzen, die Webseite zu testen und zu optimieren. Weitere
                                    Informationen zum Google Tag Manager finden Sie hier: <a
                                    href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank"
                                    style={{wordBreak: "break-all"}}>https://www.google.com/intl/de/tagmanager/use-policy.html</a>
                                </p>

                                <p className="bold up">
                                    15. GOOGLE ADS CONVERSION UND GOOGLE REMARKETING
                                </p>
                                <p className="boldmesh">
                                    15.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Unsere Webseite verwendet Conversion Tracking des Werbedienstes "Google Ads", der
                                    von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA betrieben
                                    wird (nachfolgend "Google" genannt). Mit Hilfe von Google Ads können wir Werbung auf
                                    externen Webseiten schalten, um Sie dort auf unsere Angebote aufmerksam zu machen.
                                    Darüber hinaus ist es uns mit dem Dienst möglich, die Reichweite und den Erfolg
                                    einzelnen Werbemaßnahmen zu ermitteln. Unsere Werbeanzeigen werden dabei von Google
                                    über sogenannte "Ad Server" ausgeliefert. Dazu verwendet Google sogenannte "Ad
                                    Server"- Cookies, durch die bestimmte Parameter zur Erfolgsmessung, wie Einblendung
                                    der Anzeigen oder Klicks durch die Nutzer, gemessen werden. Sofern Sie über eine
                                    Google-Anzeige auf unsere Webseite gelangen, wird von Google Ads ein Cookie in ihrem
                                    Endgerät gespeichert (siehe Ziffer 10). Diese Cookies sollen laut Google nicht dazu
                                    dienen, Sie persönlich zu identifizieren. Zu diesem Cookie werden in der Regel als
                                    Analyse-Werte die Unique Cookie-ID, Anzahl Ad Impressions pro Platzierung
                                    (Frequency), letzte Impression (relevant für Post-View-Conversions) sowie
                                    Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr angesprochen werden
                                    möchte) gespeichert. Die Cookies ermöglichen Google, Ihren Internetbrowser
                                    wiederzuerkennen. Sofern Sie Webseiten eines Ads-Kunden besuchen und das auf Ihrem
                                    Endgerät gespeicherte Cookie noch nicht abgelaufen ist, können Google und der Kunde
                                    erkennen, dass der Nutzer auf die Anzeige geklickt hat und auf unsere Webseite
                                    weitergeleitet wurde. Jedem Ads-Kunden wird ein anderes Cookie zugeordnet. Cookies
                                    können somit nicht über die Webseiten von Ads-Kunden nachverfolgt werden. Wir selbst
                                    verarbeiten mit unseren Google Ads Werbemaßnahmen keine personenbezogenen Daten.
                                    Google stellt uns lediglich statistische Auswertungen zur Verfügung. Anhand dieser
                                    Auswertungen können wir erkennen, welche der eingesetzten Werbemaßnahmen besonders
                                    effektiv sind. Weitergehende Daten aus dem Einsatz der Werbemittel erhalten wir
                                    nicht, insbesondere können wir die Nutzer nicht anhand dieser Informationen
                                    identifizieren. Wenn Sie unsere Webseite besuchen wird daher eine Verbindung zu den
                                    Google-Servern hergestellt. Wir haben keinen Einfluss auf den Umfang und die weitere
                                    Verwendung der Daten, die durch den Einsatz von Google Ads Conversion durch Google
                                    erhoben werden und informieren Sie daher entsprechend unserem Kenntnisstand: Durch
                                    die Einbindung von Google Ads Conversion erhält Google die Information, welche
                                    Unterseite unserer Webseite Sie aufgerufen oder eine Anzeige von uns angeklickt
                                    haben. Sofern Sie bei einem Dienst von Google registriert sind, kann Google den
                                    Besuch Ihrem Account zuordnen. Selbst wenn Sie nicht bei Google registriert sind
                                    bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass Google Ihre
                                    IP-Adresse in Erfahrung bringt und speichert.
                                    <br/><br/>
                                        Unsere Webseite verwendet darüber hinaus den Werbedienst "Google Remarketing",
                                        der ebenfalls von Google betrieben wird. Mit Google Remarketing können wir Sie
                                        nach dem Besuch unserer Webseite auf anderen Webseiten, die sich dem
                                        Google-Werbenetzwerk angeschlossen haben, mit Werbeanzeigen für unsere Angebote
                                        erneut ansprechen. Auch dafür verwendet Google Cookies, die in Ihrem Browser
                                        gespeichert werden und über die Ihr Nutzungsverhalten bei Besuch verschiedener
                                        Webseiten durch Google erfasst und ausgewertet wird. So kann Google Ihren
                                        vorherigen Besuch unserer Website feststellen und Ihnen Werbung für unsere
                                        Angebote auf anderen Webseiten anzeigen. Eine Zusammenführung der im Rahmen des
                                        Remarketings erhobenen Daten mit Ihren personenbezogenen Daten, die ggf. von
                                        Google gespeichert werden, findet durch Google laut eigenen Aussagen nicht
                                        statt. Insbesondere wird laut Google beim Remarketing eine Pseudonymisierung
                                        eingesetzt.
                                        <br/><br/>
                                            Weitere Informationen zum Datenschutz bei Google finden Sie hier: <a
                                            href="http://www.google.com/intl/de/policies/privacy" target="_blank"
                                            style={{wordBreak: "break-all"}}>http://www.google.com/intl/de/policies/privacy</a> und <a
                                            href="https://services.google.com/sitestats/de.html" target="_blank"
                                            style={{wordBreak: "break-all"}}>https://services.google.com/sitestats/de.html</a>.
                                </p>
                                <p className="boldmesh">
                                    15.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt, um zielgenaue Online-Werbung für unsere eigenen Angebote
                                    durchführen und deren Wirksamkeit und Reichweite auswerten zu können.
                                </p>
                                <p className="boldmesh">
                                    15.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 15.2 benannten Zweck. Sofern Sie von uns im Rahmen
                                    eines Cookie-Banners bzw. Cookie-Consent-Tools um eine Einwilligung gebeten werden,
                                    besteht die Rechtsgrundlage in Art. 6 Abs. 1 lit. a DSGVO. Eine solche Einwilligung
                                    ist freiwillig.
                                </p>
                                <p className="boldmesh">
                                    15.4 Speicherdauer und Widerspruchsrecht, Widerruf der Einwilligung
                                </p>
                                <p className="mesh">
                                    Die Speicherdauer, sowie Ihre Kontroll- und Einstellungsmöglichkeiten bei Cookies
                                    haben wir in Ziffer 10 erläutert. Sie können der Datenverarbeitung durch Google Ads
                                    Conversion und Google Remarketing im Übrigen jederzeit über die folgende Webseite
                                    widersprechen: <a href="http://www.google.com/ads/preferences" target="_blank">http://www.google.com/ads/preferences</a>.
                                    Sofern wir Einwilligungen zur Verwendung von Google Adwords Conversion und Google
                                    Remarketing über ein Cookie-Banner oder ein Cookie-Consent-Tool einholen, können
                                    diese Einwilligungen von Ihnen jederzeit innerhalb der Einstellungen des
                                    Cookie-Banners bzw. des Cookie-Consent-Tools mit Wirkung für die Zukunft widerrufen
                                    werden.
                                </p>
                                <p className="boldmesh">
                                    15.5 Empfänger und Übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    Durch die Einbindung von Google Ads Conversion und Google Remarketing werden ggf.
                                    personenbezogenen Daten an Google übermittelt. Google verarbeitet Ihre
                                    personenbezogenen Daten auch in den USA. Sofern Standardvertragsklauseln vorliegen,
                                    gelten diese als Grundlage für die Übermittlung personenbezogener Daten aus der
                                    Europäischen Union in die USA.
                                </p>

                                <p className="bold up">
                                    16. AFFILIATE MARKETING
                                </p>
                                <p className="boldmesh">
                                    16.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Wir arbeiten mit sogenannten Affiliate-Netzwerken zusammen, über welche externe
                                    Webseitenbetreiber eine Provision erhalten, wenn ein von ihnen geworbener Kunde auf
                                    unseren Seiten bestimmte Aktionen durchführt (z.B. eine Anmeldung oder Käufe).
                                    Derzeit setzen wir dazu ein: Adcell, Superclix, Vitrado und CashDorado. Die Partner
                                    binden sogenannte Affiliate-Links oder andere Verweise wie eine Werbeanzeige ein.
                                    Wenn Nutzer diesen Links folgen bzw. anschließend unsere Angebote in Anspruch
                                    nehmen, erhalten die Drittanbieter eine Provision von uns. Um nachverfolgen zu
                                    können, ob die Nutzer die Angebote eines von uns eingesetzten Affiliate-Links
                                    wahrgenommen haben, ist es notwendig, dass die jeweiligen Drittanbieter erfahren,
                                    dass die Nutzer einem innerhalb unseres Onlineangebotes eingesetzten Affiliate-Link
                                    gefolgt sind. Vorstehende Anbieter setzen hierfür Cookies ein, über die wir Sie in
                                    Ziffer 10 informieren. Die Cookies dienen der Zuordnung zu dem jeweiligen
                                    Drittanbieter.
                                </p>
                                <p className="boldmesh">
                                    16.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Zuordnung der Affiliate-Links zu den jeweiligen Geschäftsabschlüssen oder zu
                                    sonstigen Aktionen (z.B. Käufen) dient alleine dem Zweck der Provisionsabrechnung
                                    und wird automatisch nach spätestens 60 Tagen aufgehoben. Für die Zwecke der
                                    vorgenannten Zuordnung der Affiliate-Links können die Affiliate-Links um bestimmte
                                    Werte ergänzt werden, die ein Bestandteil des Links sind oder anderweitig, z.B. in
                                    einem Cookie, gespeichert werden können. Zu den Werten können insbesondere die
                                    Ausgangswebseite (Referrer), der Zeitpunkt, eine Online-Kennung der Betreiber der
                                    Webseite, auf der sich der Affiliate-Link befand, eine Online-Kennung des jeweiligen
                                    Angebotes, die Art des verwendeten Links, die Art des Angebotes und eine
                                    Online-Kennung des Nutzers gehören. Die Verarbeitung erfolgt zudem, um zielgenaue
                                    Online-Werbung für unsere eigenen Angebote durchführen und deren Wirksamkeit und
                                    Reichweite auswerten zu können.
                                </p>
                                <p className="boldmesh">
                                    16.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 16.2 benannten Zweck. Die Verarbeitung erfolgt auf
                                    Grundlage unseres überwiegenden berechtigten wirtschaftlichen Interesses gemäß Art.
                                    6 Abs. 1 S.1 lit. f) DSGVO. Unser berechtigtes Interesse folgt aus dem in Ziffer
                                    16.2 beschriebenen Zweck. Anders ist uns eine Abrechnung von Provisionszahlungen
                                    nicht möglich. Es liegt zudem innerhalb der vernünftigen Erwartungshaltung von
                                    Nutzern, dass werbefinanzierte Angebote abgerechnet werden müssen und hierfür auf
                                    Webseiten technische Vorkehrungen, wie etwa das Setzen eines Cookies, erforderlich
                                    sind. Schließlich bleiben Ihnen Interventionsmöglichkeiten, wie bereits unter Ziffer
                                    10 erläutert. Sofern wir die Nutzer vor dem Setzen von Cookies um deren Einwilligung
                                    in den Einsatz bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die
                                    Einwilligung.
                                </p>
                                <p className="boldmesh">
                                    16.4 Speicherdauer und Widerspruchsrecht
                                </p>
                                <p className="mesh">
                                    Die Speicherdauer, sowie Ihre Kontroll- und Einstellungsmöglichkeiten bei Cookies
                                    haben wir in Ziffer 10 erläutert.
                                </p>
                                <p className="boldmesh">
                                    16.5 Empfänger und übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    Durch die Einbindung von Adcell, Superclix, Vitrado und CashDorado werden ggf.
                                    personenbezogene Daten auch an Server in den USA. Sofern Standardvertragsklauseln
                                    vorliegen, gelten diese als Grundlage für die Übermittlung personenbezogener Daten
                                    aus der Europäischen Union in die USA.
                                </p>

                                <p className="bold up">
                                    17. LIVE-CHAT VIA LIVEAGENT
                                </p>
                                <p className="boldmesh">
                                    17.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Zur schnelleren Kontaktaufnahme und Bearbeitung Ihres Anliegens haben wir auf
                                    unserer Webseite eine Live-Chat-Funktion mit unserem Kundensupport integriert. Wir
                                    verwenden dafür den Kommunikationsdienst "LiveAgent", der von der Quality Unit, s.
                                    r. o.Vajnorska 100/A83104 Bratislava, Slovakia angeboten wird. LiveAgent bietet uns
                                    die Möglichkeit mit Nutzern per Live-Chat zu kommunizieren. Dabei werden die über
                                    den Chat versendeten Nachrichten (ggf. Ihr Name, E-Mail-Adresse, Telefonnummer)
                                    durch uns auf den Servern von LiveAgent verarbeitet. Weitere Informationen zum
                                    Datenschutz bei LiveAgent finden Sie unter <a
                                    href="https://www.liveagent.com/de/sicherheits-und-datenschutzrichtlinie/"
                                    target="_blank">https://www.liveagent.com/de/sicherheits-und-datenschutzrichtlinie/</a>
                                </p>
                                <p className="boldmesh">
                                    17.2 Zweck
                                </p>
                                <p className="mesh">
                                    Zur schnelleren Kontaktaufnahme und Bearbeitung Ihres Anliegens haben wir auf
                                    unserer Webseite eine Live-Chat-Funktion mit unserem Kundensupport integriert. Wir
                                    verwenden dafür den Kommunikationsdienst "LiveAgent", der von der Quality Unit, s.
                                    r. o. Vajnorska 100/A83104 Bratislava, Slovakia angeboten wird. LiveAgent bietet uns
                                    die Möglichkeit mit Nutzern per Live-Chat zu kommunizieren. Dabei werden die über
                                    den Chat versendeten Nachrichten (ggf. Ihr Name, E-Mail-Adresse, Telefonnummer)
                                    durch uns auf den Servern von LiveAgent verarbeitet. Weitere Informationen zum
                                    Datenschutz bei LiveAgent finden Sie unter <a
                                    href="https://www.liveagent.com/de/sicherheits-und-datenschutzrichtlinie/"
                                    target="_blank">https://www.liveagent.com/de/sicherheits-und-datenschutzrichtlinie/</a>
                                    <br/><br/>
                                        Mit der Bereitstellung des Live-Chats wollen wir Ihnen eine bequeme und schnelle
                                        Möglichkeit anbieten, sich mit uns in Verbindung zu setzen. Die mit und in dem
                                        Live-Chat übermittelten Daten werden ausschließlich zum Zwecke der Bearbeitung
                                        und Beantwortung Ihres Anliegens verwendet.
                                </p>
                                <p className="boldmesh">
                                    17.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 17.2 benannten Zweck. Sofern der Live-Chat auf den
                                    Abschluss oder der Erfüllung eines Vertrages abzielt, so erfolgt die
                                    Datenverarbeitung zur Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO).
                                </p>
                                <p className="boldmesh">
                                    17.4 Speicherdauer
                                </p>
                                <p className="mesh">
                                    Die Daten werden von uns gelöscht, sobald sie für die Erreichung des Zweckes ihrer
                                    Erhebung nicht mehr erforderlich sind. Dies ist für gewöhnlich der Fall, wenn die
                                    jeweilige Kommunikation mit Ihnen beendet ist. Beendet ist die Kommunikation dann,
                                    wenn sich aus den Umständen entnehmen lässt, dass Ihr Anliegen abschließend geklärt
                                    ist. Sofern gesetzliche Aufbewahrungsfristen einer Löschung entgegenstehen, erfolgt
                                    eine Löschung unverzüglich nach Ablauf der gesetzlichen Aufbewahrungspflicht von 10
                                    Jahren.
                                </p>
                                <p className="boldmesh">
                                    17.5 Empfänger und Übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    LiveAgent ist für uns im Rahmen einer Auftragsverarbeitung als Dienstleister tätig.
                                    LiveAgent verarbeitet Ihre personenbezogenen Daten auch in den USA. Sofern
                                    Standardvertragsklauseln vorliegen, gelten diese als Grundlage für die Übermittlung
                                    personenbezogener Daten aus der Europäischen Union in die USA.
                                </p>

                                <p className="bold up">
                                    18. VIDEO JS PLAYER UND WOWZA
                                </p>
                                <p className="boldmesh">
                                    18.1 Beschreibung der Verarbeitung
                                </p>
                                <p className="mesh">
                                    Unsere Webseite verwendet den Dienst "VideoJS Player" einen HTML5-Videoplayer
                                    (nachfolgend "VideoJS Player" genannt) sowie die Streaming Software Wowza.
                                    <br/>
                                        Wir nutzen die Player, indem wir Videos im Mitgliederbereich von Fundorado
                                        mittels eines der Player einbinden und ausgeben, so dass diese auf unserer
                                        Webseite abspielbar sind. Die Videodateien liegen dabei auf unserem Server,
                                        werden jedoch über ein Player-Plugin innerhalb unserer Webseite dargestellt. Bei
                                        jedem Aufruf einer Unterseite unseres Mitgliederbereichs, die ein oder mehrere
                                        Videos anbietet, wird eine direkte Verbindung zwischen Ihrem Browser und einem
                                        Server der Anbieter in den USA hergestellt, um das Player-Plugin zu laden, das
                                        seinerseits dann das richtige Video von unserem Server auf unserer Webseite
                                        ausgibt. Dabei werden Informationen über Ihren Besuch, wie z.B. Ihre IP-Adresse,
                                        Ihr Browser, das von Ihnen verwendete Endgerät, Ihr Betriebssystem, die Zeit und
                                        Dauer der Videobetrachtung und ob Sie sich das Video im Vollbild angesehen
                                        haben, an den Dienstleister übermittelt. Durch Interaktionen mit den Plugins
                                        bspw. das Klicken des Start-Buttons werden diese Informationen ebenfalls an die
                                        Dienstleister weitergegeben und dort gespeichert. VideoJS Player setzt darüber
                                        hinaus Cookies für Statistikzwecke ein (siehe Ziffer 10). Weitere Informationen
                                        zum Datenschutz bei VideoJS Player finden sie unter <a
                                        href="https://www.brightcove.com/en/legal/privacy" target="_blank"
                                        style={{wordBreak: "break-all"}}>https://www.brightcove.com/en/legal/privacy</a>.
                                        Weitere Informationen zum Datenschutz bei Wowza finden Sie unter <a
                                        href="https://www.wowza.com/legal/privacy" target="_blank"
                                        style={{wordBreak: "break-all"}}>https://www.wowza.com/legal/privacy</a>.
                                </p>
                                <p className="boldmesh">
                                    18.2 Zweck
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung erfolgt, um Ihnen Videos auf unserer Webseite anzeigen, die
                                    Bildqualität und Performance der Videos verbessern, zu können.
                                </p>
                                <p className="boldmesh">
                                    18.3 Rechtsgrundlage
                                </p>
                                <p className="mesh">
                                    Die Verarbeitung ist zur Wahrung der überwiegenden berechtigten Interessen des
                                    Verantwortlichen erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Unser berechtigtes
                                    Interesse liegt in dem, in Ziffer 18.2 benannten Zweck.
                                </p>
                                <p className="boldmesh">
                                    18.4 Empfänger und Übermittlung in Drittstaaten
                                </p>
                                <p className="mesh">
                                    VideoJS Player wird angeboten von der Brightcove, Inc. 290 Congress Street, Boston,
                                    Massachusetts 02210 USA.
                                    <br/>
                                        Wowza wird angeboten von der Wowza Media Systems LLC, 523 Park Point Drive,
                                        Suite 300 Golden, CO, 80401, USA.
                                        <br/>
                                            Beide Dienstleister sind für uns im Rahmen einer Auftragsverarbeitung tätig.
                                            Durch die Einbindung der VideoJS Player werden ggf. personenbezogenen Daten
                                            wie in Ziffer 18.1 beschrieben an die vorbenannten Dienstleister
                                            übermittelt. Brightcove Inc. und Wowza Media Systems LLC verarbeiten Ihre
                                            personenbezogenen Daten auch in den USA. Sofern Standardvertragsklauseln
                                            vorliegen, gelten diese als Grundlage für die Übermittlung personenbezogener
                                            Daten aus der Europäischen Union in die USA.
                                </p>

                                <h3 className="up">SICHERHEITSMASSNAHMEN</h3>
                                <p className="bold up">
                                    19. SICHERHEITSMAßNAHMEN
                                </p>
                                <p className="mesh">
                                    Um Ihre persönlichen Daten vor fremdem Zugriff zu schützen, haben wir unsere
                                    Webseite mit einem SSL- bzw. TLS-Zertifikat versehen. SSL steht für
                                    "Secure-Sockets-Layer" und TLS für "Transport Layer Security" und verschlüsselt die
                                    Kommunikation von Daten zwischen einer Webseite und dem Endgerät des Nutzers. Sie
                                    erkennen die aktive SSL- bzw. TLS-Verschlüsselung an einem kleinen Schloss-Logo, das
                                    ganz links in der Adresszeile des Browsers angezeigt wird.
                                </p>

                                <h3 className="up">IHRE RECHTE</h3>
                                <p className="bold up">
                                    20. BETROFFENENRECHTE
                                </p>
                                <p className="mesh">
                                    Im Hinblick auf die oben beschriebene Datenverarbeitung durch unser Unternehmen
                                    stehen Ihnen die folgenden Betroffenenrechte zu:
                                </p>
                                <p className="boldmesh">
                                    20.1 Auskunft (Art. 15 DSGVO)
                                </p>
                                <p className="mesh">
                                    Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Sie
                                    betreffende personenbezogene Daten verarbeiten. Ist dies der Fall, steht Ihnen unter
                                    den in Art. 15 DSGVO genannten Voraussetzungen ein Recht auf Auskunft über diese
                                    personenbezogenen Daten und auf die in Art. 15 DSGVO aufgeführten weiteren
                                    Informationen zu.
                                </p>
                                <p className="boldmesh">
                                    20.2 Berichtigung (Art. 16 DSGVO)
                                </p>
                                <p className="mesh">
                                    Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender
                                    unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger
                                    personenbezogener Daten zu verlangen.
                                </p>
                                <p className="boldmesh">
                                    20.3 Löschung (Art. 17 DSGVO)
                                </p>
                                <p className="mesh">
                                    Sie haben das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene
                                    Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen
                                    aufgeführten Gründe zutrifft, z. B. wenn Ihre Daten für die von uns verfolgten
                                    Zwecke nicht mehr benötigt werden.
                                </p>
                                <p className="boldmesh">
                                    20.4 Einschränkung der Datenverarbeitung (Art. 18 DSGVO)
                                </p>
                                <p className="mesh">
                                    Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn
                                    eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie
                                    die Richtigkeit Ihrer personenbezogenen Daten bestreiten, wird die Datenverarbeitung
                                    für die Dauer eingeschränkt, die uns die überprüfung der Richtigkeit Ihrer Daten
                                    ermöglicht.
                                </p>
                                <p className="boldmesh">
                                    20.5 Datenübertragbarkeit (Art. 20 DSGVO)
                                </p>
                                <p className="mesh">
                                    Sie haben das Recht, unter den in Art. 20 DSGVO aufgeführten Voraussetzungen, die
                                    Herausgabe der Sie betreffenden Daten in einem strukturierten, gängigen und
                                    maschinenlesbaren Format zu verlangen.
                                </p>
                                <p className="boldmesh">
                                    20.6 Widerruf von Einwilligungen (Art. 7 Abs. 3 DSGVO)
                                </p>
                                <p className="mesh">
                                    Sie haben das Recht, bei einer Verarbeitung, die auf einer Einwilligung beruht, Ihre
                                    Einwilligung jederzeit zu widerrufen. Der Widerruf gilt ab dem Zeitpunkt seiner
                                    Geltendmachung. Er wirkt mit anderen Worten für die Zukunft. Die Verarbeitung wird
                                    durch den Widerruf der Einwilligung also nicht rückwirkend rechtswidrig.
                                </p>
                                <p className="boldmesh">
                                    20.7 Beschwerde (Art. 77 DSGVO)
                                </p>
                                <p className="mesh">
                                    Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
                                    personenbezogenen Daten gegen die DSGVO verstößt, haben Sie das Recht auf Beschwerde
                                    bei einer Aufsichtsbehörde. Sie können dieses Recht bei einer Aufsichtsbehörde in
                                    dem EU-Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                                    mutmaßlichen Verstoßes geltend machen.
                                </p>
                                <p className="boldmesh">
                                    20.8 Verbot automatisierter Entscheidungen / Profiling (Art. 22 DSGVO)
                                </p>
                                <p className="mesh">
                                    Entscheidungen, die für Sie rechtliche Folge nach sich ziehen oder Sie erheblich
                                    beeinträchtigen, dürfen nicht ausschließlich auf eine automatisierte Verarbeitung
                                    personenbezogener Daten - einschließlich eines Profiling - gestützt werden. Wir
                                    teilen Ihnen mit, dass wir im Hinblick auf Ihre personenbezogenen Daten keine
                                    automatisierte Entscheidungsfindung einschließlich Profiling einsetzen.
                                </p>
                                <div className="box_border">
                                    <p className="boldmesh" style={{margin: 0, padding: 0}}>
                                        20.9 Widerspruch (Art. 21 DSGVO)
                                    </p>
                                    <p>
                                        Wenn wir personenbezogene Daten von Ihnen auf Grundlage von Art. 6 Abs. 1 lit. f
                                        DSGVO (zur Wahrung überwiegender berechtigten Interessen) verarbeiten, haben Sie
                                        das Recht, unter den in Art. 21 DSGVO aufgeführten Voraussetzungen dagegen
                                        Widerspruch einzulegen. Dies gilt jedoch nur, soweit Gründe vorliegen, die sich
                                        aus Ihrer besonderen Situation ergeben. Nach einem Widerspruch verarbeiten wir
                                        Ihre personenbezogenen Daten nicht mehr, es sei denn wir können zwingende
                                        schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                                        Rechte und Freiheiten überwiegen. Wir müssen die Verarbeitung ebenfalls nicht
                                        einstellen, wenn sie der Geltendmachung, Ausübung oder Verteidigung von
                                        Rechtsansprüchen dient. In jedem Fall - auch unabhängig von einer besonderen
                                        Situation - haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
                                        Ihrer personenbezogenen Daten für Direktwerbung einzulegen.
                                    </p>
                                </div>
                                <br/>
                                    <p className="mesh">
                                        Stand: August 2021
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </Fragment>
        </FundoLayout>
    )
}
export default InfoPage;
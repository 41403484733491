import { createSlice } from "@reduxjs/toolkit";
const initialUserState = {
    isLoggedIn: false,
    id: 0,
    firstname: 'Fundo',
    lastname: 'Guest',
    username: 'guest',
    email: '',
    country: 'DE',
    token: null,
    loggedIn: false,
    fsk: false,
    currency: 'EUR',
    hasActiveSubscription: false,
    wm: null,
    wbm: null,
    kamp: null,
    needsAgeVerification: true,
    ageVerified: false

};

const userSlice  = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        openLogin(state) {
            state.showLogin = true;
        },
        closeLogin(state) {
            state.showLogin = false;
        },
        setCountryFromIp(state, action) {
            state.country = action.payload;
        },
        setCurrencyFromIp(state, action) {
            state.currency = action.payload.toLowerCase();
        },
        setUser(state, action) {
            state.firstname = action.payload.authData.firstname;
            state.lastname = action.payload.authData.lastname;
            state.email = action.payload.authData.email;
            state.username = action.payload.authData.username;
            state.country = action.payload.authData.country;
            state.ageVerified = !!action.payload.authData.age_verified_at;
            state.needsAgeVerification = (action.payload.authData.country === 'DE' && !action.payload.authData.age_verified_at);
            state.hasActiveSubscription = action.payload.authData.has_active_subscription;
            state.fsk = false;
        },
        clearUser(state, action) {
            state = initialUserState;
        },

        loginWithToken(state, action) {
            state.showLogin = false;
            state.isLoggedIn = action.payload.userIsLoggedIn;
        },
        loginUser(state, action) {
            state.showLogin = false;
            localStorage.setItem('token', action.payload.authData.token);
            localStorage.setItem('expirationTime', action.payload.authData.token_expires);
            state.isLoggedIn = true;
            state.fsk = false;
        },
        logoutUser(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('expirationTime');
            state.isLoggedIn = false;
            state.id = initialUserState.id;
            state.username = initialUserState.username;
            state.firstname = initialUserState.firstname;
            state.lastname = initialUserState.lastname;
            state.hasActiveSubscription = initialUserState.hasActiveSubscription;
            state.token = initialUserState.token;
            state.email = initialUserState.email;

        },
        setFsk(state) {
            state.fsk = true;
        },
        setAffiliateDataFromQuerystring(state, action) {
            console.log(action);
        }
    }
});

export const { openLogin, closeLogin, setCountryFromIp, setCurrencyFromIp, setUser, clearUser, loginUser, loginWithToken, logoutUser, setFsk, setAffiliateDataFromQuerystring } = userSlice.actions;
export default userSlice.reducer;

import {ArrowUpIcon} from "@heroicons/react/solid";

const ScrollToTop = () => {

    const onScrollClick = () => {
        document.getElementById('scrollContainer').scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <div className="fixed h-12 w-12 bg-black right-0.5 cursor-pointer hover:bg-fundo-purple z-40" style={{bottom: 80, right: 10, opacity: 0.5}} onClick={onScrollClick}>
            <div className="p-1">
                <ArrowUpIcon className="w-10 h-10 text-white" />
            </div>
        </div>
    )
}
export default ScrollToTop;

import { createSlice } from "@reduxjs/toolkit";

const initialLoginState = {
    showLogin: false,
    isLoggedIn: false,
    success: false,
    error: null
};

const loginSlice  = createSlice({
    name: 'login',
    initialState: initialLoginState,
    reducers: {
        openLogin(state) {
            state.showLogin = true;
        },
        closeLogin(state) {
            state.showLogin = false;
        },
        loginWithToken(state, action) {
            state.showLogin = false;
            state.isLoggedIn = action.payload.userIsLoggedIn;
            state.success = action.payload.userIsLoggedIn;
        },
        loginUser(state, action) {
            state.showLogin = false;
            localStorage.setItem('token', action.payload.authData.token);
            localStorage.setItem('expirationTime', action.payload.authData.token_expires);
            state.isLoggedIn = true;
            state.success = true;
        },
        logoutUser(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('expirationTime');
            state.isLoggedIn = false;
            state.success = false;
        }
    }
});

export const { openLogin, closeLogin, loginUser, loginWithToken, logoutUser } = loginSlice.actions;
export default loginSlice.reducer;

import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeBuyCoins} from "../../store/base-slice";
import {useTranslation} from "react-i18next";
import {CheckCircleIcon, DotsCircleHorizontalIcon} from "@heroicons/react/solid";
import { pickBestAvailableLanguage } from "../../core/helpers/TranslationHelpers";
import {availablePaymentMethods} from "../../data/payment-methods";
import {buyCoins} from "../store/payment-actions";

const currencyFormat = (num, currency = 'eur') => {
    const floatNum = parseFloat(num);
    return 'Є ' + floatNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const BuyCoinsModal = () => {
    const { t, i18n } = useTranslation();
    const token = localStorage.getItem('token');
    const [packages, setPackages] = useState([]);
    const [methods, setMethods] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(true);
    const { country  } = useSelector(state => state.auth.user);
    const [activeCoinPackage, setActiveCoinPackage] = useState(2);
    const [selectedCoinPackage, setSelectedCoinPackage] = useState();
    const dispatch = useDispatch();
    const { showBuyCoins } = useSelector(state => state.base);
    const { currency } = useSelector(state => state.auth.user);



    useEffect(() => {
        setMethods(availablePaymentMethods(country));
        const fetchPackages = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/coins/packages', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setPackages(responseData);
            setIsLoading(false);
        }
        fetchPackages().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    }, [country, token]);


    useEffect(() => {
        console.log(httpError);
    }, [httpError])

    const closeModal = () => {
        dispatch(closeBuyCoins());
    }

    const handleBuy = (method) => {
        setIsLoading(true);
        const buyCoinsResponse = dispatch(buyCoins(selectedCoinPackage.id, method, currency));
        buyCoinsResponse.then((data) => {
            if (data.status === 'success') {
                window.location.href = data.url;
            } else {
                setIsLoading(false);
            }
        });
    };

    const selectThePackage = (cPack) => {
        setSelectedCoinPackage(cPack);
        setActiveCoinPackage(cPack.id)
    };

    return (
        <Transition.Root show={showBuyCoins} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                            <div className="flex p-3">
                                <div className='w-full px-10 pb-4 pt-10 bg-fundo-gray-light rounded-tr-md rounded-br-md'>
                                    <div className='text-2xl font-bold text-center mb-10' dangerouslySetInnerHTML={{__html: t('coins.modal_title')}} />
                                    <div>
                                        {t('coins.modal_description')}
                                    </div>
                                    {!isLoading &&
                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-5">
                                        {packages?.map((coin) => (
                                            <div
                                                key={coin.id}
                                                onClick={() => selectThePackage(coin)}
                                                className={classNames(
                                                    activeCoinPackage !== coin.id ? 'bg-fundo-snupper shadow-md' : 'bg-white',
                                                    'relative rounded-lg border border-gray-300 px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400'
                                                )}
                                            >
                                                <div className="flex-shrink-0">
                                                    {activeCoinPackage === coin.id &&
                                                        <CheckCircleIcon className="h-8 w-8 text-green-400"/>
                                                    }
                                                    {activeCoinPackage !== coin.id &&
                                                        <DotsCircleHorizontalIcon className="h-8 w-8 text-gray-200" />
                                                    }
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <a href="#" className="focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        <p className="text-sm font-medium text-gray-900">{pickBestAvailableLanguage(coin.title, i18n.language)}</p>
                                                        <p className="text-sm text-gray-500 truncate">{currencyFormat(coin.price_eur, currency.toLowerCase())}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    }
                                    <div className="mb-5 mt-10 text-center">
                                        <span className="text-xl font-bold">{t('coins.select_method').toUpperCase()}</span>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {methods.map((method) => (
                                            <div key={method.code} className="flex bg-white rounded-md border-2 px-3 py-2 cursor-pointer"
                                                 onClick={() => handleBuy(method.code)}
                                            >
                                                <div className="flex-none w-28 h-16 flex items-center justify-center">
                                                    <img src={method.image} alt={method.name[i18n.language]} />
                                                </div>
                                                <div className="flex-grow h16 flex items-center pl-3">
                                                    <span className="text-2xl font-light">{method.name[i18n.language]}</span>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default BuyCoinsModal;
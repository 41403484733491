import {PulseLoader} from "react-spinners";
import {useTranslation} from "react-i18next";

const InfiniteLoading = () => {
    const { t } = useTranslation();

    return (
        <div className="grid grid-cols-3 bg-fundo-gray-light mt-5 py-4">
            <div className="col-start-2 col-end-2 text-center ">
                <PulseLoader size={10} /> <span className="font-light text-2xl text-fundo-gray-dark">{t('infloading')}</span>
            </div>
        </div>
    );
}
export default InfiniteLoading;

import {Fragment, useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SceneItem from "../video/SceneItem";
import {AdjustmentsIcon} from "@heroicons/react/solid";
import DvdItem from "../video/DvdItem";
import {pickBestAvailableLanguage} from "../../helpers/TranslationHelpers";
import InfiniteEndReached from "../InfiniteEndReached";
import InfiniteLoading from "../InfiniteLoading";
import GenreDropdown from "./GenreDropdown";
import {openSignup} from "../../../store/base-slice";
import {useDispatch, useSelector} from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const VideoBrowser = (props) => {
    const { listType, listId, browseType } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState('Videos');
    const [selectedListType, setSelectedListType] = useState(null)
    const [selectedListId, setSelectedListId] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [endReached, setEndReached] = useState(false);
    const [isInitial, setIsInitial] = useState(true);
    const [videos, setVideos] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedGenre, setSelectedGenre] = useState();
    const fskSite = useSelector(state => state.base.fskSite);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);


    const { i18n, t } = useTranslation();

    const fetchVideos = async (theListType, $theListId ,loadPage) => {
        let append = '';
        if (selectedGenre) {
            append = '&genre=' + selectedGenre.id;
        }
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/videos/featured/' + theListType + '/' + $theListId + '?lang='+ i18n.language + append, {
            params: {
                page: loadPage
            }
        });

        if (response.status !== 200) {
            throw new Error('Unable to load videos!');
        }

        const responseData = await response.data;
        setPage(responseData.videos.current_page + 1);

        if (loadPage === 1) {
            setVideos(responseData.videos.data);
            setTotalCount(responseData.videos.total)
            setTitle(responseData.list_title);
            setIsInitial(false);
        } else {
            setVideos(videos.concat(responseData.videos.data));
        }
        if (responseData.videos.current_page === responseData.videos.last_page ) {
            setEndReached(true);
        }

        setIsLoading(false);
    }


    const loadMore = () => {
        fetchVideos(listType, listId, page).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    }


    useEffect(() => {
        setVideos([]);
        setSelectedListType(listType);
        setSelectedListId(listId);
        setPage(1);
        setSelectedGenre(null);
        setEndReached(false);

        fetchVideos(listType, listId, 1).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    },[props.listType, props.listId]);

    useEffect(() => {
        setVideos([]);
        setSelectedListType(listType);
        setSelectedListId(listId);
        setPage(1);
        setEndReached(false);

        fetchVideos(listType, listId, 1).catch(error => {
            setIsLoading(false);
            // setHttpError(error.message)
        });
    }, [selectedGenre])

    const handleGenreSelect = (genre) => {
        console.log(genre);
        setSelectedGenre(genre);
    };

    const onItemClick = (video) => {
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                navigate('/video/' + video.id + '/' + video.slug)
            } else {
                dispatch(openSignup());
            }
        } else {
            navigate('/video/' + video.id + '/' + video.slug)
        }
    }


    return (
        <Fragment>
            <div className="flex px-3">
                <div className="flex-grow">
                    <h2 className="text-fundo-gray-dark font-light text-lg md:text-3xl">
                        {title} <span className="text-sm ml-3">({totalCount})</span>
                    </h2>
                </div>
                <div>
                    {listType !== 'genre' &&
                    <GenreDropdown
                        onGenreClick={(genre) => handleGenreSelect(genre) }
                        selectedGenre={selectedGenre}
                    />
                    }
                </div>
            </div>

            <div className="mt-3">
                <InfiniteScroll
                    dataLength={videos.length}
                    next={loadMore}
                    hasMore={!endReached}
                    loader={ <InfiniteLoading /> }
                    endMessage={ <InfiniteEndReached /> }
                    scrollableTarget="scrollContainer"

                >
                    <div className={classNames(browseType === 'dvd'
                        ? 'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3' : 'grid-cols-2 md:grid-cols-4 gap-3',
                        'grid ')}>
                    {videos.map((video, index) => (
                        <Fragment key={video.id}>
                            {video.collection !== 1 &&
                                <SceneItem image={video.artwork.large}
                                           title={pickBestAvailableLanguage(video.title, i18n.language)}
                                           onClick={() => onItemClick(video)}
                                           actors={video.actors}
                                           duration={video.meta.duration}
                                           publicationDate={video.publication_date}
                                />
                            }
                            {video.collection === 1 &&
                                <DvdItem image={video.cover.large}
                                         title={pickBestAvailableLanguage(video.title, i18n.language)}
                                         actors={video.actors}
                                         onClick={() => onItemClick(video)}
                                         duration={video.meta.duration}
                                         publicationDate={video.publication_date}
                                />
                            }
                        </Fragment>
                    ))}
                    </div>
                </InfiniteScroll>
            </div>
        </Fragment>
    );
}
export default VideoBrowser;

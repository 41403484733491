import {Fragment, useEffect, useRef, useState} from "react";

import SelectSearch from "react-select-search";

import 'react-select-search/style.css';

const CountrySelector = ( props ) => {
    const { language, country, placeholder, onChange } = props;
    const countryRef = useRef();
    const [selectedCountry, setSelectedCountry] = useState(country);
    const [countries, setCountries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/meta/countries/' + language);

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }

            const responseData = await response.json();
            setCountries(responseData);
            setIsLoading(false);
        }

        fetchCountries().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    },[props]);

    const countryChanged = () => {
        setSelectedCountry(countryRef.current.value);
        console.log('country changed: ', countryRef.current.value);
        onChange(countryRef.current.value);
    }

    return (
        <Fragment>
            <div>
                <select
                    onChange={countryChanged}
                    ref={countryRef}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                    value={selectedCountry}
                >
                    {countries?.map((c, index) =>
                        <option key={c.value}
                                value={c.value}
                        >{c.name}</option>
                    )}

                </select>
            </div>
        </Fragment>
    );

}
export default CountrySelector;
import {CalendarIcon, FolderIcon, HomeIcon, SparklesIcon, UsersIcon, VideoCameraIcon} from "@heroicons/react/outline";
import {BiMoviePlay, MdOutlineMovieCreation, MdOutlineMovieFilter} from "react-icons/all";

const mainNavigation = [
    { name: 'navigation.home', icon: HomeIcon, current: true, path: '/' },
    {
        name: 'navigation.fundorado.name',
        icon: VideoCameraIcon,
        current: true,
        path: '/cams/live',
        children: [
            { name: 'navigation.fundorado.live', path: '/cams/live' },
            { name: 'navigation.fundorado.private', path: '/cams/private' },
            // { name: 'navigation.fundorado.voyeur', path: '/cams/voyeur' },
            { name: 'navigation.fundorado.starshows', path: '/cams/starshow' },
            // { name: 'navigation.fundorado.videonew', path: '/videos/new' },
            // { name: 'navigation.fundorado.videotop100', path: '/videos/top100' },
        ],
    },
    {
        name: 'navigation.fundo_specials.name',
        icon: SparklesIcon,
        current: false,
        path: '/channels/specials',
        children: [
            { name: 'navigation.fundo_specials.realityseries', path: '/channels/specials/reality' },
            { name: 'navigation.fundo_specials.sadorado', path: '/channels/specials/sadorado' },
            { name: 'navigation.fundo_specials.gaydorado', path: '/channels/specials/gaydorado' },
            { name: 'navigation.fundo_specials.texasgangbang', path: '/channels/specials/texasgangbang' },
            { name: 'navigation.fundo_specials.footfetish', path: '/channels/specials/footfetish' },
            { name: 'navigation.fundo_specials.tickle', path: '/channels/specials/tickle' },
            { name: 'navigation.fundo_specials.orgasmabuse', path: '/channels/specials/orgasmabuse' },
            { name: 'navigation.fundo_specials.teencoreclub', path: '/channels/specials/teencoreclub' },
        ],
    },
    {
        name: 'navigation.featured.name',
        icon: MdOutlineMovieCreation,
        current: false,
        path: '/videos/featured',
        children: [
            { name: 'navigation.featured.dutch', path: '/videos/featured/cast/58819' },
            { name: 'navigation.featured.dorcel', path: '/videos/featured/studio/325' },
            { name: 'navigation.featured.magmafilm', path: '/videos/featured/studio/669' },
            { name: 'navigation.featured.mmv', path: '/videos/featured/studio/901' },
            { name: 'navigation.featured.deutschlandporno', path: '/videos/featured/studio/869' },
            { name: 'navigation.featured.eroticplanet', path: '/videos/featured/studio/879' },
            { name: 'navigation.featured.ifb', path: '/videos/featured/studio/623' },
            { name: 'navigation.featured.reaves', path: '/videos/featured/studio/2352' },
            { name: 'navigation.featured.wicked', path: '/videos/featured/studio/807' },
            { name: 'navigation.featured.brazzers', path: '/videos/featured/studio/861' },
            { name: 'navigation.featured.mofos', path: '/videos/featured/studio/903' },
            { name: 'navigation.featured.private', path: '/videos/featured/studio/383' },
            { name: 'navigation.featured.penthouse', path: '/videos/featured/studio/1960' },
            { name: 'navigation.featured.bangbros', path: '/videos/featured/studio/1948' },
            { name: 'navigation.featured.allstudios', path: '/studios' },
        ],
    },
    {
        name: 'navigation.bonus.name',
        icon: MdOutlineMovieFilter,
        current: false,
        path: '/videos/bonus',
        children: [
            { name: 'navigation.bonus.russian', path: '/videos/bonus/studio/461' },
            { name: 'navigation.bonus.hqteens', path: '/videos/bonus/studio/1624' },
            { name: 'navigation.bonus.mixed1', path: '/videos/bonus/studio/1636' },
            { name: 'navigation.bonus.mixed2', path: '/videos/bonus/studio/1870' },
        ],
    },

]

export default mainNavigation;

import {CountryDropdown} from "react-country-region-selector";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {userUpdate} from "../../../../auth/store/user-actions";



const UserChangePassword = ( props ) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [updateLoading, setUpdateLoading] = useState(false);
    const { onUpdateComplete } = props;
    const { register, getValues, setError, formState: { errors }, handleSubmit, control } = useForm();

    const onSubmit = data => {
        setUpdateLoading(true);

        const test = dispatch(userUpdate(data));
        test.then((data) => {
            if (data.status === 'success') {
                onUpdateComplete(data);
            } else {
                if (data.data.errors.username) {
                    data.data.errors.username.forEach((errData) => {
                        setError('username', { type: "custom", message: errData});
                    });
                }

                if (data.data.errors.email) {
                    data.data.errors.email.forEach((errData) => {
                        setError('email', { type: "custom", message: errData});
                    });
                }
                setUpdateLoading(false);
            }
        })


    }
    return (
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profile.password.title')}</h3>
                    <p className="mt-1 text-sm text-gray-500">{t('profile.password.description')}</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-6">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    {t('profile.password.current_password')}
                                </label>
                                <input
                                    type="password"
                                    className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    {...register("current_password", {
                                        required: true,
                                        minLength: 5,
                                    })}
                                />
                                {errors.current_password &&
                                    <p className="mt-2 text-xs text-red-600" id="email-error">
                                        {errors.current_password.type === 'custom' ? errors.current_password.message : t('auth.validation.firstname')}
                                    </p>
                                }
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    {t('profile.password.new_password')}
                                </label>
                                <input
                                    type="password"
                                    className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    {...register("new_password", {
                                        required: true,
                                        minLength: 5
                                    })}
                                />
                                {errors.new_password &&
                                    <p className="mt-2 text-xs text-red-600" id="email-error">
                                        {errors.new_password.type === 'custom' ? errors.new_password.message : t('auth.validation.firstname')}
                                    </p>
                                }
                            </div>

                            <div className="col-span-6 sm:col-span-6">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    {t('profile.password.confirm_password')}
                                </label>
                                <input
                                    type="password"
                                    className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    {...register("confirm_password", {
                                        required: true,
                                        minLength: 5
                                    })}
                                />
                                {errors.confirm_password &&
                                    <p className="mt-2 text-xs text-red-600" id="email-error">
                                        {errors.confirm_password.type === 'custom' ? errors.confirm_password.message : t('auth.validation.firstname')}
                                    </p>
                                }
                            </div>


                        </div>
                        <div className="flex justify-end mt-5">
                            <button
                                type="submit"
                                disabled={true}
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fundo-purple-light hover:bg-fundo-purple "
                            >
                                {t('profile.password.password_change_button')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default UserChangePassword;
import {useNavigate} from "react-router-dom";
import {BsCircleFill} from "react-icons/all";
import {CheckCircleIcon} from "@heroicons/react/solid";
import ReactCountryFlag from "react-country-flag";
import {useDispatch, useSelector} from "react-redux";
import {openSignup} from "../../../../store/base-slice";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const PrivateCamItem = ( props ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fskSite = useSelector(state => state.base.fskSite);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    const { cam, fsk } = props;

    const onClickHandler = () => {
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                navigate('/cams/private/'+cam.id+'/'+cam.pseudo);
            } else {
                dispatch(openSignup());
            }
        } else {
            navigate('/cams/private/'+cam.id+'/'+cam.pseudo);
        }
    }
    return (
        <div className="cursor-pointer rounded-md shadow-lg" onClick={onClickHandler}>
            <div className="relative">
                <img src={fsk ? cam.previewimageurlsoft : cam.previewimageurl} alt={cam.pseudo} className="w-full" />
                <div className="absolute top-0 right-0 bg-black bg-opacity-50 px-2 py-1 space-x-2">
                    <span className="inline-block text-sm font-bold text-white">HD</span>
                    <span className="inline-block text-green-500"><BsCircleFill className="text-green w-3 h-3" /></span>
                </div>
                {cam.exclusive &&
                    <div className="absolute bottom-0 left-0 bg-fundo-blue px-2 py-1 space-x-2">
                        <span className="inline-block text-sm font-bold text-white">exclusive</span>
                    </div>
                }
                {cam.isnew &&
                    <div className="absolute bottom-0 right-0 bg-green-500 px-2 py-1 space-x-2">
                        <span className="inline-block text-sm font-bold text-white">new</span>
                    </div>
                }
            </div>
            <div className="bg-white px-2 py-3 flex">
                <div className="flex-grow">
                    <div className="text-base font-bold">
                        {cam.pseudo}
                    </div>
                    <div className="text-xs font-light">
                        {cam.vcardsoft}
                    </div>
                </div>
                <div className="flex-none">
                    <ReactCountryFlag
                        countryCode={cam.country.toUpperCase() === 'EN' ? 'GB' : cam.country.toUpperCase()}
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                        style={{
                            borderRadius: '50%',
                            height: '1.5rem',
                            width: '1.5rem'
                        }}
                        className='h-8 w-8 '
                        svg />
                </div>
            </div>
        </div>
    );

};
export default PrivateCamItem;
import {Fragment, useState} from "react";
import {loginWithToken} from "./store/user";
import {useDispatch} from "react-redux";
import {verifyUser} from "./store/auth-actions";
import {setNavExpanded} from "../store/base-slice";

// let logoutTimer;

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjustedExpirationTime = new Date(expirationTime).getTime();

    return adjustedExpirationTime - currentTime;
}

const retreiveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedExpirationDate = localStorage.getItem('expirationTime');

    const remainingTime = calculateRemainingTime(storedExpirationDate);
    if (remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        return null;
    }
    return { token: storedToken, duration: remainingTime };
}

const Auth = (props) => {
    const dispatch = useDispatch();
    const tokenData = retreiveStoredToken();
    let initialToken;
    if (tokenData) {
        initialToken = tokenData.token;
    }
    const [token] = useState(initialToken);

    const userIsLoggedIn = !!token;

    dispatch(loginWithToken({userIsLoggedIn}));
    if (userIsLoggedIn) {
        dispatch(verifyUser(token));
    }


    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
}

export default Auth;

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FlatFeeCamItem from "./FlatFeeCamItem";

const FlatFeeVertical = (props) => {
    const [performers, setPerformers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()

    useEffect(() => {
        const fetchPerformers = async () => {
            let url = new URL(process.env.REACT_APP_BACKEND_URL + '/ffcams')
            url.search = new URLSearchParams({
                online:true,
                limit: 6
            });
            const response = await fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setPerformers(responseData);
            setIsLoading(false);
        }
        fetchPerformers().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    },[]);

    return (
        <div className="px-3">
            <div>
                <h2 className="text-2xl font-light py-2">{ t('CamGirls Online')}</h2>
            </div>
            {!isLoading &&
                <div className="grid grid-cols-1 cursor-pointer">
                    {performers.map((performer, index) => (
                        <FlatFeeCamItem key={index} performer={performer}/>
                    ))}
                </div>
            }
        </div>
    );
};
export default FlatFeeVertical;

import {Fragment, useEffect, useState} from 'react'
import { Menu, Transition } from '@headlessui/react'
import {AdjustmentsIcon, ChevronDownIcon} from '@heroicons/react/solid'
import {useTranslation} from "react-i18next";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {pickBestAvailableLanguage} from "../../helpers/TranslationHelpers";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const GenreDropdown = ( props ) => {
    const { onGenreClick, selectedGenre } = props;
    const [genres, setGenres] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()
    const navigate = useNavigate();
    const [selectedGenreTitle, setSelectedGenreTitle] = useState();

    useEffect(() => {
        const fetchGenres = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/genres/overview?collection=1&limit=11',{
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },

            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setGenres(responseData);
            setIsLoading(false);
        }
        fetchGenres().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    }, [])

    useEffect(() => {
        setSelectedGenreTitle(null);
    }, [selectedGenre])


    const handleGenreClick = (genre) => {
        setSelectedGenreTitle(genre ? pickBestAvailableLanguage(genre.title, i18n.language) : null);
        onGenreClick(genre);
    }


    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex justify-center w-full border border-gray-300 shadow-sm px-4 py-3 bg-fundo-purple text-white text-sm font-medium hover:bg-fundo-purple-light">
                    <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    { selectedGenreTitle ? t('videos.genre') + ': ' + selectedGenreTitle : t('videos.genres')}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {/*<div className="px-4 py-3">*/}
                    {/*    <p className="text-sm">Signed in as</p>*/}
                    {/*    <p className="text-sm font-medium text-gray-900 truncate">tom@example.com</p>*/}
                    {/*</div>*/}
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    key={0}
                                    onClick={() => handleGenreClick(null)}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm w-full'
                                    )}
                                >
                                    { t('videos.all_genres') }
                                </button>
                            )}
                        </Menu.Item>
                        {genres.map((genre) => (
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        key={genre.id}
                                        onClick={() => handleGenreClick(genre)}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 text-sm w-full'
                                        )}
                                    >
                                        { pickBestAvailableLanguage(genre.title,i18n.language) }
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
};
export default GenreDropdown;

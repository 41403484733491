import {Fragment, useEffect, useRef, useState} from "react";
import MainPlayer from "./MainPlayer";

let videoJsOptions = { // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
        src: '/path/to/video.mp4',
        type: 'video/mp4'
    }]
}


const SvodPlayer = ( props ) => {
    const [isLoading, setIsLoading] = useState(true);
    const [linkData, setLinkData] = useState([]);
    const token = localStorage.getItem('token');
    const [options, setOptions] = useState(videoJsOptions);

    const playerRef = useRef(null);



    useEffect(() => {
        const fetchLinks = async (videoId) => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/video/links/' + videoId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            videoJsOptions.sources[0].type = responseData.mime_type;
            videoJsOptions.sources[0].src = responseData.url;
            setLinkData(responseData);
            setIsLoading(false);
        }

        fetchLinks(props.id).catch(error => {
            setIsLoading(false);
            //setHttpError(error.message)
            console.log(error.message)
        });
    },[props.id]);


    const handlePlayerReady = (player) => {
        playerRef.current = player;
        console.log('player', player)
        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    const loadPlayer = () => {
        return (
            <MainPlayer options={videoJsOptions} onReady={handlePlayerReady} />
        )
    };

    return (
        <Fragment>
            {!isLoading &&
                loadPlayer()
            }
        </Fragment>
    )

};
export default SvodPlayer;

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import FundoLogoPlain from "../../core/ui/svg/FundoLogoPlain";
import {useTranslation} from "react-i18next";
import 'react-day-picker/lib/style.css';
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css"
import SignupForm from "../forms/SignupForm";
import FundoFPlain from "../../core/ui/svg/FundoFPlain";
import SubscribeForm from "../forms/SubscribeForm";
import {useDispatch, useSelector} from "react-redux";
import {closeSignup} from "../../store/base-slice";
// import { closeSignup } from "../store/signup";

const SignupModal = (props) => {
    const dispatch = useDispatch();
    const [signupActive, setSignupActive] = useState(true);
    const [subscribeActive, setSubscribeActive] = useState(false);
    const showSignup = useSelector(state => state.base.showSignup);



    const {t } = useTranslation();

    const onCloseHandler = () => {
        dispatch(closeSignup());
    }

    const signupCompleted = (data) => {
        setSignupActive(false);
        setSubscribeActive(true);
    }

    return (
        <Transition.Root show={showSignup} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onCloseHandler}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full">
                            <div className="flex p-3">
                                <div className='hidden md:block md:w-3/12 bg-fundo-purple rounded-tl-md rounded-bl-md flex flex-wrap content-between'>
                                    <div></div>
                                    <div></div>
                                    <div className='px-5 pb-4'>
                                        <FundoLogoPlain className='w-full'/>
                                    </div>
                                </div>
                                {signupActive &&
                                <div className='w-full md:w-9/12 px-10 pb-4 pt-10 bg-fundo-gray-light rounded-tr-md rounded-br-md'>
                                    <div className='text-2xl font-bold text-center mb-10' dangerouslySetInnerHTML={{__html: t('auth.texts.signup_header')}}/>
                                    <SignupForm onSignupCompleted={signupCompleted} />
                                </div>}

                                {subscribeActive &&
                                <div className='w-full md:w-9/12 px-10 pb-4 pt-10 bg-fundo-gray-light rounded-tr-md rounded-br-md'>
                                    <div className='text-2xl font-bold text-center mb-10 grid grid-cols-6'>
                                        <div className='col-span-1 text-fundo-purple'>
                                            <FundoFPlain className='h-16 fill-current' />
                                        </div>
                                        <div className='col-span-5 text-4xl uppercase text-fundo-purple'>
                                            {t('auth.texts.subscribe_header')}
                                        </div>
                                    </div>
                                    <SubscribeForm />
                                </div>}

                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default SignupModal;

import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";

const months = [
    {
        id: "01",
        name: "months.jan"
    },
    {
        id: "02",
        name: "months.feb"
    },
    {
        id: "03",
        name: "months.mar"
    },
    {
        id: "04",
        name: "months.apr"
    },
    {
        id: "05",
        name: "months.may"
    },
    {
        id: "06",
        name: "months.jun"
    },
    {
        id: "07",
        name: "months.jul"
    },
    {
        id: "08",
        name: "months.aug"
    },
    {
        id: "09",
        name: "months.sep"
    },
    {
        id: "10",
        name: "months.oct"
    },
    {
        id: "11",
        name: "months.nov"
    },
    {
        id: "12",
        name: "months.dec"
    },
];

const currentDate = moment();

const DateSelector = ( props ) => {
    const { onChange } = props;
    const [dateField, setDateField] = useState();
    const dayRef = useRef(currentDate.day());
    const monthRef = useRef(currentDate.month());
    const yearRef = useRef(currentDate.year());
    const [day, setDay] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();

    const { t } = useTranslation();

    const handleChange = () => {
        let newDate = yearRef.current.value + '-' + monthRef.current.value + '-' + dayRef.current.value;
        onChange(newDate);
        console.log('changeeeee', newDate);
    }


    return (
        <div>
            <input type="hidden" value={dateField}/>
        <div className={`grid grid-cols-3 gap-1`}>
            <div>
                <input type="text"
                       ref={dayRef}
                       className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                       placeholder={t('day')}
                       onChange={event => {
                           handleChange();
                       }}
                />
            </div>
            <div>
                <select ref={monthRef}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('month')}
                        onChange={event => {
                            setMonth(event.target.value);
                            handleChange();
                        }}                >
                    {months.map((monthOpt) =>
                        <option key={monthOpt.id} value={monthOpt.id} >{t(monthOpt.name)}</option>
                    )}
                </select>
            </div>
            <div>
                <input type="text"
                       ref={yearRef}
                       className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                       placeholder={t('year')}
                       onChange={event => {
                           setYear(event.target.value);
                           handleChange();
                       }}
                />
            </div>
        </div>
        </div>
    )
}
export default DateSelector;
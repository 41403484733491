import {Fragment} from "react";

const Affiliate = ( props ) => {
    const searchParams = new URLSearchParams(window.location.search);

    const cw = searchParams.get('cw');
    const ct = searchParams.get('CT');
    const ppid = searchParams.get('ppid');

    console.log('affiliate',cw,ct, ppid)

    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
}
export default Affiliate;
import moment from "moment";
import {truncateString} from "../../helpers/StringHelpers";
import {useState} from "react";


const DvdItem = ( props ) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const { onClick, image, title, duration, publicationDate, actors, positionNumber, fsk } = props;

    const openDetails = () => {
        setDetailsOpen(true);
    }

    const closeDetails = () => {
        setDetailsOpen(false);
    }

    return (
        <div className="relative transition cursor-pointer shadow-md rounded-md  hover:shadow-none"  onMouseEnter={openDetails} onMouseLeave={closeDetails}>
            <div className="relative" onClick={onClick}>
                <img className="w-full" src={image} alt={title} />
                    {/*<div className="absolute bg-fundo-purple text-white rounded-full top-1 right-1 z-10 w-8 h-8">*/}
                    {/*    <span>{positionNumber}</span>*/}
                    {/*</div>*/}
            </div>

            <div className="bg-white pt-2 px-2 pb-2">
                <div className="flex flex-wrap">
                    <div className="w-full text-sm sm:text-base font-bold text-fundo-gray-dark">
                        <span onClick={onClick}>{truncateString(title, 22)}</span>
                    </div>
                    <div className="flex-grow text-xs">
                        {(actors?.length > 0) &&
                            actors.slice(0, 2).map((actor, i, arr) => <span key={actor.id} >{actor.name} {i !== (arr.length-1) ? ',' : ''}</span>)
                        }
                    </div>
                    <div className="flex-none text-xs">
                        {duration}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DvdItem;

import TinySlider from "tiny-slider-react";
import NextStarShow from "../../core/components/cams/NextStarShow";
import {useNavigate} from "react-router-dom";
import {HeaderBanners} from "../../data/header-banners";
import {useTranslation} from "react-i18next";
import {openSignup} from "../../store/base-slice";
import {useDispatch} from "react-redux";
import {pickBestAvailableLanguage} from "../../core/helpers/TranslationHelpers";

const HomeHeader = ( props ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { fsk } = props;
    const {i18n} = useTranslation();

    const settings = {
        controls: false,
        autoplayButton: false,
        autoplayButtonOutput: false,
        lazyload: true,
        nav: false,
        mouseDrag: true,
        items: 1,
        autoplay: true,
        autoplayTimeout: 4000
    };

    const onBannerClick = (path) => {
        if (fsk) {
            dispatch(openSignup())
        } else {
            navigate(path)
        }

    }
    return (
        <div className="w-full grid grid-cols-8 mb-10 px-3">
            <div className="col-span-8 xl:col-span-6">
                <TinySlider settings={settings} >
                    {HeaderBanners.map((banner, index) =>
                        <div key={index}  className="relative">
                            <button onClick={() => onBannerClick(banner.path)} className="w-full">
                                <img
                                    className={`tns-lazy-img w-full rounded-md`}
                                    src={pickBestAvailableLanguage(banner.image, i18n.language)}
                                    data-src={pickBestAvailableLanguage(banner.image, i18n.language)}
                                    alt={banner.title[i18n.language]}
                                />
                            </button>
                        </div>
                    )}
                </TinySlider>
            </div>
            <div className="col-span-8 xl:col-span-2 mt-5 lg:mt-0 lg:px-3">
                <NextStarShow fsk={fsk} />
            </div>
        </div>
    )
}
export default HomeHeader
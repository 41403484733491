import {CountryDropdown} from "react-country-region-selector";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {authRegisterUser} from "../../../../auth/store/auth-actions";
import CountrySelector from "../../../../core/components/meta/CountrySelector";
import {useState} from "react";
import {userUpdate} from "../../../../auth/store/user-actions";
import {CheckCircleIcon} from "@heroicons/react/outline";
import {  toast } from 'react-toastify';



const UserAccount = ( props ) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [userIsUpdated, setUserIsUpdated] = useState(false);
    const [userUpdateFailed, setUserUpdateFailed] = useState(false);
    const userCountry = useSelector(state => state.auth.user.country);
    const { onUpdateComplete } = props;
    const { firstname, lastname, email, username, country } = useSelector(state => state.auth.user );
    const { register, getValues, setError, formState: { errors }, handleSubmit, control } = useForm();

    const notifyUpdateSuccess = () => toast.success(t('profile.account.notification_updated'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notifyUpdateFailed = () => toast.error(t('profile.account.notification_updated'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


    const onSubmit = data => {
        setUserUpdateFailed(false);
        setUpdateLoading(true);

        const test = dispatch(userUpdate(data));
        test.then((data) => {
            if (data.status === 'success') {
                notifyUpdateSuccess();
                setUpdateLoading(false);
            } else {
                notifyUpdateFailed();
                setUserUpdateFailed(true);
                if (data.data.errors.email) {
                    data.data.errors.email.forEach((errData) => {
                        setError('email', { type: "custom", message: errData});
                    });
                }
                setUpdateLoading(false);
            }
        })


    }
    return (
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profile.account.title')}</h3>
                        <p className="mt-1 text-sm text-gray-500">{t('profile.account.description')}</p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        {userUpdateFailed &&
                            <div className="rounded-md bg-red-50 p-4 my-3">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <CheckCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-red-800">{t('profile.account.notification_update_failed')}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        {t('profile.account.firstname')}
                                    </label>
                                    <input
                                        type="text"
                                        className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        {...register("firstname", {
                                            required: true,
                                            minLength: 2,
                                            value: firstname
                                        })}
                                    />
                                    {errors.firstname &&
                                        <p className="mt-2 text-xs text-red-600" id="email-error">
                                            {errors.firstname.type === 'custom' ? errors.firstname.message : t('auth.validation.firstname')}
                                        </p>
                                    }
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        {t('profile.account.lastname')}
                                    </label>
                                    <input
                                        type="text"
                                        autoComplete="family-name"
                                        className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        {...register("lastname", {
                                            required: true,
                                            minLength: 2,
                                            value: lastname
                                        })}
                                    />
                                    {errors.lastname &&
                                        <p className="mt-2 text-xs text-red-600" id="email-error">
                                            {errors.lastname.type === 'custom' ? errors.lastname.message : t('auth.validation.lastname')}
                                        </p>
                                    }
                                </div>

                                <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                        {t('profile.account.username')} <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={username}
                                        disabled
                                        readOnly
                                        className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                        {t('profile.account.email')}
                                    </label>
                                    <input
                                        type="text"
                                        className="mt-1  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        {...register("email", {
                                            required: true,
                                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            value: email
                                        })}
                                    />
                                    {errors.email &&
                                        <p className="mt-2 text-xs text-red-600" id="email-error">
                                            {errors.email.type === 'custom' ? errors.email.message : t('auth.validation.email')}
                                        </p>
                                    }
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                        {t('profile.account.country')}
                                    </label>
                                    <Controller
                                        control={control}
                                        name="country"
                                        rules={{ required: true }}
                                        defaultValue={country}
                                        render={({ field }) => (
                                            <CountrySelector language={i18n.language} country={userCountry} onChange={(e) => field.onChange(e)} />

                                        )}
                                    />
                                    {errors.country &&
                                        <p className="mt-2 text-xs text-red-600" id="email-error">
                                            {errors.country.type === 'custom' ? errors.country.message : t('auth.validation.country')}
                                        </p>
                                    }
                                </div>

                            </div>
                            <div className="flex justify-end mt-5">
                                <div className="h-full flex items-center pt-3 text-xs">
                                    {t('profile.account.not_allowed')}
                                </div>
                                <button
                                    type="submit"
                                    disabled={updateLoading}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fundo-purple-light hover:bg-fundo-purple "
                                >
                                    {t('profile.account.update_button')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    )
}
export default UserAccount;
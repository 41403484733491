import { Fragment, useState } from 'react'
import { SearchIcon } from '@heroicons/react/solid'
import LogoImage from '../../assets/images/FundoradoLogo.svg';
import SmallLogoImage from '../../assets/images/FundoFGold.svg';

import {
    MenuIcon,
} from '@heroicons/react/outline'
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FundoSideNavigation from "./FundoSideNavigation";
import {useDispatch, useSelector} from "react-redux";
import {openLogin, openSignup, toggleMainNav, toggleMobileMenu} from "../store/base-slice";
import FundoMobileNavigation from "./FundoMobileNavigation";
import classes from "./TopBar.module.css";
import {logoutUser} from "../auth/store/user";
import LanguageDropdown from "../core/components/LanguageDropdown";
import LoginModal from "../auth/modals/LoginModal";
import SignupModal from "../auth/modals/SignupModal";
import ScrollToTop from "../core/components/ScrollToTop";
import Footer from "./Footer";
import UserDropDownMenu from "../core/components/general/UserDropDownMenu";
import MobileTabbar from "./navigation/MobileTabbar";
import navigationData from "../data/mobileNavigation";
import useNavigation from "../hooks/useNavigation";
import BuyCoinsModal from "../auth/modals/BuyCoinsModal";
import SearchBar from "../core/components/general/SearchBar";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FundoLayout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentRoute, setCurrentRoute } = useNavigation();
    const { mainBackgroundColor } = props;


    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const mainNavExpanded = useSelector(state => state.base.mainNavExpanded);
    const isLoggedIn = useSelector(state => state.auth.user.isLoggedIn);

    const fskSite = useSelector(state => state.base.fskSite);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    const { t } = useTranslation();

    const doToggleMobileMenu = () => {
        dispatch(toggleMobileMenu())
    };
    const handleLogout = () => {
        dispatch(logoutUser());
        navigate('/', { replace: true });
    };

    const handleTabBarClick = (path) => {
        if (fskSite && needsAgeVerification) {
            dispatch(openSignup());
        } else {
            setCurrentRoute(path)
        }
    };

    return (
        <>

            <div
                className="flex items-center m-2 fixed bottom-0 right-0 border border-gray-400 rounded p-2 bg-gray-300 text-pink-600 text-sm z-40">
                Breakpoint
                <span className="ml-1 sm:hidden md:hidden lg:hidden xl:hidden">default (&lt; 640px)</span>
                <span className="ml-1 hidden sm:inline md:hidden font-extrabold">sm</span>
                <span className="ml-1 hidden md:inline lg:hidden font-extrabold">md</span>
                <span className="ml-1 hidden lg:inline xl:hidden font-extrabold">lg</span>
                <span className="ml-1 hidden xl:inline font-extrabold">xl</span>
            </div>
            <div className="h-full flex flex-col">
                {/* Top nav*/}
                <header className="flex-shrink-0 relative h-16 bg-fundo-purple flex items-center">
                    {/* Logo area */}
                    <div className="absolute hidden md:block inset-y-0 left-0 md:static md:flex-shrink-0 text-white">
                        <Link
                            to="/"
                            className={classNames(
                                mainNavExpanded ? 'w-64' : 'w-16 md:w-20',
                                'flex items-center justify-center h-16 bg-fundo-purple '
                            )}
                        >
                            {!mainNavExpanded &&
                            <div className="px-3 py-3">
                                <img src={SmallLogoImage} alt="" className="w-64"/>
                             </div>

                            }
                            {mainNavExpanded &&
                                <div className="px-3">
                                    <img src={LogoImage} alt="" className="w-64"/>
                                </div>
                            }

                        </Link>
                    </div>
                    <div className="p-3 block md:hidden">
                        {!isLoggedIn &&
                            <Fragment>
                                <button
                                    className={classes.loginButton}
                                    onClick={() => dispatch(openLogin())}
                                >{t('auth.login')}</button>
                            </Fragment>
                        }
                    </div>

                    {/* Picker area */}
                    <div className={classNames(
                        isLoggedIn ? 'pl-3' : '',
                        'mx-auto md:hidden'
                    )}>
                        <div className="px-3 py-3">
                            <img src={LogoImage} alt="" className="h-8"/>
                        </div>
                    </div>
                    <div className="h-16 p-3">
                        <div className="block md:hidden">
                            {!isLoggedIn &&
                                <Fragment>
                                    <button
                                        className={classes.signupButton}
                                        onClick={() => dispatch(openSignup())}
                                    >{t('auth.subscribe')}</button>
                                </Fragment>
                            }
                            {isLoggedIn &&
                                <div className="mt-1">
                                    <UserDropDownMenu />
                                </div>
                            }
                        </div>

                    </div>
                     {/*Desktop nav area*/}
                    <div className="-ml-3 hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between desktop">
                        <div className="hidden md:block mr-3">
                            <button className="w-10 h-10 hover:text-gray-800" type="button" onClick={() => dispatch(toggleMainNav())}>
                                <MenuIcon className="w-10 text-white m-auto" />
                            </button>
                        </div>
                        <div className="md:container 2xl:mx-auto flex">

                            <div className="min-w-0 flex-grow">
                                <SearchBar />
                            </div>
                            <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">

                                <div className="flex items-center space-x-8">
                                    {!isLoggedIn &&
                                        <Fragment>
                                            <button
                                                className={classes.loginButton}
                                                onClick={() => dispatch(openLogin())}
                                            >{t('auth.login')}</button>

                                            <button
                                                className={classes.signupButton}
                                                onClick={() => dispatch(openSignup())}
                                            >{t('auth.subscribe')}</button>
                                        </Fragment>
                                    }
                                    <LanguageDropdown />
                                    {isLoggedIn &&
                                        <UserDropDownMenu/>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* Mobile menu, show/hide this `div` based on menu open/closed state */}
                    <FundoMobileNavigation />
                </header>

                {/* Bottom section */}
                <div className="min-h-0 flex-1 flex overflow-hidden">
                    {/* Narrow sidebar*/}
                    <FundoSideNavigation />
                    {/* Main area */}
                    <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex bg-gray-200">
                        {/* Primary column */}
                        <section
                            id="scrollContainer"
                            aria-labelledby="primary-heading"
                            className="min-w-0 flex-1 h-full flex flex-col overflow-y-auto lg:order-last"
                        >
                            <LoginModal />
                            <SignupModal />
                            <BuyCoinsModal />
                            <ScrollToTop />
                            <MobileTabbar
                                navigationData={navigationData}
                                currentRoute={currentRoute}
                                setCurrentRoute={handleTabBarClick}
                            />
                            <div className={`2xl:container 2xl:mx-auto  px-3 py-5 ${mainBackgroundColor ? mainBackgroundColor : 'bg-white'}`}>
                                {props.children}
                            </div>
                            <Footer />
                        </section>

                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:hidden lg:flex-shrink-0 lg:order-first">
                            <div className="h-full relative flex flex-col w-96 border-r border-gray-200 bg-gray-100 overflow-y-auto">
                                {/* Your content */}
                            </div>
                        </aside>
                    </main>
                </div>
            </div>
        </>
    )
}
export default FundoLayout;

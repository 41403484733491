import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-video.css';


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import {useCallback, useEffect, useRef, useState} from "react";
import {IoPlayCircleOutline} from "react-icons/all";
import {useTranslation} from "react-i18next";

const FlatFeeVideos = props => {
    const { videos, performerName } = props;
    const [items, setItems] = useState(videos);
    const { t } = useTranslation();

    const onInit = useCallback((detail) => {
        console.log('initttt');
    }, []);

    const getItems = useCallback(() => {
        return props.videos.map((item, index) => {
            return (
                <div
                    key={index}
                    className="gallery-item w-full md:w-1/4 p-2 relative"
                    data-lg-size="1280-720"
                    data-video={`{"source": [{"src":"${item.url}", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}`}
                    data-poster={item.thumb}
                >
                    <img className="img-responsive rounded-md " src={`${item.thumb}&width=320`} />
                    <div className="absolute top-0 left-0 w-full h-full flex">
                        <IoPlayCircleOutline color="#ffffff" size="3em" className="m-auto" />
                    </div>
                </div>
            );
        });
    }, [props.images]);

    return (
        <div className="mt-3">
            {props.videos.length > 0 &&

                <div>
                    <div className="flex flex-wrap">
                        <div>
                            <h2 className="font-light text-xl">{t('cams.videos')}</h2>
                        </div>
                    </div>
                    <LightGallery
                        download={false}
                        plugins={[lgVideo]}
                        mode="lg-fade"
                        licenseKey="BC52E29C-F84D47CD-BD6A5B3E-1F08CED9"
                        elementClassNames="flex flex-wrap"
                        onInit={onInit}
                    >
                        {getItems()}

                    </LightGallery>


                </div>
            }
        </div>
    )
}
export default FlatFeeVideos;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from "./app/store";
import './index.css';
import App from './app/App';
import Auth from "./app/auth/Auth";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import './i18n';
import Fsk from "./app/core/Fsk";
import Affiliate from "./app/core/Affiliate";

document.title = 'Welcome || ' + process.env.REACT_APP_DEFAULT_WEBSITE_TITLE;

const alertOptions = {
    // you can also just use 'bottom center'
    position: positions.MIDDLE,
    timeout: 2500,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
}

ReactDOM.render(
  <React.StrictMode>
          <Provider store={store}>
              <Fsk>
                  <Affiliate>
                      <Auth>
                          <AlertProvider template={AlertTemplate} {...alertOptions}>
                              <App />
                          </AlertProvider>
                      </Auth>
                  </Affiliate>
              </Fsk>
          </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


import {Fragment} from "react";
import getIpApiData from "../services/ipapi/ip-api";
import {setCountryFromIp, setCurrencyFromIp} from "../auth/store/user";
import {useDispatch} from "react-redux";
import {setAgeVerificationActive, setFskSite} from "../store/base-slice";

const Fsk = ( props ) => {
    const dispatch = useDispatch();

    getIpApiData(ipData => {
        dispatch(setCountryFromIp(ipData.countryCode));
        dispatch(setCurrencyFromIp(ipData.currency));

        if (window.location.hostname === process.env.REACT_APP_AVS_HOSTNAME && ipData.countryCode !== 'DE') {
            let queryString = window.location.search;
            window.location.href = 'https://' + process.env.REACT_APP_STANDARD_HOSTNAME + queryString;
        }
        if (window.location.hostname === process.env.REACT_APP_AVS_HOSTNAME && ipData.countryCode === 'DE') {
            dispatch(setAgeVerificationActive());
            dispatch(setFskSite());
        }
    });


    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
}
export default Fsk;
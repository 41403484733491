export const HeaderBanners = [
    {
        title: {
            en: "Welcome",
            de: "Wilkommen",
        },
        path: "/",
        image:{
            en: "/img/banners/front/rotation_willkommen_en.jpg",
            de: "/img/banners/front/rotation_willkommen_de.jpg"
        }
    },
    {
        title: {
            en: "Starshows",
            de: "Starshows",
        },
        path: "/cams/starshow",
        image:{
            en: "/img/banners/front/rotation_shows_en.jpg",
            de: "/img/banners/front/rotation_shows_de.jpg"
        }
    },
    {
        title: {
            en: "Mia Julia",
            de: "Mia Julia",
        },
        path: "",
        image:{
            en: "/img/banners/front/rotation_miajulia_en.jpg",
            de: "/img/banners/front/rotation_miajulia_de.jpg"
        }
    },
    {
        title: {
            en: "Micaela",
            de: "Micaela",
        },
        path: "",
        image:{
            en: "/img/banners/front/rotation_micaela_en.jpg",
            de: "/img/banners/front/rotation_micaela_de.jpg"
        }
    },
];
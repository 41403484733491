import mainNavigation from "../data/main-navigation";
import {NavLink, useLocation} from "react-router-dom";
import {Disclosure} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toggleMobileMenu, toggleMainNavItem, openSignup} from "../store/base-slice";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const FundoSideNavigation = (props) => {
    const dispatch = useDispatch();
    const mainNavExpanded = useSelector(state => state.base.mainNavExpanded);
    const mainNavOpenItems = useSelector(state => state.base.mainNavOpenItems);
    const fskSite = useSelector(state => state.base.fskSite);
    const location = useLocation();
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    const { t } = useTranslation();

    const itemIsExpanded = (itemName) => {
        return mainNavOpenItems.includes(itemName);
    }

    const isCurrentItem = (path) => {
        return path === location.pathname;
    }

    const subItemActive = (item) => {
        let isActive = false;
        if (!item.children) {
            return false
        }
        item.children.forEach((child) => {
            if (child.path ===  location.pathname) {
                isActive = true;
            }
        });
        return isActive;
    }

    const onItemClick = (e) => {
        console.log(e);
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                //
            } else {
                e.preventDefault();
                dispatch(openSignup());
            }
        }
    }

    return (
        <nav aria-label="Sidebar" className="hidden md:block md:flex-shrink-0 md:bg-fundo-purple md:overflow-y-auto pb-20">
            <div className={classNames(
                mainNavExpanded ? 'hidden' : 'block',
                'relative w-20 flex flex-col p-3 space-y-3'
            )}>
                {mainNavigation.map((item) => (
                    <NavLink
                        key={item.name}
                        to={item.path}
                        className={classNames(
                            isCurrentItem(item.path) || subItemActive(item) ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700',
                            'flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg'
                        )}
                    >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                    </NavLink>
                ))}
            </div>

            <div className={classNames(
                mainNavExpanded ? 'block' : 'hidden',
                'relative w-64 flex flex-col p-3 space-y-3'
            )}>
                {mainNavigation.map((item) =>
                    !item.children ? (
                        <div key={item.name}>
                            <NavLink
                                to={item.path}
                                className={classNames(
                                    isCurrentItem(item.path)
                                        ? 'bg-fundo-purple-dark text-white'
                                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'group flex items-center px-2 py-2 text-sm font-medium'
                                )}
                            >
                                <item.icon
                                    className={classNames(
                                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                {t(item.name)}
                            </NavLink>
                        </div>
                    ) : (
                        <Disclosure as="div" key={item.name} className="space-y-1">
                            {({ open }) => (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => dispatch(toggleMainNavItem(item.name))}
                                        className={classNames(
                                            isCurrentItem(item.path) || subItemActive(item)
                                                ? 'bg-fundo-purple-dark text-white'
                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium'
                                        )}
                                    >
                                        <item.icon
                                            className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-300"
                                            aria-hidden="true"
                                        />
                                        <span className="flex-1">{t(item.name)}</span>
                                        <svg
                                            className={classNames(
                                                itemIsExpanded(item.name) ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                            )}
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                        </svg>
                                    </button>
                                    {itemIsExpanded(item.name) &&
                                    <div className="space-y-1 bg-fundo-purple-light">
                                        {item.children.map((subItem) => (
                                            <NavLink
                                                key={subItem.name}
                                                to={subItem.path}
                                                onClick={onItemClick}
                                                className={classNames(isCurrentItem(subItem.path)
                                                        ? 'bg-fundo-gold text-gray-900 font-bold'
                                                        : '',
                                                        'group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-300 hover:text-gray-300 hover:bg-gray-700')}
                                            >
                                                {t(subItem.name)}
                                            </NavLink>
                                        ))}
                                    </div>
                                    }
                                </>
                            )}
                        </Disclosure>
                    )
                )}

            </div>
        </nav>

    );

}
export default FundoSideNavigation;
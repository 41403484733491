export const paymentMethods = [
    {
        code: "creditcard",
        active: true,
        name: {
            en: "Creditcard",
            de: "Creditcard",
            nl: "Creditcard",
            fr: "Creditcard",
            es: "Creditcard",
        },
        availableIn: ["ALL"],
        image: "/img/pay/creditcard.svg",
    },
    {
        code: "directdebit",
        active: true,
        name: {
            en: "Direct Debit",
            de: "Direct Debit",
            nl: "Direct Debit",
            fr: "Direct Debit",
            es: "Direct Debit",
        },
        availableIn: ["ALL"],
        image: "/img/pay/directdebit.svg",
    },
    {
        code: "sofortbanking",
        active: true,
        name: {
            en: "SOFORTbanking",
            de: "SOFORTbanking",
            nl: "SOFORTbanking",
            fr: "SOFORTbanking",
            es: "SOFORTbanking",
        },
        availableIn: ["NL", "DE", "BE", "AT", "CH"],
        image: "/img/pay/sofortbanking.svg",
    },
    {
        code: "giropay",
        active: true,
        name: {
            en: "Giropay",
            de: "Giropay",
            nl: "Giropay",
            fr: "Giropay",
            es: "Giropay",
        },
        availableIn: ["DE", "LU", "AT", "CH"],
        image: "/img/pay/giropay.svg",
    },
    {
        code: "banktransfer",
        active: true,
        name: {
            en: "Bank transfer",
            de: "Bank transfer",
            nl: "Bank transfer",
            fr: "Bank transfer",
            es: "Bank transfer",
        },
        availableIn: ["ALL"],
        image: "/img/pay/banktransfer.svg",
    },
    {
        code: "onlineueberweisen",
        active: true,
        name: {
            en: "OnlineÜberweisen",
            de: "OnlineÜberweisen",
            nl: "OnlineÜberweisen",
            fr: "OnlineÜberweisen",
            es: "OnlineÜberweisen",
        },
        availableIn: ["DE", "NL", "BE", "AT", "CH"],
        image: "/img/pay/banktransfer.svg",
    },
];

export const availablePaymentMethods = (country) => {
    return paymentMethods.filter((method) => {
        if (method.active) {
            if (method.availableIn[0] === 'ALL') {
                return true;
            } else {
                return method.availableIn.includes((country));
            }
        }
    });
};
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import FlatFeeCamItem from "./components/FlatFeeCamItem";
import {AdjustmentsIcon} from "@heroicons/react/solid";

const FlatFeeCams = (props) => {
    const { collection } = props;
    const [performers, setPerformers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        const fetchPerformers = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/ffcams?c=' + collection);

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setPerformers(responseData);
            setIsLoading(false);
        }
        fetchPerformers().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    },[collection])

    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
    }

    return (
        <div>
            <div className="flex px-3">
                <div className="flex-grow">
                    <h2 className="text-fundo-gray-dark font-light text-3xl">
                        {collection === 'regular' ? t('livecams') : t('starshow') } <span className="text-sm ml-3">({performers.length})</span>
                    </h2>
                </div>
                <div>
                    {/*<button*/}
                    {/*    type='button'*/}
                    {/*    onClick={toggleFilter}*/}
                    {/*    className="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium text-white bg-fundo-purple hover:bg-fundo-purple-light"*/}
                    {/*>*/}
                    {/*    <AdjustmentsIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />*/}
                    {/*    {t('filter')}*/}
                    {/*</button>*/}
                </div>
            </div>
            {filterOpen &&
                <div className="grid grid-cols-3 mb-3 bg-fundo-gray-light p-3">
                    <div>

                    </div>
                </div>
            }
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mt-3">
                {performers.map((performer, index) => (
                    <FlatFeeCamItem key={index} performer={performer} />
                ))}
            </div>
        </div>
    )
};
export default FlatFeeCams;

import axios from "axios";

export const authSubscribeUser = (plan, method) => {
    let subscriptionResponse;
    return async (dispatch) => {
        const doSubscribe = async () => {
            const userToken = localStorage.getItem('token');
            if (!userToken) {
                throw new Error('User not signed in');
            }
            const subscribeResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/user/subscribe',
                {
                    plan,
                    method,
                    hostname: window.location.hostname
                },
                {
                    'headers': {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + userToken
                    }
                }
            );
            if (subscribeResponse.status !== 200) {
                throw new Error('subscription_failed');
            }
            return await subscribeResponse.data;
        }

        try {
            subscriptionResponse = await doSubscribe(plan, method);
            console.log('sub response: ', subscriptionResponse);
            // dispatch(loginUser({
            //     authData: registrationDataReponse
            // }))
            return subscriptionResponse;
        } catch (e) {
            console.error('authSubscribeUser failed: ', e.message, e.response);
            return {
                "status": "error",
                "data": e.response
            }
        }
    }
}

import {truncateString} from "../../helpers/StringHelpers";
import LazyLoad from 'react-lazyload';
import SceneItemPlaceholder from "./SceneItemPlaceholder";

const SceneItem = ( props ) => {

    const { onClick, image, title, duration, publicationDate, actors, fsk } = props;


    return (
        <div className="cursor-pointer shadow-md rounded-md" >
            <div className="relative" onClick={onClick}>
                <LazyLoad
                    offset={20}
                    scrollContainer="#scrollContainer"
                    placeholder={<SceneItemPlaceholder className="w-full" />}
                    once={true}
                >
                    <img className="w-full" src={image} alt={title} />
                </LazyLoad>
            </div>
            <div className="bg-white pt-2 px-2 pb-2">
                <div className="flex flex-wrap">
                    <div className="w-full text-sm sm:text-base font-bold text-fundo-gray-dark">
                        <span onClick={onClick}>{truncateString(title, 30)}</span>
                    </div>
                    <div className="flex-grow text-xs">
                        {actors.length > 0 &&
                            actors.slice(0, 2).map((actor, i, arr) => <span key={actor.id}>{actor.name} {i !== (arr.length-1) ? ',' : ''}</span>)
                        }
                    </div>
                    <div className="flex-none text-xs">
                        {duration}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SceneItem;

import {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import * as PropTypes from "prop-types";
import TinySlider from "tiny-slider-react";
import cams from "./Cams";
import {useTranslation} from "react-i18next";


const PrivateCamDetail = () => {
    const { camid, pseudo } = useParams();
    const { chatSessionId, setChatSessionId } = useState(null);
    const [camData, setCamData] = useState();
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();

    const { t } = useTranslation();

    const settings = {
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayButton: false,
        autoplayButtonOutput: false,
        controls: false,
        lazyload: true,
        nav: false,
        mouseDrag: true,
        items: 1,
    };

    const initiateChat = async (producerId) => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/cams/private/initiate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({
                "producerId": producerId
            })
        })
        return response.json();
    }

    const doChat = (producerId) => {
        initiateChat(producerId).then(data => {
            console.log(data);
        })
    }

    useEffect(() => {
        const getPrivateCam = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/cams/private/' + camid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (!response.ok) {
                throw new Error('Unable to load chat room!');
            }
            const responseData = await response.json();
            setCamData(responseData);
            setIsLoading(false);
        }

        getPrivateCam().catch(error => {
            setIsLoading(false);
        });

        }, [camid]);


    return (
        <div>
            <div className="grid grid-cols-3">
                <div className="col-span-1">
                    {!isLoading &&
                    <TinySlider settings={settings}>
                        {camData?.profileimages.map((pImage, index) => (
                            <div key={index} style={{ position: "relative" }}>
                                <img
                                    className={`tns-lazy-img w-full rounded-md shadow-xl`}
                                    src={pImage.imageurl}
                                    data-src={pImage.imageurl}
                                    alt={camData.pseudo}
                                />
                            </div>
                        ))}
                    </TinySlider>
                    }
                    <div className="w-full mt-5">
                        <div>
                            <button type="button"
                                    className="w-full px-4 py-3 text-center text-2xl font-light bg-fundo-gold text-fundo-gray-dark shadow-xl"
                                    onClick={() => doChat(camData.id)}
                            >
                                Start Chat
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 px-5 pt-5">
                    <div className="  w-full  items-center">
                        <p className=" focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 font-normal text-base leading-4 text-gray-600">/ <NavLink to="/cams/private" className="text-fundo-purple">Private</NavLink> / <NavLink to="/cams/private" className="text-fundo-purple">{t('cams.private_categories.' + camData?.category)}</NavLink> / {camData?.pseudo}</p>
                        <h2 className="font-semibold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 mt-4">{ camData?.pseudo }</h2>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0 w-full mt-3">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{t('cams.profile.name')}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{camData?.pseudo}</dd>
                                </div>
                                {camData?.age &&
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">{t('cams.profile.age')}</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{camData?.age} {camData?.birthday.length > 0 ? `(${camData?.birthday})` : ''}</dd>
                                </div>
                                }
                                {camData?.bodyheight &&
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">{t('cams.profile.bodyheight')}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{camData?.bodyheight}</dd>
                                    </div>
                                }
                                {camData?.eyes &&
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">{t('cams.profile.eyes')}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{camData?.eyes}</dd>
                                    </div>
                                }
                                {camData?.cupsize &&
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">{t('cams.profile.cupsize')}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{camData?.cupsize}</dd>
                                    </div>
                                }
                                {camData?.haircolor &&
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">{t('cams.profile.haircolor')}</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{camData?.haircolor}</dd>
                                    </div>
                                }
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default PrivateCamDetail;
import FundoLayout from "../../../layout/FundoLayout";
import {useTranslation} from "react-i18next";
import {CountryDropdown} from "react-country-region-selector";
import {useDispatch, useSelector} from "react-redux";
import {openBuyCoins} from "../../../store/base-slice";
import UserAccount from "./components/UserAccount";
import UserChangePassword from "./components/UserChangePassword";
import UserSubscriptions from "./components/UserSubscriptions";

const Profile = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { firstname, lastname, email, username, country } = useSelector(state => state.auth.user );

    return (
        <FundoLayout mainBackgroundColor="bg-gray-200">
            <div className="space-y-6">
                <UserAccount />
                <UserChangePassword />
                <UserSubscriptions />

                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profile.coins.title')}</h3>
                            <p className="mt-1 text-sm text-gray-500">{t('profile.coins.description')}</p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <button type="button"
                                    onClick={() => dispatch(openBuyCoins())}
                                    className="block bg-fundo-purple text-white text-xl font-medium rounded-md px-3 py-3"
                            >
                                {t('coins.buy')}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profile.transactions.title')}</h3>
                            <p className="mt-1 text-sm text-gray-500">{t('profile.transactions.description')}</p>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                        </div>
                    </div>
                </div>




            </div>
        </FundoLayout>
    )
}
export default Profile;

import axios from "axios";
import {loginUser, setUser, clearUser, logoutUser} from "./user";
import {closeLogin} from "../../store/base-slice";

export const authLoginUser = (username, password) => {
    return async (dispatch)  => {
        const doLogin = async () => {
            const loginResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/auth/login',
                {
                    username,
                    password
                });

            if (loginResponse.status !== 200) {
                throw new Error('login_failed');
            }

            const data = await loginResponse.data;
            return data;
        }

        try {
            const loginData = await doLogin(username, password);
            console.log('loginUser success:', loginData);
            dispatch(loginUser({
                authData: loginData
            }));
            dispatch(setUser({
                authData: loginData.data
            }));
            dispatch(closeLogin());
            return {
                status: 'success',
                message: 'login.success'
            };

        } catch (e) {
            dispatch(clearUser());
            console.error('loginUser failed: ', e.message);
            return {
                status: 'failed',
                message: 'login.failed'
            };
        }
    }
}

export const authRegisterUser = (registrationData) => {
    let registrationDataReponse;
    return async (dispatch) => {
        const doRegister = async () => {
            const registrationResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/auth/register',
                {
                    firstname: registrationData.firstname,
                    lastname: registrationData.lastname,
                    email: registrationData.email,
                    username: registrationData.username,
                    password: registrationData.password,
                    password_confirmation: registrationData.password,
                    country: registrationData.country,
                    birthdate: registrationData.birthdate,
                    hostname: window.location.hostname
                });

            if (registrationResponse.status !== 200) {
                throw new Error('Registration failed')
            }

            registrationDataReponse = await registrationResponse.data;
            return registrationDataReponse;
        }
        try {
            registrationDataReponse = await doRegister(registrationData);
            console.log('authRegisterUser success:', registrationDataReponse);
            dispatch(loginUser({
                authData: registrationDataReponse
            }));
            dispatch(setUser({
                authData: registrationDataReponse
            }));
            return {
                "status":   "success"
            }
        } catch (e) {
            console.error('authRegisterUser failed: ', e.message, e.response);
            return {
                "status": "error",
                "data": e.response.data
            }
        }
    }
}

export const verifyUser = (token) => {
    let verifyData;
    return async (dispatch) => {
        const  doVerifyUser = async () => {
            const verifyResponse = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/user/verify',
                {
                    username: 'test'
                },
                {
                    'headers': {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            )

            if (verifyResponse.status !== 200 ) {
                throw new Error('No Access');
            }

            return await verifyResponse;
        }

        try {
            verifyData = await doVerifyUser();
            dispatch(setUser({
                authData: verifyData.data
            }));
        } catch (e) {
            console.log('verifyUser failed:' + e.message, e.response);
            dispatch(logoutUser());
            return {
                "status": "error",
                "data": e.response
            }
        }
    }
}

/* This example requires Tailwind CSS v2.0+ */
import {Fragment, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {Link} from "react-router-dom";
import FundoLogoPlain from "../../core/ui/svg/FundoLogoPlain";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {closeLogin, openSignup} from "../../store/base-slice";
import { authLoginUser } from '../store/auth-actions'
import {XCircleIcon} from "@heroicons/react/outline";

const LoginModal = (props) => {
    //const showLogin = useSelector(state => state.auth.login.showLogin);
    const { showLogin } = useSelector(state => state.base);
    const [modalContext, setModalContext] = useState('login');
    // const [loading, setLoading] = useState(false);
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    const forgotNameRef = useRef(null);
    const forgotEmailRef = useRef(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [loginFailed, setLoginFailed] = useState(false);
    const dispatch = useDispatch();
    const {t } = useTranslation();

    // useEffect(() => {
    //     console.log('effect: ', success, error)
    // },[success, error]);
    //

    const closeModal = () => {
        dispatch(closeLogin());
    }

    const showSignup = () => {
        dispatch(closeLogin());
        dispatch(openSignup());
    }

    const handleSignuo = (e) => {
        e.preventDefault();
        setLoginFailed(false);
        setErrorMessage(null);
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const result = dispatch(authLoginUser(username, password));
        result.then((data) => {
            if (data.status === 'failed') {
                setLoginFailed(true);
                setErrorMessage(data.message);
            }
        });
    };

    const handleForgot = () => {
        // setLoginFailed(false);
        // setErrorMessage(null);
        // const username = usernameRef.current.value;
        // const password = passwordRef.current.value;
        // const result = dispatch(authLoginUser(username, password));
        // result.then((data) => {
        //     if (data.status === 'failed') {
        //         setLoginFailed(true);
        //         setErrorMessage(data.message);
        //     }
        // });
    };

    return (
        <Transition.Root show={showLogin} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
                            <div className="flex p-3">
                                <div className='hidden md:w-5/12 bg-fundo-purple rounded-tl-md rounded-bl-md md:flex flex-wrap content-between'>
                                    <div></div>
                                    <div></div>
                                    <div className='px-5 pb-4'>
                                        <FundoLogoPlain className='w-full'/>
                                    </div>
                                </div>
                                {modalContext === 'forgot' &&
                                <div className='w-full md:w-7/12 px-10 pb-4 pt-10 bg-fundo-gray-light rounded-tr-md rounded-br-md'>
                                    {/*Forgot Password*/}
                                    <div className='text-2xl font-bold text-center mb-10' dangerouslySetInnerHTML={{__html: t('auth.texts.forgot_header')}} />
                                    <form>
                                        <div className='mb-5'>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    ref={forgotNameRef}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder={t('auth.username')}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-5'>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    ref={forgotEmailRef}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder={t('auth.email')}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-16">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-fundo-blue text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={handleForgot}
                                            >
                                                {t('auth.forgot_button')}
                                            </button>
                                        </div>
                                        <div className='text-center pt-3'>
                                            <div className='text-xs'>
                                                {t('auth.texts.goto_login')} <span onClick={() => setModalContext('login')} className='text-fundo-blue cursor-pointer'>{t('auth.texts.click_here')}</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                }
                                {(modalContext === 'login') &&
                                <div className='w-full md:w-7/12 px-10 pb-4 pt-10 bg-fundo-gray-light rounded-tr-md rounded-br-md'>
                                    <div className='text-2xl font-bold text-center mb-10' dangerouslySetInnerHTML={{__html: t('auth.texts.login_header')}} />
                                    <form onSubmit={handleSignuo}>
                                        <div className='mb-5'>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    ref={usernameRef}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder={t('auth.username')}
                                                />
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div className="mt-1">
                                                <input
                                                    type="password"
                                                    ref={passwordRef}
                                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                                                    placeholder={t('auth.password')}
                                                />
                                            </div>
                                        </div>
                                        {loginFailed &&
                                        <div className="mt-3">
                                            <div className="rounded-md bg-red-50 p-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-red-800">{t(errorMessage)}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <div className="mt-5 sm:mt-16">
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-fundo-blue text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                            >
                                                {t('auth.login')}
                                            </button>
                                        </div>
                                        <div className='text-center pt-2'>
                                            <div className='text-xs'>
                                                {t('auth.texts.forgot_password')} <span onClick={() => setModalContext('forgot')} className='text-fundo-blue cursor-pointer'>{t('auth.texts.click_here')}</span>
                                            </div>
                                            <div className='pt-5'>
                                                {t('auth.texts.login_noprofile')}<br/>
                                                <span onClick={() => showSignup()} className='cursor-pointer text-fundo-blue'>{t('auth.texts.login_tryout')}</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                }
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default LoginModal;

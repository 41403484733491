import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import TinySlider from "tiny-slider-react";
import Countdown, {zeroPad} from 'react-countdown';
import moment from "moment";
import {useDispatch} from "react-redux";
import {openSignup} from "../../../store/base-slice";

const NextStarShow = ( props ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [schedules, setSchedules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()
    const { fsk } = props;
    let tinySlider = null;

    useEffect(() => {
        const fetchVideos = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/starshow/schedule/commingup/3');

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setSchedules(responseData);
            setIsLoading(false);
        }
        fetchVideos().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    }, []);

    const sliderSettings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        controls: false,
        autoPlay:false
    };

    const onGoTo = dir => tinySlider.slider.goTo(dir)

    const onProfileLinkClicked = (path) => {
        if (fsk) {
            dispatch(openSignup())
        } else {
            navigate(path)
        }
    }

    const countDownRenderer = ({days, hours, minutes, seconds, completed }) => {
        return (
            <div className="grid grid-cols-2 gap-x-5 gap-y-2 px-5">
                <div className="text-5xl font-bold flex justify-end items-center text-fundo-purple">
                    <div>
                        <div className="text-center">{zeroPad(days)}</div>
                        <div className="text-center font-light text-fundo-purple text-xs">{t('days')}</div>
                    </div>
                </div>
                <div className="text-5xl font-bold flex justify-start items-center text-fundo-purple">
                    <div>
                        <div className="text-center">{zeroPad(hours)}</div>
                        <div className="text-center font-light text-fundo-purple text-xs">{t('hours')}</div>
                    </div>
                </div>
                <div className="text-5xl font-bold flex justify-end text-fundo-purple">
                    <div>
                        <div className="text-center">{zeroPad(minutes)}</div>
                        <div className="text-center font-light text-fundo-purple text-xs">{t('minutes')}</div>
                    </div>
                </div>
                <div className="text-5xl font-bold flex justify-start items-center text-fundo-purple">
                    <div>
                        <div className="text-center">{zeroPad(seconds)}</div>
                        <div className="text-center font-light text-fundo-purple text-xs">{t('seconds')}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full h-full">
            <div className="w-full h-full rounded-md shadow-xl">
                <div className="bg-fundo-purple flex flex-wrap p-3 rounded-t-md">
                    <div className="text-right w-1/2 py-2 flex justify-end">
                        <img src="/img/fundo.png" alt="Fundo" className="m-auto"/>
                    </div>
                    <div className="text-left text-2xl font-light w-1/2 text-white">
                        StarShows
                    </div>
                </div>
                <div>
                    {schedules &&
                    <TinySlider settings={sliderSettings} ref={ts => tinySlider = ts}>
                        {schedules.map((schedule, index) => (
                            <div className="py-3" key={index}>
                                <div className="grid grid-cols-6 gap-1">
                                    <div className="col-span-1 flex items-center">
                                        <ChevronLeftIcon className="h-12 text-gray-300 hover:text-gray-500"
                                                         onClick={() => tinySlider.onGoTo('prev')}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <div className="aspect-w-1 aspect-h-1">
                                            <img src={`https://api.fundo.work/storage${schedule.image}`} alt="" className="w-full h-full object-top object-cover lg:w-full lg:h-full rounded-full border-4 border-fundo-purple"/>
                                        </div>
                                    </div>
                                    <div className="col-span-1  flex items-center">
                                        <ChevronRightIcon className="h-12 text-gray-300 hover:text-gray-500"
                                                          onClick={() => onGoTo('next')}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <span className="text-fundo-purple font-medium text-lg">
                                        {schedule.performerName}
                                    </span>
                                </div>
                                <div className="flex justify-center">
                                    <span className="text-gray-500 font-light text-md">
                                        {schedule.startDate} - {schedule.endTime}
                                    </span>
                                </div>
                                <div>
                                    <Countdown
                                        date={Date.parse(schedule.dateShiftStart)}
                                        renderer={countDownRenderer}
                                        autoStart={true}
                                    />
                                </div>
                                <div className="mt-2 text-center">
                                    <span className="text-lg font-bold text-gray-600">
                                        {t('cams.until_next_starshow')}
                                    </span>
                                </div>
                                <div className="flex justify-center mt-2">
                                    <button className="px-4 py-1 rounded-md text-md font-normal text-white bg-fundo-purple hover:bg-fundo-purple"
                                            onClick={() => onProfileLinkClicked('/cams/live/' + schedule.slug)}
                                    >
                                        {t('cams.gotoprofile')}
                                    </button>
                                </div>
                            </div>

                        ))}
                    </TinySlider>
                    }
                </div>


            </div>
        </div>
    )
}
export default NextStarShow;
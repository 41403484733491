const faqData = [
    {
        "id": 1,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "Contact",
            "de": "Kontakt"
        },
        "faqs": [
            {
                "id": 1,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 1,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Read this first! You can take care of almost everything yourself. ",
                    "de": "Lesen Sie dies zuerst! Sie können sich um fast alles selbst kümmern. "
                },
                "answer": {
                    "en": "You can arrange almost everything yourself on our website, cancel, change your personal information and payment information. Log in to the website and view your account. There you will find all your data that you can change. Quick, easy and directly arranged. ",
                    "de": "Auf unserer Website können Sie fast alles selbst regeln, stornieren, Ihre persönlichen Daten und Zahlungsinformationen ändern. Loggen Sie sich auf der Website ein und sehen Sie sich Ihr Konto an. Dort finden Sie alle Ihre Daten, die Sie ändern können. Schnell, einfach und direkt erledigt. "
                }
            },
            {
                "id": 2,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 1,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How can I reach you by e-mail?",
                    "de": "Wie kann ich euch per E-Mail erreichen?"
                },
                "answer": {
                    "en": "You can always contact us by email at service@fundorado.de",
                    "de": "Du kannst uns jederzeit per E-Mail unter der E-Mail-Adresse service@fundorado.de erreichen."
                }
            }
        ]
    },
    {
        "id": 2,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "Access Data",
            "de": "Zugangdaten"
        },
        "faqs": [
            {
                "id": 3,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 2,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How do I get a new password if I forgot my old one?",
                    "de": "Wie bekomme ich ein neues Passwort, wenn ich mein altes vergessen habe?"
                },
                "answer": {
                    "en": "You can always request a link here to reset your password. If you also forget your username, or no longer have access to your email address, please contact the FunDorado Service Team.",
                    "de": "Du kannst dir jederzeit hier einen Link anfordern, unter dem du dein Passwort neu setzen kannst.\n\n\nSolltest du zusätzlich auch deinen Benutzernamen vergessen, oder keinen Zugriff mehr auf deine E-Mail-Adresse haben, wende dich bitte an das FunDorado Service-Team."
                }
            },
            {
                "id": 4,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 2,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How do I find out my username if I have forgotten it?",
                    "de": "Wie erfahre ich meinen Benutzernamen, wenn ich ihn vergessen habe?"
                },
                "answer": {
                    "en": "If you have forgotten your username, please contact the FunDorado Service Team directly.",
                    "de": "Wenn du deinen Benutzernamen vergessen hast, wende dich bitte direkt an das FunDorado Service-Team."
                }
            },
            {
                "id": 5,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 2,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How can I change my username?",
                    "de": "Wie kann ich meinen Benutzernamen ändern?"
                },
                "answer": {
                    "en": "Since your username is permanently linked to your membership, you cannot change it.",
                    "de": "Da dein Benutzername fest mit deiner Mitgliedschaft verknüpft ist, kannst du den Benutzernamen leider nicht ändern."
                }
            }
        ]
    },
    {
        "id": 3,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "Prices and packages",
            "de": "Preise und Pakete"
        },
        "faqs": [
            {
                "id": 6,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Why is there no free subscription anymore?",
                    "de": "Warum gibt es kein kostenloses Abo mehr?"
                },
                "answer": {
                    "en": "We decided to stop with the free trial membership. Many members didn't realize that the free plan would continue into a paid subscription. From now on you can test us for 14 days for 4,99 €. Within these 14 days you can cancel your subscription at any time. There is no cancellation period anymore.",
                    "de": "Wir haben uns gegen eine kostenlose Probe-Mitgliedschaft entschieden. Vielen Mitgliedern war nicht klar, dass der kostenlose Tarif in einem kostenpflichtigen Abonnement weitergeführt wird. Ab sofort kannst du uns 14 Tage lang für 4,99 € testen. Innerhalb dieser 14 Tage kannst du dein Abo jederzeit beenden. Eine Kündigungsfrist gibt es nicht mehr."
                }
            },
            {
                "id": 7,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What does the trial rate cost and what do I get for it?",
                    "de": "Was kostet der Schnupper-Tarif und was bekomme ich dafür?"
                },
                "answer": {
                    "en": "The contract period in the trial tariff is 14 days from the date of registration. It is automatically extended by another month if the membership is not canceled. For the monthly fee of only 24,95 EUR you get full access to over 40 live cams, our large online video library with 125.000 movies, the departments private and pictures and very exclusively (only for FunDorado members) also access to our sexy community!",
                    "de": "Die Vertragslaufzeit beträgt im Schnupper-Tarif 14 Tage ab dem Datum der Anmeldung. Sie verlängert sich automatisch um jeweils mit einen weiteren Monat, wenn die Mitgliedschaft nicht gekündigt wird. Für den Monatsbeitrag i.H.v. nur 24,95 EUR* bekommst du vollen Zugriff auf über 40 Live Cams, unsere große Online-Videothek mit 125.000 Filmen, die Ressorts Privat und Bilder sowie ganz exklusiv (nur für FunDorado-Mitglieder) auch Zugriff auf unsere sexy Community!"
                }
            },
            {
                "id": 8,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What does the basic rate cost and what do I get for it?",
                    "de": "Was kostet der Basis-Tarif und was bekomme ich dafür?"
                },
                "answer": {
                    "en": "The contract period in the basic tariff is 31 days from the date of registration. It is automatically extended by another month if the membership is not canceled. For the monthly fee of only 24.95 EUR you get full access to over 40 live cams, our large online video library with 125,000 movies, the private and picture sections, as well as exclusive (only for FunDorado members) access to our sexy community!",
                    "de": "Die Vertragslaufzeit beträgt im Basis-Tarif 31 Tage ab dem Datum der Anmeldung. Sie verlängert sich automatisch um jeweils mit einen weiteren Monat, wenn die Mitgliedschaft nicht gekündigt wird. Für den Monatsbeitrag i.H.v. nur 24,95 EUR* bekommst du vollen Zugriff auf über 40 Live Cams, unsere große Online-Videothek mit 125.000 Filmen, die Ressorts Privat und Bilder, sowie ganz exklusiv (nur für FunDorado-Mitglieder) auch Zugriff auf unsere sexy Community!"
                }
            },
            {
                "id": 9,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How much is the Silver plan and what do I get for it?",
                    "de": "Was kostet der Silber-Tarif und was bekomme ich dafür?"
                },
                "answer": {
                    "en": "The contract period in the silver tariff is 6 months (183 days) from the date of registration. It is automatically renewed for another 6 months if the membership is not cancelled. For your total membership fee of only 119,70 EUR you get full access to over 40 live cams, our large online video library with 125.000 movies, the private and picture sections, as well as exclusive (only for FunDorado members) access to our sexy community!",
                    "de": "Die Vertragslaufzeit beträgt im Silber-Tarif 6 Monate (183 Tage) ab dem Datum der Anmeldung. Sie verlängert sich automatisch um jeweils weitere 6 Monate, wenn die Mitgliedschaft nicht gekündigt wird. Für deinen Gesamt-Mitgliedsbeitrag i.H.v. nur 119,70 EUR* bekommst du vollen Zugriff auf über 40 Live Cams, unsere große Online-Videothek mit 125.000 Filmen, die Ressorts Privat und Bilder, sowie ganz exklusiv (nur für FunDorado-Mitglieder) auch Zugriff auf unsere sexy Community!"
                }
            },
            {
                "id": 10,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What does the gold tariff cost and what do I get for it?",
                    "de": "Was kostet der Gold-Tarif und was bekomme ich dafür?"
                },
                "answer": {
                    "en": "The contract period in the Gold tariff is 12 months (365 days) from the date of registration. It is automatically renewed for another year if the membership is not cancelled. For your total membership fee of only 179,40 EUR you get full access to over 40 live cams, our large online video library with 125.000 movies, the private and picture sections, as well as exclusive (only for FunDorado members) access to our sexy community!",
                    "de": "Die Vertragslaufzeit beträgt im Gold-Tarif 12 Monate (365 Tage) ab dem Datum der Anmeldung. Sie verlängert sich automatisch um jeweils ein weiteres Jahr, wenn die Mitgliedschaft nicht gekündigt wird. Für deinen Gesamt-Mitgliedsbeitrag i.H.v. nur 179,40 EUR* bekommst du vollen Zugriff auf über 40 Live Cams, unsere große Online-Videothek mit 125.000 Filmen, die Ressorts Privat und Bilder, sowie ganz exklusiv (nur für FunDorado-Mitglieder) auch Zugriff auf unsere sexy Community!"
                }
            },
            {
                "id": 11,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What does the Platinum tariff cost and what do I get for it?",
                    "de": "Was kostet der Platin-Tarif und was bekomme ich dafür?"
                },
                "answer": {
                    "en": "The contract period in the Platinum tariff is 24 months (720 days) from the date of registration. It is automatically renewed for two additional years if the membership is not cancelled. For the total membership fee of only 239.80 EUR* you get full access to over 40 live cams, our large online video library with 125,000 movies, the private and picture sections, as well as exclusive (only for FunDorado members) access to our sexy community!",
                    "de": "Die Vertragslaufzeit beträgt im Platin-Tarif 24 Monate (720 Tage) ab dem Datum der Anmeldung. Sie verlängert sich automatisch um jeweils zwei weitere Jahre, wenn die Mitgliedschaft nicht gekündigt wird. Für den Gesamt-Mitgliedsbeitrag i.H.v. nur 239,80 EUR* bekommst du vollen Zugriff auf über 40 Live Cams, unsere große Online-Videothek mit 125.000 Filmen, die Ressorts Privat und Bilder, sowie ganz exklusiv (nur für FunDorado-Mitglieder) auch Zugriff auf unsere sexy Community!"
                }
            },
            {
                "id": 12,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What are Private Cams?",
                    "de": "Was sind Privat Cams?"
                },
                "answer": {
                    "en": "In den Privat Cams kannst du ganz allein mit deinem Lieblingsgirl chatten. Wenn du möchtest, kannst du dabei auch das Bild deiner Webcam an das Girl übertragen. Zur Nutzung der Privatcams kannst du Guthaben in Form sog. Coins aufladen und dann verbrauchen. Eine Flatrate für Privatcams können wir leider nicht anbieten.",
                    "de": "In den Privat Cams kannst du ganz allein mit deinem Lieblingsgirl chatten. Wenn du möchtest, kannst du dabei auch das Bild deiner Webcam an das Girl übertragen. Zur Nutzung der Privatcams kannst du Guthaben in Form sog. Coins aufladen und dann verbrauchen. Eine Flatrate für Privatcams können wir leider nicht anbieten."
                }
            },
            {
                "id": 13,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 3,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What are Live Cams?",
                    "de": "Was sind Live Cams?"
                },
                "answer": {
                    "en": "In the live cams you can not only watch the girls or couples, but also chat with them (or other members) or transmit the image of your own webcam. Of course, only if and to whom you want! All memberships already include over 40 Live Cams.",
                    "de": "In den Live Cams kannst du den Girls oder Paaren nicht nur zuschauen, sondern auch mit ihnen (oder anderen Mitgliedern) chatten oder das Bild deiner eigenen Webcam übertragen. Natürlich nur, wenn und an wen du möchtest! In allen Mitgliedschaften sind bereits über 40 Live Cams enthalten."
                }
            }
        ]
    },
    {
        "id": 4,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "Registration",
            "de": "Anmeldung"
        },
        "faqs": [
            {
                "id": 14,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 4,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Can I register without an email address?",
                    "de": "Kann ich mich auch ohne E-Mail-Adresse anmelden?"
                },
                "answer": {
                    "en": "Since we will send you your personal password by e-mail after registration, it is necessary to enter your e-mail address in any case. If you don't have an e-mail address yet, you can easily set one up for free at freenet.de, for example.",
                    "de": "Da wir dir dein persönliches Passwort nach der Anmeldung per E-Mail zusenden, ist die Angabe deiner E-Mail-Adresse auf jeden Fall notwendig. Wenn du noch keine E-Mail-Adresse hast, kannst du dir z.B. bei freenet.de ganz einfach und kostenlos eine einrichten."
                }
            },
            {
                "id": 15,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 4,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How long does it take to receive my password?",
                    "de": "Wie lange dauert es, bis ich mein Passwort erhalte?"
                },
                "answer": {
                    "en": "As soon as you have successfully completed your registration, we will send your personal password to your e-mail address within a few minutes. If you don't find our e-mail in your inbox, please check your spam filter.",
                    "de": "Sobald du deine Anmeldung erfolgreich abgeschlossen hast, senden wir dir dein persönliches Passwort innerhalb weniger Minuten an deine E-Mail-Adresse zu. Solltest du unsere E-Mail nicht in deinem Posteingang finden, schau bitte auch einmal in deinem Spamfilter nach."
                }
            },
            {
                "id": 16,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 4,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "I don't have an account or a credit card, can I still register?",
                    "de": "Ich habe kein Konto und keine Kreditkarte, kann ich mich trotzdem anmelden?"
                },
                "answer": {
                    "en": "Unfortunately, you cannot register without providing your bank details or credit card. Of course, we only charge the fees for the services you have ordered! Furthermore, Jestoro GmbH is a German company based in Hamburg. Naturally, we therefore adhere to the strict German data protection conditions.",
                    "de": "Ohne die Angabe deiner Bankverbindung oder Kreditkarte kannst du dich leider nicht anmelden. Wir buchen natürlich nur die Beiträge für die von dir bestellten Leistungen ab! Darüber hinaus ist die Jestoro GmbH ein deutsches Unternehmen mit Sitz in Hamburg. Selbstversändlich richten wir uns daher nach den strengen, deutschen Datenschutzbedingungen."
                }
            },
            {
                "id": 17,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 4,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "I did not receive my password after registering. What can I do?",
                    "de": "Ich habe nach der Anmeldung mein Passwort nicht erhalten. Was kann ich tun?"
                },
                "answer": {
                    "en": "Please make sure that our e-mail has not accidentally landed in the spam filter of your mailbox. If you still can't find the message there, or if you entered your email address incorrectly when registering, please contact the FunDorado service team.",
                    "de": "Schau bitte unbedingt einmal nach, ob unsere E-Mail nicht versehentlich im Spamfilter deines Postfachs gelandet ist. Solltest du die Nachricht auch dort nicht finden, oder bei der Anmeldung deine E-Mail-Adresse fehlerhaft angegeben haben, wende dich bitte an das FunDorado Service-Team."
                }
            }
        ]
    },
    {
        "id": 5,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "My membership",
            "de": "Meine Mitgliedschaft"
        },
        "faqs": [
            {
                "id": 18,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What is included in my membership?",
                    "de": "Was ist in meiner Mitgliedschaft enthalten?"
                },
                "answer": {
                    "en": "All members have UNLIMITED access to FunDorado Live Cams, exclusive casting content, 100,000 XXX clips, over 25,000 full length movies and from 2022 our new community.",
                    "de": "Alle Mitglieder haben UNBEGRENZTEN Zugang zu den FunDorado Live Cams, exklusiven Casting-Inhalten, 100.000 XXX-Clips, über 25.000 Filmen in voller Länge und ab 2022 unserer neuen Community."
                }
            },
            {
                "id": 19,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Will I have access to additional products?",
                    "de": "Erhalte ich Zugriff auf weitere Produkte?"
                },
                "answer": {
                    "en": "Of course! With your membership you not only get access to FunDorado, but also to the BDSM portal SaDorado and 35 other erotic websites! Fundorado is by far the biggest video website in Europe!",
                    "de": "Natürlich! Mit deiner Mitgliedschaft erhältst du nicht nur Zugang zu FunDorado, sondern auch zum BDSM-Portal SaDorado und 35 weiteren Erotik-Websites! Fundorado ist die mit Abstand größte Video-Website in Europa!"
                }
            },
            {
                "id": 20,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Where can I cancel my membership?",
                    "de": "Wo kann ich meine Mitgliedschaft kündigen?"
                },
                "answer": {
                    "en": "There is no cancellation period with us. You can cancel your membership until the last day of your contract period by logging in with your access data and terminating the current membership with a click. Easy and fast without any hassle.",
                    "de": "Bei uns gibt es keine Kündigungsfrist. Du kannst deine Mitgliedschaft bis zum letzten Tag deiner Vertragslaufzeit kündigen, indem du dich mit deinen Zugangsdaten einloggst und die laufende Mitgliedschaft per Klick beendest. Einfach und schnell ohne Probleme."
                }
            },
            {
                "id": 21,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Where can I update my bank account information?",
                    "de": "Wo kann ich meine Bankverbindung aktualisieren?"
                },
                "answer": {
                    "en": "If you want to update your bank details, please log in with your access data and then click on \"Options\" in the upper right corner under \"Your FunDorado\".",
                    "de": "Wenn du deine Bankverbindung aktualisieren möchtest, logge dich bitte mit deinen Zugangsdaten ein und klicken dann oben rechts unter \"Dein FunDorado\" auf den Punkt \"Optionen\"."
                }
            },
            {
                "id": 22,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Where can I update my credit card information?",
                    "de": "Wo kann ich meine Kreditkartendaten aktualisieren?"
                },
                "answer": {
                    "en": "If you want to update your credit card details, please log in with your access data and click on \"Options\" in the upper right corner under \"Your FunDorado\".",
                    "de": "Wenn du Kreditkartendaten aktualisieren möchtest, logge dich bitte mit deinen Zugangsdaten ein und klicken dann oben rechts unter \"Dein FunDorado\" auf den Punkt \"Optionen\"."
                }
            },
            {
                "id": 23,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How can I change my address?",
                    "de": "Wie kann ich meine Adresse ändern?"
                },
                "answer": {
                    "en": "If you want to add a new e-mail address, please log in with your access data and click on \"Options\" in the upper right corner of \"Your FunDorado\".",
                    "de": "Wenn du eine neue E-Mail-Adresse hinterlegen möchtest, logge dich bitte mit deinen Zugangsdaten ein und klicken dann oben rechts unter \"Dein FunDorado\" auf den Punkt \"Optionen\"."
                }
            },
            {
                "id": 24,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 5,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How can I update my email address?",
                    "de": "Wie kann ich meine E-Mail-Adresse aktualisieren?"
                },
                "answer": {
                    "en": "If you want to enter a new e-mail address, please log in with your access data and click on \"Options\" in the upper right corner under \"Your FunDorado\".",
                    "de": "Wenn du eine neue E-Mail-Adresse hinterlegen möchtest, logge dich bitte mit deinen Zugangsdaten ein und klicken dann oben rechts unter \"Dein FunDorado\" auf den Punkt \"Optionen\"."
                }
            }
        ]
    },
    {
        "id": 6,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "Technical",
            "de": "Technisches"
        },
        "faqs": [
            {
                "id": 25,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 6,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "What do I need to use FunDorado?",
                    "de": "Was brauche ich, um FunDorado nutzen zu können?"
                },
                "answer": {
                    "en": "You can use the FunDorado offer optimally with any standard PC or notebook. It is also possible to use it with your tablet or smartphone. For your visit to FunDorado we recommend the use of a modern browser, such as Google Chrome, Mozilla Firefox or Microsoft Edge. Your internet connection should have a stable transfer speed of at least 2 MBit/sec. so that you can view all content in the best possible quality. You can check the actual speed of your connection here, for example. Of course, you can also use FunDorado with a slower internet connection, for example via 3G or LTE mobile. However, the transmission quality of the live cams and videos may be somewhat limited.",
                    "de": "Das Angebot von FunDorado kannst du optimal mit jedem handelsüblichen PC oder Notebook nutzen. Auch die Nutzung mit deinem Tablet oder Smartphone ist möglich. Für deinen Besuch auf FunDorado empfehlen wir dir die Verwendung eines modernen Browsers, wie z.B. Google Chrome, Mozilla Firefox oder dem Microsoft Edge. Deine Internetverbindung sollte eine stabile Übertragungsgeschwindigkeit von mindestens 2 MBit/sek. haben, damit du alle Inhalt in bestmöglicher Qualität ansehen kannst. Die tatsächliche Geschwindigkeit deiner Verbindung kannst du zum Beispiel hier überprüfen. Natürlich kannst du FunDorado auch mit einer langsameren Internetverbindung, zum Beispiel per 3G oder LTE Mobilfunk, nutzen. Dabei ist die Übertragungsqualität der Live Cams und Videos jedoch möglicherweise etwas eingeschränkt."
                }
            },
            {
                "id": 26,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 6,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Can I watch the videos and live cams on my TV?",
                    "de": "Kann ich die Videos und Live Cams auch auf meinem Fernseher ansehen?"
                },
                "answer": {
                    "en": "The easiest way to watch the movies and videos on your TV is to connect your PC or laptop directly with a standard HDMI, DVI or VGA cable. Which option is right for you depends on the technical equipment of your PC and your TV. Whether you can use FunDorado with the browser of your Smart TV, we can not tell you in general. It always depends on the software used there. Just give it a try.",
                    "de": "Am einfachsten kannst du die Filme und Videos auf deinem Fernseher ansehen, wenn du deinen PC oder Laptop direkt mit einem handelsüblichen HDMI, DVI oder VGA Kabel dort anschließt. Welche Möglichkeit für dich in Frage kommt, ist abhängig von der technischen Ausstattung deines PCs und deines Fernsehers. Ob du FunDorado mit dem Browser deines Smart TV nutzen kannst, können wir dir pauschal leider nicht sagen. Das kommt immer auf die dort verwendete Software an. Probiere es doch einfach mal aus."
                }
            },
            {
                "id": 27,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 6,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Can I use FunDorado on my tablet or smartphone?",
                    "de": "Kann ich FunDorado auch auf meinem Tablet oder Smartphone nutzen?"
                },
                "answer": {
                    "en": "Even if you use a tablet or smartphone, you can log in to FunDorado as usual. All video content and most live cams are also available there.",
                    "de": "Auch wenn du ein Tablet oder Smartphone verwendest, kannst du dich wie gewohnt bei FunDorado einloggen. Alle Videoinhalte und die meisten Live Cams stehen dir auch dort zur Verfügung."
                }
            }
        ]
    },
    {
        "id": 7,
        "created_at": "2021-12-11T22:08:42.000000Z",
        "updated_at": "2021-12-11T22:08:42.000000Z",
        "sort_order": 0,
        "title": {
            "en": "About Fundorado",
            "de": "Uber Fundorado"
        },
        "faqs": [
            {
                "id": 28,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 7,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Where can I read the general terms and conditions of FunDorado?",
                    "de": "Wo kann ich die allgemeinen Geschäftsbedingungen von FunDorado lesen?"
                },
                "answer": {
                    "en": "The general terms and conditions (GTC) of FunDorado can be found here at any time.",
                    "de": "Die allgemeinen Geschäftsbedingungen (AGB) von FunDorado findest du jederzeit hier."
                }
            },
            {
                "id": 29,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 7,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "How secure is my data with FunDorado?",
                    "de": "Wie sicher sind meine Daten bei FunDorado"
                },
                "answer": {
                    "en": "Jestoro GmbH is a German company based in Hamburg. Therefore, we naturally comply with the (strict) German data protection regulations and also have a corresponding commitment to handle the data of our members responsibly and securely. For more details you can also have a look at the privacy policy of Jestoro GmbH. If you have any further questions regarding the security of your data, please do not hesitate to contact the FunDorado service team.",
                    "de": "Die Jestoro GmbH ist ein deutsches Unternehmen mit Sitz in Hamburg. Daher richten wir uns natürlich nach den (strengen) deutschen Datenschutzbestimmungen und haben darüber hinaus auch eine entsprechende Selbstverpflichtung, mit den Daten unserer Mitglieder verantwortungsvoll und sicher umzugehen. Für mehr Details kannst du dir auch die Datenschutzerklärung der Jestoro GmbH ansehen. Wenn du weitere Fragen zur Sicherheit deiner Daten hast, wende dich gern jederzeit an das FunDorado Service-Team."
                }
            },
            {
                "id": 30,
                "created_at": "2021-12-11T22:08:42.000000Z",
                "updated_at": "2021-12-11T22:08:42.000000Z",
                "category_id": 7,
                "status": "online",
                "sort_order": 0,
                "highlight": 0,
                "question": {
                    "en": "Who makes FunDorado anyway?",
                    "de": "Wer macht denn überhaupt FunDorado?"
                },
                "answer": {
                    "en": "If you would like to learn more about Jestoro GmbH, please take a look at our company page.",
                    "de": "Wenn du mehr über die Jestoro GmbH erfahren möchtest, schaue doch einfach mal auf unserer Firmenseite vorbei."
                }
            }
        ]
    }
]
export default faqData;
import * as React from "react"

function WebcamIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167 176" {...props}>
            <path d="M88.14 9c4.26.9 8.63 1.45 12.76 2.75 25.12 7.89 40.97 24.8 45.72 50.6 4.7 25.53-3.64 47.27-24.34 63.06-38.69 29.51-93.62 8.34-103.14-39.32-7.3-36.56 19.29-72.65 56.32-76.47.77-.08 1.52-.41 2.28-.62h10.4zm-5.46 104.06c21.64.12 39.39-17.43 39.42-38.98.03-21.42-17.3-38.91-38.9-39.25-21.33-.33-39.35 17.54-39.43 39.09-.07 21.39 17.45 39.03 38.91 39.14zM67.69 20.85c8.03 10.25 23.72 10.1 30.28-.06-10.08-2.85-19.98-2.85-30.28.06z" />
            <path d="M37.52 165c.01-2.54 0-5.08.02-7.62.04-4.14 1.14-5.27 5.4-5.31 6-.05 12.01-.01 18.01-.02.68 0 1.36-.09 2.45-.16.14-2.86.28-5.66.44-8.86 12.79 3.4 25.41 3.4 38.41-.06v9.09h18.96c6.51 0 7.13.62 7.14 7.05 0 1.96.01 3.93.02 5.89H37.52z" />
            <path
                d="M58.59 65.91c-4.13 11.77.87 24.58 11.82 30.72 10.9 6.11 24.98 3.3 32.77-6.54 7.85-9.91 7.42-24.05-1-33.39-7.8-8.64-21.42-11.21-30.08-5.68 4.25 5.51 4.55 9.86.98 14.04-3.58 4.19-8.3 4.5-14.49.85z"
                fill="#fff"
            />
            <path d="M58.59 65.91c6.19 3.66 10.91 3.34 14.49-.84s3.28-8.52-.98-14.04c8.66-5.53 22.28-2.96 30.08 5.68 8.42 9.33 8.85 23.47 1 33.39-7.79 9.84-21.87 12.65-32.77 6.54-10.95-6.15-15.95-18.97-11.82-30.73z" />
        </svg>
    )
}

export default WebcamIcon

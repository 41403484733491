/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import faqData from "../../data/faq-data";
import {useTranslation} from "react-i18next";
import {pickBestAvailableLanguage} from "../helpers/TranslationHelpers";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FaqAccordion = () => {
    const { i18n } = useTranslation();


    return (
        <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                {faqData.map((category, index) =>
                    <div key={`cat_${category.id}`} className="max-w-3xl mx-auto divide-y-2 divide-gray-200 mb-10">
                        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">{pickBestAvailableLanguage(category.title, i18n.language)}</h2>
                        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                            {category.faqs.map((faq) => (
                                <Disclosure key={`faq_${faq.id}`} as="div" className="pt-6">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">{pickBestAvailableLanguage(faq.question, i18n.language)}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                                                          <ChevronDownIcon
                                                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                              aria-hidden="true"
                                                          />
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{pickBestAvailableLanguage(faq.answer, i18n.language)}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                )}


            </div>
        </div>
    )
}
export default FaqAccordion;
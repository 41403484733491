const mobileNavigation = [
    {
        "title": "Home",
        "url": "/"
    },
    {
        "title": "Webcams",
        "url": "/cams"
    },
    {
        "title": "Videos",
        "url": "/videos"
    },
    {
        "title": "Channels",
        "url": "/channels"
    }
];
export default mobileNavigation;


export const pickBestAvailableLanguage = (translationVar, preferredLanguage) => {
    let response = null;
    const languages = [
        'en',
        'de',
        'nl',
        'fr',
        'es'
    ];
    if (translationVar[preferredLanguage] && translationVar[preferredLanguage].length > 0) {
        response = translationVar[preferredLanguage];
    } else {
        const filtered = languages.filter((val, idx, arr) => {
            return val !== preferredLanguage;
        });
        filtered.forEach((val, idx) => {
            if (!response !== null && translationVar[val] && translationVar[val].length > 0) {
                response = translationVar[val];
            }
        });
    }

    return response;
};
export const pickBestBannerTranslation = (bannerVar, preferredLanguage) => {
    let response = null;
    const languages = [
        'en',
        'de'
    ];
    if (bannerVar[preferredLanguage] && bannerVar[preferredLanguage].length > 0) {
        response = bannerVar[preferredLanguage];
    } else {
        const filtered = languages.filter((val, idx, arr) => {
            return val !== preferredLanguage;
        });
        filtered.forEach((val, idx) => {
            if (!response !== null && bannerVar[val] && bannerVar[val].length > 0) {
                response = bannerVar[val];
            }
        });
    }

    return response;
}
import axios from "axios";

const getIpApiData = async (onFinish) => {
    axios.get('https://pro.ip-api.com/json/?fields=status,message,countryCode,currency,timezone,query&key=jjs1CDZhC9WZTHy')
        .then(data =>{
        onFinish(data.data);
    });

}
export default getIpApiData;

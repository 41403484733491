import React, { useCallback } from "react";
import classNames from "classnames";
import { toggleMobileMenu } from "../../store/base-slice";

import styles from "./MobileTabbar.module.css";
import {BiWebcam, GrChannel, HiMenuAlt2, HiOutlineHome, MdOutlineOndemandVideo} from "react-icons/all";
import {useDispatch} from "react-redux";

const MobileTabbar = ({ navigationData, currentRoute, setCurrentRoute }) => {
    const dispatch = useDispatch();

    const getTabIcon = useCallback((item) => {
        switch (item.title) {
            case "Home":
                return <HiOutlineHome />
            case "Webcams":
                return <BiWebcam />;
            case "Videos":
                return <MdOutlineOndemandVideo />
            case "Channels":
                return <GrChannel />;
            case "Menu":
                return <HiMenuAlt2 />
        }
    }, []);

    const toggleMenu = () => {
        dispatch(toggleMobileMenu());
    }

    
    return (
        <nav className={styles.tabbar}>
            {navigationData.map((item, index) => (
                <span
                    key={index}
                    className={classNames([
                        styles.tabItem,
                        currentRoute === item.url && styles.tabItemActive,
                    ])}
                    onClick={() => setCurrentRoute(item.url)}
                >
              <span className={styles.icon}>{getTabIcon(item)}</span>
            </span>
            ))}
            <span className={styles.tabItem} onClick={toggleMenu} >
                <HiMenuAlt2 />
            </span>
        </nav>
    );
};

export default MobileTabbar;

import classes from './HomeNewAmateurs.module.css';
import {Link} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/solid";

const HomeNewAmateurs = (props) => {


    const amateurs = [
        {
            id: 1,
            online: true,
            name: 'Natacha',
            image: '/img/amateurs/amateur_01.jpg'
        },
        {
            id: 2,
            online: false,
            name: 'Sabina83',
            image: '/img/amateurs/amateur_02.jpg'
        },
        {
            id: 3,
            online: true,
            name: 'BlondAngel',
            image: '/img/amateurs/amateur_03.jpg'
        },
        {
            id: 4,
            online: false,
            name: 'Bibi',
            image: '/img/amateurs/amateur_04.jpg'
        },
        {
            id: 5,
            online: true,
            name: 'Chicka334',
            image: '/img/amateurs/amateur_05.jpg'
        },
        {
            id: 6,
            online: true,
            name: 'Reddevil',
            image: '/img/amateurs/amateur_06.jpg'
        },
        {
            id: 7,
            online: true,
            name: 'Diana',
            image: '/img/amateurs/amateur_07.jpg'
        },
        {
            id: 8,
            online: false,
            name: 'Milf 2',
            image: '/img/amateurs/amateur_08.jpg'
        },
    ];

    return (
        <div className='homeBlock mb-10'>
            <div className={classes.titleContainer}>
                <div><h2>{props.title}</h2></div>
                <div className='flex justify-end'>
                    <div className='flex'>
                        <Link to='/' className={classes.link}>
                            Amateure in deiner Nahe
                        </Link>
                        <ChevronRightIcon className='h-6 text-fundo-purple flex-none' />
                    </div>
                </div>
            </div>
            <div className={classes.amateurContainer}>
                { amateurs.map(amateur => {
                    const activeClass = amateur.online ? classes.active : null;
                    return (<div key={amateur.id} className={classes.amateurItem}>
                        <img src={amateur.image} alt={amateur.name} className={activeClass} />
                    </div>);
                })}
            </div>

        </div>
    );
}
export default HomeNewAmateurs;

import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {verifyUser} from "../../../auth/store/auth-actions";
import {Fragment, useEffect, useState} from "react";
import FundoLayout from "../../../layout/FundoLayout";
import {useTranslation} from "react-i18next";

let checkInterval = null;

const SubscriptionReturn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [vmaIsLoading, setVmaIsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [vmaHttpError, setVmaHttpError] = useState();
    const [vmaRedirectUrl, setVmaRedirectUrl] = useState(null);
    const [openWindow, setOpenWindow] = useState(false);
    const [keepChecking, setKeepCheking] = useState(false);
    const [needsVerification, setNeedsVerification] = useState(false);

    const user = useSelector(state => state.auth.user);
    const base = useSelector(state => state.base);
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status');
    const transaction = searchParams.get('transaction');
    const code = searchParams.get('code');
    const reference = searchParams.get('reference');

    const { t } = useTranslation();
    let vmaWindow;

    const checkReturn = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/return_check', {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        if (!response.ok) {
            throw new Error('Return Check Failed')
        }
        const responseData = await response.json();

        setNeedsVerification(responseData.needs_verification === 1);
        clearInterval(checkInterval);
        if (!responseData.needs_verification) {
            setIsLoading(false);
        }
    }

    const verify = () => {
        dispatch(verifyUser(localStorage.getItem('token')));
    };

    const openVerifyWindow = (url) => {
        var left = (window.screen.width - 480) / 2;
        var top = (window.screen.height - 754) / 4;
        vmaWindow = window.open(url, 'vmawindow', 'toolbar=0,status=0,width=480,height=754,top=' + top +',left=' + left);

        checkInterval = setInterval(() =>{
            checkReturn().then((data) => {
                console.log('interval check done', data);
            }).catch((error) => {
                console.log('interval check error');
            });
        }, 2000);
    }

    useEffect(() => {
        console.log('needsverification');


        const fetchRedirectUrl = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/vmaredirect', {
                method: "POST",
                body: JSON.stringify({
                    sourceHost: window.location.hostname
                }),
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }

            });
            if (!response.ok) {
                throw new Error('VMA Redirect URL')
            }
            const responseData = await response.json();
            console.log('needsverification2', responseData);
            setVmaRedirectUrl(responseData.redirect_url);
            setVmaIsLoading(false);
            setIsLoading(false);
            setOpenWindow(true);
        }

        fetchRedirectUrl().catch(error => {
            setVmaIsLoading(false);
            setVmaHttpError(error.message);
        });
    },[needsVerification]);

    useEffect(() => {
        verify();
        checkReturn().catch(error => {
            setIsLoading(false);

        });

    },[])

    return (
        <FundoLayout>
            {code === '200' &&
                <div className="bg-fundo-purple">
                    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-light text-white sm:text-4xl">
                            <span className="block">Welcome to <span className="text-fundo-gold">Fundorado</span></span>
                        </h2>
                        {!isLoading &&
                            <Fragment>
                                {needsVerification &&
                                    <Fragment>
                                        <p className="mt-4 text-lg leading-6 text-indigo-200">
                                            {t('age_verification.billing_return_description')}
                                        </p>
                                        <button
                                            className='mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-black bg-fundo-gold hover:bg-fundo-gray-light sm:w-auto'
                                            onClick={() => openVerifyWindow(vmaRedirectUrl)}>{t('age_verification.start_vma_button_title')}
                                        </button>

                                    </Fragment>
                                }
                                {!needsVerification &&
                                    <Fragment>
                                        <p className="mt-4 text-lg leading-6 text-indigo-200">
                                            Thanks for signing up!
                                        </p>
                                        <NavLink
                                            to="/"
                                            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-black bg-fundo-gold hover:bg-fundo-gray-light sm:w-auto"
                                        >
                                            Get Started
                                        </NavLink>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                </div>
            }
            {code !== '200' &&
                <div className="bg-fundo-purple">
                    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-light text-white sm:text-4xl">
                            <span className="block">Something went wrong</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-white">
                            <span className="block">Your transaction failed or is still in progress. It's possible that the transaction is still pending, in this case the subscription will automatically activate once it is successful.</span>

                        </p>
                    </div>
                </div>
            }
            {/*<div>*/}
            {/*    status: {status}<br/>*/}
            {/*    transaction: {transaction}<br/>*/}
            {/*    code: {code}<br/>*/}
            {/*    reference: {reference}<br/>*/}
            {/*</div>*/}
        </FundoLayout>

    );
};
export default SubscriptionReturn;
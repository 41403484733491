import {useTranslation} from "react-i18next";
import {Fragment, useEffect, useState} from "react";
import {pickBestAvailableLanguage} from "../../../../core/helpers/TranslationHelpers";
import moment from "moment";

const UserSubscriptions = () => {
    const { t, i18n } = useTranslation();
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(true);

    const [subscription, setSubscription] = useState();

    useEffect(() => {
        const fetchSubscription = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/subscription', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setSubscription(responseData);
            setIsLoading(false);
        }
        fetchSubscription().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    },[])

    return (
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profile.subscriptions.title')}</h3>
                    <p className="mt-1 text-sm text-gray-500">{t('profile.subscriptions.description')}</p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                    {!isLoading &&
                    <div className="round-md p-4 bg-fundo-gold">
                        <div className="flex flex-wrap justify-between">
                            <div>
                                <span className="text-xl">{pickBestAvailableLanguage(subscription.plan.name,i18n.language)}</span>
                                <span className="text-sm">{pickBestAvailableLanguage(subscription.plan.description,i18n.language)}</span>
                            </div>
                            <div>
                                <div>
                                    <span className="text-sm font-light">{t('profile.subscriptions.status')}: </span>
                                    <span className={`text-sm font-bold text-subscription-status-${subscription.status}`}>{subscription.status}</span>
                                </div>
                                <div>
                                    <span className="text-sm font-light">{t('profile.subscriptions.next_renewal')}: </span>
                                    <span className={`text-sm font-bold`}>{moment(subscription.recur_date).format('DD.MM.YYYY')}</span>
                                </div>
                                <div>
                                    <span className="text-sm font-light">{t('profile.subscriptions.renewal_amount')}: </span>
                                    <span className={`text-sm font-bold`}>
                                        {subscription.currency === 'eur' ? (<span className="pr-1">&euro;</span>) : (<span className="pr-1">$</span>)}
                                        {subscription.price}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {subscription.status === 'active' &&
                                    <Fragment>
                                        <button type="button" disabled={true}
                                                className="bg-red-500 text-white rounded-sm px-3 py-1 mr-2">
                                            {t('profile.subscriptions.cancel_button')}
                                        </button>
                                        <button type="button" disabled={true}
                                                className="bg-fundo-blue text-white rounded-sm px-3 py-1">
                                                {t('profile.subscriptions.upgrade_button')}
                                        </button>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default UserSubscriptions;
import {useDispatch, useSelector} from "react-redux";
import {openSignup} from "../../../store/base-slice";
import {Fragment} from "react";

const BlueThinCta = ( props ) => {
    const dispatch = useDispatch();
    const { textBold, text, path } = props;
    const isLoggedIn = useSelector(state => state.auth.user.isLoggedIn);

    const doOpenSignup = () => {
        dispatch(openSignup());
    }


    return (
    <Fragment>
        {!isLoggedIn &&
            <div className="w-full grid grid-cols-6 mb-5 p-3">
                <div className="col-span-6 rounded-md bg-fundo-blue text-center text-xl text-white px-3 py-3 cursor-pointer" onClick={doOpenSignup}>
                    <span className="font-medium">{textBold}</span> <span className="font-thin">{text}</span>
                </div>
            </div>
        }
    </Fragment>
    )
}
export default BlueThinCta;
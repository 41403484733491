import {Menu, Transition} from "@headlessui/react";
import ReactCountryFlag from "react-country-flag";
import {Fragment} from "react";
import {useTranslation} from "react-i18next";

const LanguageDropdown = (props) => {
    const {  i18n } = useTranslation()

    const changeLanguageHandler = (lang) =>
    {
        i18n.changeLanguage(lang);
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="bg-white rounded-full flex text-sm ">
                <ReactCountryFlag
                    countryCode={i18n.language.toUpperCase() === 'EN' ? 'GB' : i18n.language}
                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                    svg
                    style={{
                        width: '2em',
                        height: '2em',
                        borderRadius: '50%'
                    }}
                />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => { changeLanguageHandler('en')}}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                    )}
                                >
                                    <ReactCountryFlag
                                        countryCode='GB'
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        svg
                                        style={{
                                            width: '1em',
                                            height: '1em',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <span className='pl-3'>English</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => { changeLanguageHandler('de')}}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                    )}>
                                    <ReactCountryFlag
                                        countryCode='DE'
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        svg
                                        style={{
                                            width: '1em',
                                            height: '1em',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <span className='pl-3'>Deutsch</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => { changeLanguageHandler('nl')}}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                    )}
                                >
                                    <ReactCountryFlag
                                        countryCode='NL'
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        svg
                                        style={{
                                            width: '1em',
                                            height: '1em',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <span className='pl-3'>Nederlands</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => { changeLanguageHandler('fr')}}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                    )}
                                >
                                    <ReactCountryFlag
                                        countryCode='FR'
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        svg
                                        style={{
                                            width: '1em',
                                            height: '1em',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <span className='pl-3'>Français</span>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => { changeLanguageHandler('es')}}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                    )}
                                >
                                    <ReactCountryFlag
                                        countryCode='ES'
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        svg
                                        style={{
                                            width: '1em',
                                            height: '1em',
                                            borderRadius: '50%'
                                        }}
                                    />
                                    <span className='pl-3'>Español</span>
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>

    );

};
export default LanguageDropdown;

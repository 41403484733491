import {Fragment, Suspense} from 'react';
import '../App.css';
import {Route, BrowserRouter, Routes, Navigate} from "react-router-dom";

import Home from "./main/pages/home/Home";

import Cams from "./main/pages/cams/Cams";
import Channels from "./main/pages/channels/Channels";

import VerifyAge from "./main/pages/VerifyAge";
import ChannelsExclusive from "./main/pages/channels/ChannelsExclusive";
import ChannelsMore from "./main/pages/channels/ChannelsMore";
import ChannelsSpecials from "./main/pages/channels/ChannelsSpecials";
import VerifyMyAgeReturn from "./main/pages/VerifyMyAgeReturn";
import SubscriptionReturn from "./main/pages/subscription/SubscriptionReturn";
import VideoDetail from "./main/pages/videos/VideoDetail";
import FlatFeeCams from "./main/pages/cams/FlatFeeCams";
import FlatFeeCamDetail from "./main/pages/cams/FlatFeeCamDetail";
import NotFound from "./main/pages/general/NotFound";
import HealthCheck from "./main/pages/general/HealthCheck";
import Videos from "./main/pages/videos/Videos";
import VideosFeatured from "./main/pages/videos/VideosFeatured";
import PrivateCams from "./main/pages/cams/PrivateCams";
import PrivateCamDetail from "./main/pages/cams/PrivateCamDetail";
import {useDispatch, useSelector} from "react-redux";
import Profile from "./main/pages/user/Profile";
import ReturnPage from "./main/pages/billing/ReturnPage";
import StudioOverview from "./main/pages/studios/StudioOverview";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setFsk} from "./auth/store/user";
import InfoPage from "./main/pages/info/InfoPage";


function App() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.user.isLoggedIn);
    const userCountry = useSelector(state => state.auth.user.country);

    let isFskGuest = false;

    if (userCountry === 'DE') {
        if (!isLoggedIn) {
            isFskGuest = true;
        }
    }



  return (
      <BrowserRouter>
          <Suspense fallback="Loading....">
              <ToastContainer />

              <Routes>
              <Route path="/" element={ <Home /> } />
              <Route path="/user/verifyage" element={ <VerifyMyAgeReturn /> } />
              <Route path="/user/vma" element={ <VerifyAge /> } />
                  <Route path="/page/:page" element={ <InfoPage /> } />
              {isLoggedIn &&
                  <Route path="/user/profile" element={ <Profile /> } />
              }
              {!isFskGuest &&
                  <Fragment>
                      <Route path="cams" element={<Cams/>}>
                          <Route index={true} element={<Navigate to="/cams/live"/>}/>
                          <Route path="/cams/live" element={<FlatFeeCams collection="regular" />} />
                          <Route path="/cams/starshow" element={<FlatFeeCams collection="starshow" />}/>
                          <Route path="/cams/live/:slug" element={<FlatFeeCamDetail/>}/>
                          <Route path="/cams/private" element={<PrivateCams/>}/>
                          <Route path="/cams/private/:camid/:pseudo" element={<PrivateCamDetail/>}/>
                      </Route>
                      <Route path="/subscription/return/:status" element={<SubscriptionReturn/>}/>
                      <Route path="/transaction/return/:status" element={<ReturnPage/>}/>

                      <Route path="channels" element={<Channels/>}>
                          <Route index={true} element={<Navigate to="/channels/specials/teencoreclub"/>}/>
                          <Route path="/channels/exclusive" element={<ChannelsExclusive/>}/>
                          <Route path="/channels/specials" element={<Navigate to="/channels/specials/teencoreclub"/>}/>
                          <Route path="/channels/specials/:channel" element={<ChannelsSpecials/>}/>
                          <Route path="/channels/more" element={<ChannelsMore/>}/>
                      </Route>

                      <Route path="/videos" element={<Videos/>}>
                          <Route index={true} element={<Navigate to="/videos/featured/studio/325"/>}/>
                          <Route path="/videos/browse/:listtype/:listid" element={<VideosFeatured/>}/>
                          <Route path="/videos/featured" element={<Navigate to="/videos/featured/cast/58819"/>}/>
                          <Route path="/videos/featured/:listtype/:listid" element={<VideosFeatured/>}/>
                          <Route path="/videos/bonus" element={<Navigate to="/videos/bonus/studio/1624"/>}/>
                          <Route path="/videos/bonus/:listtype/:listid" element={<VideosFeatured/>}/>
                      </Route>

                      <Route path="/video/:id/:slug" element={<VideoDetail/>}/>
                      <Route path="/studios" element={<StudioOverview />}/>
                  </Fragment>
              }
              <Route path="/health-check" element={<HealthCheck />} />
              <Route path="*" element={<NotFound />} />
          </Routes>
          </Suspense>
      </BrowserRouter>
  );
}

export default App;

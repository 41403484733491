import classes from './FlatFeeCamItem.module.css';
import {openSignup} from "../../../../store/base-slice";
import WebcamIcon from "../../../../core/icons/WebcamIcon";
import ReactCountryFlag from "react-country-flag";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAlert} from "react-alert";
import {BsCircleFill} from "react-icons/all";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const FlatFeeCamItem = (props) => {
    const { fsk } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const alert = useAlert();
    const performer = props.performer;
    const fskSite = useSelector(state => state.base.fskSite);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    const onClickHandler = () => {
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                navigate('/cams/live/' + performer.slug);
            } else {
                dispatch(openSignup());
            }
        } else {
            navigate('/cams/live/' + performer.slug);
        }
    }

    return (
        <div className="cursor-pointer rounded-md shadow-lg" onClick={onClickHandler}>
            <div className="relative">
                <div className="aspect-w-3 aspect-h-2 imgContainer">
                    <img src={`https://api.fundo.work/storage${fsk ? performer.image_fsk : performer.image}`} alt={performer.modelName} className="w-full h-full object-top object-cover lg:w-full lg:h-full rounded-md" />
                </div>

                <div className="absolute top-0 right-0 bg-black bg-opacity-50 p-2 space-x-2 rounded-tr-md">
                    <span className="inline-block text-sm font-bold text-white">HD</span>
                    <span className="inline-block">
                        <BsCircleFill className={classNames(
                            performer.isOnline ? 'text-green-500' : 'text-red-500',
                            'w-3 h-3')}
                        />
                    </span>
                </div>
                <div className="absolute bottom-0 left-0 bg-fundo-blue p-2 space-x-2">
                    <span className="inline-block text-sm font-bold text-white">{performer.id}</span>
                </div>
                {/*{cam.isnew &&*/}
                {/*    <div className="absolute bottom-0 right-0 bg-green-500 p-2 space-x-2">*/}
                {/*        <span className="inline-block text-sm font-bold text-white">new</span>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            <div className="bg-white px-2 py-3 flex">
                <div className="flex-grow">
                    <div className="text-base font-bold">
                        {performer.modelName}
                    </div>
                    <div className="text-xs font-light">
                        {performer.motto}
                    </div>
                </div>
                <div className="flex-none">
                    <ReactCountryFlag
                        countryCode={performer.langCode === 'EN' ? 'GB' : performer.langCode}
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                        style={{
                            borderRadius: '50%',
                            height: '1.5rem',
                            width: '1.5rem'
                        }}
                        className='h-8 w-8 '
                        svg />
                </div>
            </div>
        </div>


    );
};
export default FlatFeeCamItem;

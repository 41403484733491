import {Controller, useForm} from "react-hook-form";
import {PulseLoader} from "react-spinners";
import {Link} from "react-router-dom";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {closeSignup, openLogin} from "../../store/base-slice";
import {useDispatch, useSelector} from "react-redux";
import {authRegisterUser} from "../store/auth-actions";
import CountrySelector from "../../core/components/meta/CountrySelector";
import DateSelector from "../../core/components/general/DateSelector";

const SignupForm = (props) => {
    const dispatch = useDispatch();
    const { onSignupCompleted } = props;
    const [signupLoading, setSignupLoading] = useState(false);
    const { register, getValues, setError, formState: { errors }, handleSubmit, control } = useForm();
    const userCountry = useSelector(state => state.auth.user.country);
    const { i18n, t } = useTranslation()

    const showLoginModal = () => {
        dispatch(closeSignup());
        dispatch(openLogin())
    }

    const onSubmit = data => {
        setSignupLoading(true);

        const test = dispatch(authRegisterUser(data));
        test.then((data) => {
            if (data.status === 'success') {
                onSignupCompleted(data);
            } else {
                if (data.data.errors.username) {
                    data.data.errors.username.forEach((errData) => {
                        setError('username', { type: "custom", message: errData});
                    });
                }

                if (data.data.errors.email) {
                    data.data.errors.email.forEach((errData) => {
                        setError('email', { type: "custom", message: errData});
                    });
                }
                setSignupLoading(false);
            }
        })


    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                <div className='sm:col-span-3'>
                    <input
                        type="text"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('auth.username')}
                        {...register("username", {
                            required: true,
                            minLength: 5
                        })}
                    />
                    {errors.username &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.username.type === 'custom' ? errors.username.message : t('auth.validation.username')}
                    </p>
                    }
                </div>
                <div className='sm:col-span-3'>
                    <input
                        type="email"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('auth.email')}
                        {...register("email", {
                            required: true,
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                    />
                    {errors.email &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.email.type === 'custom' ? errors.email.message : t('auth.validation.email')}
                    </p>
                    }
                </div>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                <div className='sm:col-span-3'>
                    <input
                        type="text"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('auth.firstname')}
                        {...register("firstname", {
                            required: true
                        })}
                    />
                    {errors.firstname &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.firstname.type === 'custom' ? errors.firstname.message : t('auth.validation.firstname')}
                    </p>
                    }
                </div>
                <div className='sm:col-span-3'>
                    <input
                        type="text"
                        name="lastname"
                        id="lastname"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('auth.lastname')}
                        {...register("lastname", {
                            required: true
                        })}
                    />
                    {errors.lastname &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.lastname.type === 'custom' ? errors.lastname.message : t('auth.validation.lastname')}
                    </p>
                    }
                </div>
            </div>

            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                <div className='sm:col-span-3'>
                    <Controller
                        control={control}
                        name="country"
                        rules={{ required: true }}
                        defaultValue={userCountry}
                        render={({ field }) => (
                            <CountrySelector language={i18n.language} country={userCountry} onChange={(e) => field.onChange(e)} />

                        )}
                    />
                    {errors.country &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.country.type === 'custom' ? errors.country.message : t('auth.validation.country')}
                    </p>
                    }
                </div>
                <div className="col-span-3">
                    <Controller
                        control={control}
                        name="birthdate"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <DateSelector onChange={(e) => {
                                field.onChange(e)
                                console.log('here it is', e);
                            }} />
                        )}
                    />
                    {errors.birthdate &&
                        <p className="mt-2 text-xs text-red-600" id="email-error">
                            {errors.birthdate.type === 'custom' ? errors.birthdate.message : t('auth.validation.birthdate')}
                        </p>
                    }
                </div>
            </div>

            <div className='mt-14 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                <div className='sm:col-span-3'>
                    <input
                        type="password"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('auth.password')}
                        {...register("password", {
                            required: t('auth.validation.password')
                        })}

                    />
                    {errors.password &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.password.type === 'custom' ? errors.password.message : t('auth.validation.password')}
                    </p>
                    }
                </div>
                <div className='sm:col-span-3'>
                    <input
                        type="password"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block p-4 w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder={t('auth.password_confirm')}
                        {...register("password_confirm", {
                            required: t('auth.validation.password_confirm_required'),
                            validate: {
                                matchesPreviousPassword: (value) => {
                                    const { password } = getValues();
                                    return password === value || t('auth.validation.password_confirmation');
                                }
                            }
                        })}
                    />
                    {errors.password_confirm &&
                    <p className="mt-2 text-xs text-red-600" id="email-error">
                        {errors.password_confirm.message}
                    </p>
                    }
                </div>
            </div>


            <div className="mt-5 sm:mt-16">
                <button
                    type="submit"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-fundo-blue text-base uppercase font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-xl"
                    disabled={signupLoading}
                >
                    {!signupLoading && t('auth.continue')}
                    {signupLoading && <PulseLoader color='#ffffff' loading={signupLoading}  size={10} margin={5} />}
                </button>

                <button
                    type="button"
                    className="mt-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-fundo-gray-dark text-base uppercase font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-xl"
                    disabled={signupLoading}
                    onClick={() => dispatch(closeSignup())}
                >
                    {t('auth.cancel')}
                </button>
            </div>
            <div className='text-center'>
                <div className='text-xs mt-3'>
                    {t('auth.texts.login_below_subscribe')} <span onClick={() => showLoginModal()} className='text-fundo-blue cursor-pointer'>{t('auth.texts.click_here')}</span>
                </div>
            </div>
        </form>

    );
}
export default SignupForm;

import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {pickBestAvailableLanguage} from "../../core/helpers/TranslationHelpers";
import {useDispatch, useSelector} from "react-redux";
import {openSignup} from "../../store/base-slice";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const genreColorMap = {
    0: "bg-fundo-purple hover:bg-fundo-purple-light",
    1: "bg-fundo-purple-light hover:bg-fundo-purple",
    2: "bg-fundo-purple hover:bg-fundo-purple-light",
    3: "bg-fundo-purple hover:bg-fundo-purple-light",
    4: "bg-fundo-purple-light hover:bg-fundo-purple",
    5: "bg-fundo-purple hover:bg-fundo-purple-light",
    6: "bg-fundo-purple-light hover:bg-fundo-purple",
    7: "bg-fundo-purple-light hover:bg-fundo-purple",
    8: "bg-fundo-purple hover:bg-fundo-purple-light",
    9: "bg-fundo-purple-light hover:bg-fundo-purple",
    10: "bg-fundo-purple hover:bg-fundo-purple-light",
}

const CategoriesBlock = ( props ) => {
    const dispatch = useDispatch();
    const { title, fsk } = props;
    const [genres, setGenres] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState();
    const { t, i18n } = useTranslation()
    const navigate = useNavigate();
    const fskSite = useSelector(state => state.base.fskSite);
    const hasActiveSubscription = useSelector(state => state.auth.user.hasActiveSubscription);
    const needsAgeVerification = useSelector(state => state.auth.user.needsAgeVerification);

    useEffect(() => {
        const fetchVideos = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/genres/filtered?collection=1&limit=11',{
                method: 'GET',
                    headers: {
                    'Accept': 'application/json',
                },

            });

            if (!response.ok) {
                throw new Error('Unable to load data!');
            }
            const responseData = await response.json();
            setGenres(responseData);
            setIsLoading(false);
        }
        fetchVideos().catch(error => {
            setIsLoading(false);
            setHttpError(error.message)
        });
    }, []);

    const loadingImage =
        "data:image/gif;base64,R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";
    const imgStyles = {
        // width: "auto",
        // height: "225px",
        // objectFit: "cover"
    };

    const onGenreClick = (path) => {
        if (fskSite) {
            if (hasActiveSubscription && !needsAgeVerification) {
                navigate(path);
            } else {
                dispatch(openSignup())
            }
        } else {
            navigate(path);
        }

    }

    return (
        <div className="px-3">
            <div>
                <div>
                    <h2 className="text-2xl font-light py-2">{title}</h2>
                </div>
                <div>
                    <Link to='/'></Link>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="bg-fundo-purple-light px-3 py-2 text-white text-2xl  text-center cursor-pointer shadow-lg hover:shadow-none hover:bg-fundo-purple"
                     onClick={() => onGenreClick('/videos/featured/genre/all')}
                >
                    {t('videos.all_genres')}
                </div>
                {genres.map((genre, index) => (
                    <div key={genre.id} className={`px-3 py-2 text-white text-2xl text-center  cursor-pointer shadow-lg hover:shadow-none ${genreColorMap[index]}`}
                         onClick={() => onGenreClick('/videos/featured/genre/' + genre.id)}
                    >
                        {pickBestAvailableLanguage(genre.title, i18n.language)}
                    </div>
                ))}
            </div>
        </div>
    )

};
export default CategoriesBlock;